import { cloneElement, useMemo } from "react";
import { SVG_WIDTH, SVG_HEIGHT } from '../../utils/constants'

function EmptyContainer({children, top, left, width, height, color, showContent = true, _radius, _gutter, _colors, isMask}) {

  let colWidth = SVG_WIDTH / 5
  let colHeight = SVG_HEIGHT / 4
  
  const _color = useMemo(() => {
    return _colors[color]
  }, [color, _colors])

  const cornerRadius = _radius + (_gutter/2)
  
  const contentWidth = (colWidth * width) - _gutter
  const contentHeight = (colHeight * height) - _gutter

  const leftPos = colWidth * left
  const topPos = colHeight * top

  const contentLeftPos = (colWidth * left) + (_gutter / 2)
  const contentTopPos = (colHeight * top) + (_gutter / 2)

  const maxRadius = contentHeight / 2 < _radius ? contentHeight / 2 : _radius

  const path = () => {
    return (
      <rect 
        // fill={`rgba(${_color.bgColor[0]}, ${_color.bgColor[1]}, ${_color.bgColor[2]}, ${_color.bgOpacity})`}
        fill={_color.bgColor} fillOpacity={_color.bgOpacity}
        strokeLinecap="round" strokeWidth={_color.borderWeight}
        // stroke={`rgb(${_color.borderColor[0]}, ${_color.borderColor[1]}, ${_color.borderColor[2]})`}
        stroke={_color.borderColor}
        x={contentLeftPos} y={contentTopPos} width={contentWidth + (_color.borderWeight/2)} height={contentHeight + (_color.borderWeight/2)} rx={maxRadius} />
    )
  } 

  if (isMask) {
    return (
      <defs>
        <clipPath id={isMask}>
          {path()}
        </clipPath>
      </defs>
    )
  }

  const drawBG = () => {
    let bg = '';

    if (top === 0 && left === 0) {
      bg += `M ${leftPos} ${topPos + cornerRadius}`
      bg += ` A ${cornerRadius} ${cornerRadius} 0 0 1 ${leftPos + cornerRadius} ${topPos}`
    } else {
      bg += `M ${leftPos} ${topPos}`
    }

    if (top === 0 && left + width === 5) {
      bg += ` H ${(leftPos + (colWidth * width)) - cornerRadius}`
      bg += ` A ${cornerRadius} ${cornerRadius} 0 0 1 ${(leftPos + (colWidth * width))} ${topPos + cornerRadius}`
    } else {
      bg += ` H ${leftPos + (colWidth * width)}`
    }

    if (top + height === 4 && left + width === 5) {
      bg += ` V ${(topPos + (colHeight * height)) - cornerRadius}`
      bg += ` A ${cornerRadius} ${cornerRadius} 0 0 1 ${(leftPos + (colWidth * width)) - cornerRadius} ${topPos + (colHeight * height)}`
    } else {
      bg += ` V ${topPos + (colHeight * height)}`
    }

    if (top + height === 4 && left === 0) {
      bg += ` H ${leftPos + cornerRadius}`
      bg += ` A ${cornerRadius} ${cornerRadius} 0 0 1 ${leftPos} ${(topPos + (colHeight * height)) - cornerRadius}`
    } else {
      bg += ` H ${leftPos}`
    }
    
    bg +=` Z`
    return bg
  }

  return (
    <g>
      <path 
        // fill={`rgba(${_color.containerColor[0]}, ${_color.containerColor[1]}, ${_color.containerColor[2]}, ${_color.containerOpacity})`} 
        fill={_color.containerColor} fillOpacity={_color.containerOpacity} 
        d={drawBG()} stroke="none" strokeLinecap="round"
      />
      {showContent && path()}
      {children && cloneElement(children, { size: { width: contentWidth + (_color.borderWeight/2), height: contentHeight + (_color.borderWeight/2) }, left: leftPos + (_gutter / 2), top: topPos + (_gutter / 2), color: _color, useHex: true})}
    </g>
  );
}

export default EmptyContainer;
