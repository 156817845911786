import { useEffect, useRef, useState, useCallback, memo, useMemo } from "react";
import { RESIZE_TO } from "../../utils/constants";
import classNames from "classnames";
import ReactGA from "react-ga4";
import styles from  './bendyText.module.scss';


const BendyText = memo(({ width, links, colorsIndexes, colors, screenSize }) => {
  const path = useRef()
  const title = useRef()
  
  const totalLength = useRef(0)
  const noOfRows = useRef(0)

  const [settings, setSettings] = useState([])

  const lineHeight = 34
  const curveHeight = 20
  const gap = 40
  const curves = Math.floor((width - (gap * 0))/80)
  const realCurveLength = (width - (gap * 0))/curves

  const id = useRef(Math.floor(Math.random() * 999999))

  const init = useCallback(() => {
    totalLength.current = path.current.getTotalLength();
    let options = title.current.querySelectorAll('span')

    let _settings = [{
      id: Math.floor(Math.random() * 999999),
      options: []
    }]
    
    let rowCounter = 0;
    // let offset = gap;
    let computedGap = width < 400 ? 0 : gap/2;
    let offset = computedGap;

    for (let i = 0; i < options.length; i++) {
      let partWidth = options[i].getBoundingClientRect().width
      let option = {
        copy: typeof links[i] === 'string' ? links[i] : links[i].label,
        link: links[i].link,
        title: links[i].title,
        width: partWidth
      }
      if (offset + partWidth > totalLength.current) {
        let randomInset = Math.round(Math.random() * gap)
        // offset = rowCounter%2 ? gap : gap * 2
        // option.offset = rowCounter%2 ? gap : gap * 2
        offset = rowCounter%2 ? computedGap : computedGap + randomInset
        option.offset = rowCounter%2 ? computedGap : computedGap + randomInset
        rowCounter++
        _settings.push({
          id: Math.floor(Math.random() * 999999),
          options: []
        })
      } else {
        option.offset = offset
      }
      
      offset += options[i].getBoundingClientRect().width + gap;

      _settings[rowCounter]['options'].push(option);
    }
  
    noOfRows.current = _settings.length
    setSettings(_settings);
  }, [links, width])

  const initTO = useRef()

  useEffect(() => {
    clearTimeout(initTO.current)
    initTO.current = null

    initTO.current = setTimeout(() => {
      clearTimeout(initTO.current)
      initTO.current = null
      init();
    }, RESIZE_TO)

    return () => clearTimeout(initTO.current)
  }, [screenSize, init])
  
  // useEffect(() => {
  //   init();
  //   setTimeout(init, 3000);
  // }, [init])

  const goTo = (link, label) => {
    if (link) {
      window.open(link, '_blank').focus();
      ReactGA.event({
        category: "about-me",
        action: "click",
        label: label
      });
    }
  }

  const drawPath = useCallback((id, index) => {
    let yPos = (curveHeight/2) + ((lineHeight + 10) * (index + 1))

    let _path = `M 0 ${yPos}`
    for (let i = 0; i < curves; i++) {
      if (i%2 === 0) {
        _path += ` A ${realCurveLength} ${realCurveLength} 1 0 0 ${realCurveLength * (i + 1)} ${yPos}`
      } else {
        _path += ` A ${realCurveLength} ${realCurveLength} 0 0 1 ${realCurveLength * (i + 1)} ${yPos}`
      }
    }

    return (
      <path 
        key={`path-${id}`} id={`path-${id}`} className={styles.path} d={_path} 
        fill="none" stroke="blue" strokeWidth={0} strokeLinecap="round"
      />
    )
  }, [curves, realCurveLength])

  const drawPathPart = (index, width, offset, hover, isTitle) => {
    let yPos = (curveHeight/2) + ((lineHeight + 10) * (index + 1))
    const titleColor = `rgb(${colors[colorsIndexes[0]].bgColor[0]}, ${colors[colorsIndexes[0]].bgColor[1]}, ${colors[colorsIndexes[0]].bgColor[2]})`
    const normalColor = `rgb(${colors[colorsIndexes[1]].bgColor[0]}, ${colors[colorsIndexes[1]].bgColor[1]}, ${colors[colorsIndexes[1]].bgColor[2]})`
    const _color = hover ? 'blue' : isTitle ? titleColor : normalColor

    let _path = `M 0 ${yPos}`
    for (let i = 0; i < curves; i++) {
      if (i%2 === 0) {
        _path += ` A ${realCurveLength} ${realCurveLength} 1 0 0 ${realCurveLength * (i + 1)} ${yPos}`
      } else {
        _path += ` A ${realCurveLength} ${realCurveLength} 0 0 1 ${realCurveLength * (i + 1)} ${yPos}`
      }
    }

    return (
      <path 
        key={`path-${id}`} id={`path-${id}`} className={styles.path} d={_path} 
        fill="none" stroke={_color} strokeWidth={lineHeight} strokeLinecap="round" 
        style={{
          // strokeDasharray: `${width}px ${totalLength.current}px`,
          strokeDasharray: `${width}px 9999px`,
          strokeDashoffset: `${-offset}px`,
          transform: `translateY(-4px)`
        }}
      />
    )
  }

  const [hoverLink, setHoverLink] = useState(false)

  const hoverOn = (link, row, column) => {
    if (link) setHoverLink([row, column])
  }
  
  const hoverOut = () => {
    setHoverLink(undefined)
  }

  const boxHeight = (curveHeight * 2) + ((lineHeight + 10) * settings.length)

  const mainCurve = useMemo(() => {
    let path = `M 0 0`
    for (let i = 0; i < curves; i++) {
      if (i%2 === 0) {
        path += ` A ${realCurveLength} ${realCurveLength} 1 0 0 ${realCurveLength * (i + 1)} 0`
      } else {
        path += ` A ${realCurveLength} ${realCurveLength} 0 0 1 ${realCurveLength * (i + 1)} 0`
      }
    }
    return path
  }, [curves, realCurveLength])

  return (
    <div className={styles.pathWrapper} >

      <div ref={title} className={styles.text}>
        {links.map((l, i) => <span key={`link-${i}`}>{typeof l === 'string' ? l : l.label}</span>)}
      </div>
      
      <svg preserveAspectRatio="none" width={width} height={boxHeight} 
        viewBox={`0 0 ${width} ${boxHeight}`} 
        className={styles.svg}>     

          <path id={`path-hidden`} className={styles.path} style={{ display: 'none' }} ref={path} d={mainCurve} fill="none" stroke="blue" strokeWidth={2} strokeLinecap="round" />

          { settings.map((l, i) => 
            drawPath(l.id, i)
          )}

          { settings.map((l, i) => 
            l.options.map((w, j) => 
              <g key={`group-${i}-${j}`}>
                {drawPathPart(i, Math.round(w.width), Math.round(w.offset), hoverLink && w.link && hoverLink[0] === i && hoverLink[1] === j, w.title)}
                <text key={`row-${i}-word-${j}`} textLength={Math.round(w.width)} className={classNames([styles.label, w.title && styles.title, hoverLink && hoverLink[0] === i && hoverLink[1] === j && styles.hover])} onMouseOver={() => { hoverOn(w.link, i, j) }} onMouseOut={hoverOut} >
                  <textPath href={`#path-${l.id}`} id="masterTextPath" textLength={Math.round(w.width)} startOffset={Math.round(w.offset)} 
                  fill={hoverLink && hoverLink[0] === i && hoverLink[1] === j ? 'white' : w.title ? 
                    `rgb(${colors[colorsIndexes[0]].textColor[0]}, ${colors[colorsIndexes[0]].textColor[1]}, ${colors[colorsIndexes[0]].textColor[2]})` : 
                    `rgb(${colors[colorsIndexes[1]].textColor[0]}, ${colors[colorsIndexes[1]].textColor[1]}, ${colors[colorsIndexes[1]].textColor[2]})`
                  } 
                  onClick={() => { goTo(w.link, w.copy) }}>{w.copy}</textPath>
                </text>
              </g>
            )
          )}
              
      </svg>        
      
    </div>
  );
})

export default BendyText;
