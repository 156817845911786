// import { useContext } from "react";
// import { AppContext } from "../../context/appContext";
import { gsap } from "gsap/all";
import { SVG_WIDTH, SVG_HEIGHT } from '../../utils/constants'

function Squiggles({ width, height, top=0, left=0, id, curveLength = 100, rotation = 0, color, factor = 1, dash, showBG }) {
  // const { colors } = useContext(AppContext);
  
  let colWidth = SVG_WIDTH / 5
  let colHeight = SVG_HEIGHT / 4

  width = colWidth * width
  height = colWidth * height
  
  let expandedWidth = (width) * 1
  let expandedHeight = (height) * 1
  
  let size = expandedHeight > expandedWidth ? expandedHeight : expandedWidth
  size = Math.round(Math.sqrt(Math.pow(size, 2) + Math.pow(size, 2)) * 100) / 100

  let expandedLeft = Math.round(((colWidth * left) - ((size - width)/2)) * 100) / 100
  let expandedTop = Math.round(((colHeight * top) - ((size - height)/2)) * 100) / 100

  const strokeWidth = Math.round((gsap.utils.clamp(2, 12, color.borderWeight)) * 100) / 100
  const noCurves = Math.floor(size/curveLength)
  const realCurveLength = Math.round((size/noCurves) * 100) / 100
  const lineHeight = Math.round((strokeWidth * factor) * 100) / 100
  const rows = Math.ceil((size / lineHeight) / 2) + 1
  const colorChoice = showBG ? 'borderColor' : 'bgColor'

  const drawCurve = (yPos) => {
    let curve = ''
    for (let i = 0; i < noCurves * 2; i++) {
      if (i%2 === 0) {
        curve += `A ${realCurveLength} ${realCurveLength} 1 0 0 ${expandedLeft + (realCurveLength * (i + 1))} ${yPos} `
      } else {
        curve += `A ${realCurveLength} ${realCurveLength} 0 0 1 ${expandedLeft + (realCurveLength * (i + 1))} ${yPos} `
      }            
    }
    return curve
  }

  const drawPath = (_index) => {
    let yPos = expandedTop + (_index * lineHeight * 2)

    return (
      <path key={`path-${id}-${_index}`} id={`path-${id}-${_index}`} d={`M ${expandedLeft} ${yPos} ${drawCurve(yPos)}`} 
      stroke={color[colorChoice]} 
      fill="none" strokeWidth={strokeWidth} strokeLinecap="round"
      transform={`rotate(${rotation} ${expandedLeft + (size/2)} ${expandedTop + (size/2)})`} 
      style={{
        strokeDasharray: dash ? `${dash}px ${strokeWidth*2 * factor}px` : 'none',
        strokeDashoffset: dash ? `${_index%2 === 0 ? 0 : -dash/2}px` : 'none'
      }}
      />
    )
  }

  return (
    <g clipPath={`url(#${id})`}>
      { [...Array(rows)].map((a, i) =>   
        drawPath(i)
      )}
    </g>
  );
}

export default Squiggles ;
