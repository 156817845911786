import { useContext, useMemo } from "react";
import { AppContext } from "../../context/appContext";
import CentralSlider from '../centralSlider';
import Container from '../container'
import MShape from '../mShape'
import SShape from '../sShape'
import BottomSlider from '../bottomSlider'
import RtlSlider from '../rtlSlider'
import AboutMeContent from '../aboutMeContent'
import ShuffleButton from '../shuffleButton'
import Carousel from '../carousel'
import FillerShape from "../fillerShape";
import ClickVerticalExpand from "../clickVerticalExpand";
import VerticalSlider1 from "../verticalSlider1";
import MinterThumbnail from '../minterContent/thumb'
import ScrollContent from "../scrollContent";
import { COPY } from '../../utils/constants'
import styles from  './grid.module.scss';


function GridMobile() {
  const { screenSize, gutter, colors, shuffleStyle, resetStyle, shuffled, infoExpanded, rightExpanded, btmSlideVal, rtlSlideVal, sizes, showMobile, bottomScale, onInfoHover, verticalSlide1Val } = useContext(AppContext);  

  let realVertialVal = useMemo(() => (1 - verticalSlide1Val) * 1.5, [verticalSlide1Val])
  let realBottomScale = useMemo(() => ((verticalSlide1Val === 0 ? bottomScale + ((1 - bottomScale) * 1.5) : bottomScale)), [bottomScale, verticalSlide1Val])

  return (
    <div className={styles.grid} style={{ padding: `${gutter}px`, backgroundColor: `rgb(${colors[0].bgColor[0]}, ${colors[0].bgColor[1]}, ${colors[0].bgColor[2]})` }}>

      
      {btmSlideVal < 1 && (<>

        <Container width={1.5} height={2} left={(btmSlideVal * 5)} top={0.5} color={2} container>
          <SShape />
        </Container>

        <Container width={3.5} height={1.5} left={1.5 + (btmSlideVal * 5)} top={1} color={3} container styles={{ backgroundColor: 'transparent' }}>
          <CentralSlider />
        </Container>

        <Container width={5} height={1} left={btmSlideVal * 5} top={2.5} color={6} container><MShape /></Container>

        {/* <RandomPoly color={8}/> */}
        <Container width={3} height={0.5} left={btmSlideVal * 5} top={3.5} color={8} background border container hidden>
          <ShuffleButton label="shuffle" onClickCB={shuffleStyle} />
        </Container>

        <Container width={2} height={0.5} left={3 + (btmSlideVal * 5)} top={3.5} color={10} background border container>
          <ShuffleButton label="reset" onClickCB={resetStyle} disabled={!shuffled && rightExpanded === 0 && btmSlideVal === 0 && infoExpanded === 0} />
        </Container>

      </>)}

      {/** bottom left */}
      <Container width={4} height={0.5} left={0} top={-realBottomScale} color={7} background border container hidden>
          <BottomSlider forceOpen={true} width={sizes.widths[3]} height={(screenSize.height/8) - gutter} />
      </Container>
      <Container width={1} height={0.5} left={4} top={-realBottomScale} color={5} background border container hidden></Container>
      
      <Container width={3.5} height={0.5} left={1.5 + (btmSlideVal * 5)} top={0.5} color={4} background border container hidden></Container>

      {/* <Container width={3.5} height={0.5} left={1.5 + (btmSlideVal * 5)} top={0.5} color={4} border background container hidden> */}

      {btmSlideVal > 0 && (<>
        <Container width={3} height={0.5} left={-3 + (btmSlideVal * 5)} top={3.5 - realVertialVal - realBottomScale} color={4} background border container hidden>
          <RtlSlider width={sizes.widths[2]} height={(screenSize.height/8) - gutter} />
        </Container>
        <Container onTop={bottomScale} width={2} height={0.5 + bottomScale} left={-5 + (btmSlideVal * 5)} top={3.5 - realVertialVal - realBottomScale} color={6} background border container hidden>
          <ClickVerticalExpand>
            <VerticalSlider1 width={sizes.widths[1]} height={((screenSize.height/4) * 1.5) - gutter} expanded={bottomScale} />
          </ClickVerticalExpand>
        </Container>
        <Container width={3} height={1} left={-3 + (btmSlideVal * 5)} top={4 - realVertialVal - realBottomScale} color={5}>
        {bottomScale > 0 && <FillerShape active={bottomScale > 0.5} />}
        </Container>
      </>)}

      {btmSlideVal > 0 && rtlSlideVal > 0 && (<>
        <Container width={5} height={3} left={-5 + (btmSlideVal * 5) - ((1 - rtlSlideVal) * 5)} top={0.5 - realVertialVal - realBottomScale} color={9} background border container hidden>
          <AboutMeContent width={4} mobile={showMobile} />
        </Container>
      </>)}


      {rtlSlideVal < 1 && btmSlideVal > 0 && (<>
        
        <Container width={5} height={2} left={
          rtlSlideVal === 1 ? 5 : (btmSlideVal * 5) - ((1 - rtlSlideVal) * 5)
        } top={1.5 - realVertialVal - realBottomScale} color={2} fillBorder={true} background border container hidden>
          <Carousel closedWidth={4} openWidth={4} />
        </Container>
        <Container width={5} height={1} left={
          rtlSlideVal === 1 ? 5 : (btmSlideVal * 5) - ((1 - rtlSlideVal) * 5)
        } top={0.5 - realVertialVal - realBottomScale} color={3} background border container hidden>
          <div className={styles.content}>
            <span className={styles.title} style={{
              color: `rgb(${colors[3].textColor[0]}, ${colors[3].textColor[1]}, ${colors[3].textColor[2]})`,
              // marginBottom: 0
            }}>some recent stuff</span>
            <p style={{
              color: `rgb(${colors[3].textColor[0]}, ${colors[3].textColor[1]}, ${colors[3].textColor[2]})`
            }}>
              a handfull of recent work, big &amp; small, commercial &amp; personal. to see my full portfolio please feel free to get in touch. 
            </p>
          </div>
        </Container>
      </>)}

      { verticalSlide1Val < 1 && (
        <Container width={5} height={3} left={0} top={4 - realVertialVal - (verticalSlide1Val === 0 ? ((1 - bottomScale) * 1.5) : 0)} color={7} background border container hidden>
          <ScrollContent color={colors[7]} width={4} height={2} active={1}>
            <>
              <div className={styles.mintContent} style={{height: 'auto', marginBottom: '20px'}}>
                <span className={styles.title} style={{
                  color: `rgb(${colors[7].textColor[0]}, ${colors[7].textColor[1]}, ${colors[7].textColor[2]})`
                }}>mint a sam!</span>
                <p 
                  style={{color: `rgb(${colors[7].textColor[0]}, ${colors[7].textColor[1]}, ${colors[7].textColor[2]})`}}
                  dangerouslySetInnerHTML={{__html: COPY.minterDsc}}>
                </p>
              </div>
              <MinterThumbnail fluid={true} visibility={verticalSlide1Val === 0 ? 1 : 0} color={colors[7]}/>
            </>
          </ScrollContent>
        </Container>
      )}

      { bottomScale > 0 && (
        <div 
          onTouchStart={() => { onInfoHover(false) }} 
          onMouseDown={() => { onInfoHover(false) }} 
          className={styles.overlay}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            // background: '#000',
            // opacity: 0.5 * bottomScale,
            zIndex: 888
          }
        }></div>
      )}
    </div>
  );
}

export default GridMobile;
