import { useRef, useContext } from "react";
import { AppContext } from "../../context/appContext";
import EmptyContainer from "./emptyContainer";
import SGroup from '../sShape/group'
import MGroup from '../mShape/group'
import SliderGroup from '../centralSlider/group'
import Dots from './dots';
import Stripes from './stripes';
import Squiggles from './squiggles';
import { rgbToHex } from '../../utils/colors'
import { SVG_WIDTH, SVG_HEIGHT } from '../../utils/constants'

function SVGNFT() {

  const { borderRadius, gutter, colors } = useContext(AppContext);

  const mousePos = useRef({x: (Math.random() * 0.7) + 0.15, y: (Math.random() * 0.7) + 0.15})

  // const _gutter = useRef(gutter)
  const _colors = useRef(colors.map(c => {
    return {
      bgColor: rgbToHex(c.bgColor),
      borderColor: rgbToHex(c.borderColor),
      containerColor: rgbToHex(c.containerColor),
      textColor: rgbToHex(c.textColor),
      bgOpacity: c.bgOpacity,
      borderOpacity: c.borderOpacity,
      borderWeight: c.borderWeight,
      containerOpacity: c.containerOpacity,
    }
  }))
  
  // const _radius = useRef(borderRadius)
  
  const options = useRef({
    _gutter: gutter,
    _colors: _colors.current,
    _radius: borderRadius
  })

  let { current: bgColor } = useRef(_colors.current[0])

  const getFill = (id, color, mask, w, h, t, l, settings, showBG) => {
    switch (id) {
      case 1:
        return <Dots width={w} height={h} top={t} left={l} color={color} id={mask} {...settings} showBG={showBG} />
      case 2:
        return <Stripes width={w} height={h} top={t} left={l} color={color} id={mask} {...settings} showBG={showBG} />
      case 3:
        return <Squiggles width={w} height={h} top={t} left={l} color={color} id={mask} {...settings} showBG={showBG} />
      default:
        return null
    }
  }
  
  const fillAmount = useRef([0, 2, 3, 4, 6][Math.floor(Math.random() * 5)])
  const containers = useRef([...Array(6)].map((a, i) => i < fillAmount.current ? Math.ceil(Math.random() * 6) : 0 ).sort(() => (Math.random() > .5) ? 1 : -1))
  // const containers = useRef([5, 6, 5, 6, 5, 6])
  const fillSettings = useRef([...containers.current].map(a => {
    switch (a) {
      case 1:
        return {fill: 1, showBG: Math.random() < 0.8, settings: { rotation: (Math.round(Math.random() * 18) -9) * 10, radius: Math.ceil(Math.random() * 4) * 6, factor: Math.ceil(Math.random() * 2) }}
      case 2:
        return {fill: 1, showBG: Math.random() < 0.8, settings: { rotation: (Math.round(Math.random() * 18) -9) * 10, radius: Math.ceil(Math.random() * 4) * 6, stroke: true, factor: Math.ceil(Math.random() * 2) }}
      case 3:
        return {fill: 2, showBG: Math.random() < 0.8, settings: { rotation: (Math.round(Math.random() * 18) -9) * 10, factor: Math.ceil(Math.random() * 2) }}
      case 4:
        return {fill: 2, showBG: Math.random() < 0.8, settings: { rotation: (Math.round(Math.random() * 18) -9) * 10, dash: Math.ceil(Math.random() * 2) * 25, factor: Math.ceil(Math.random() * 2) }}
      case 5:
        return {fill: 3, showBG: Math.random() < 0.8, settings: { rotation: (Math.round(Math.random() * 18) -9) * 10, curveLength: Math.ceil(Math.random() * 2) * 25, factor: Math.ceil(Math.random() * 2) }}
      case 6:
        return {fill: 3, showBG: Math.random() < 0.8, settings: { rotation: (Math.round(Math.random() * 18) -9) * 10, curveLength: Math.ceil(Math.random() * 2) * 25, dash: Math.ceil(Math.random() * 2) * 25, factor: Math.ceil(Math.random() * 2) }}
      default:
        return null
    }
  }))

  if (!_colors || !gutter) return null

  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`} preserveAspectRatio="xMidYMid meet">

      {/* background */}
      <path fill={bgColor.bgColor} d={
          `M 0 ${borderRadius + (gutter/2)}`
          + ` A ${borderRadius + (gutter/2)} ${(borderRadius + (gutter/2))} 0 0 1 ${borderRadius + (gutter/2)} ${0}`
          + ` H ${SVG_WIDTH - (borderRadius + (gutter/2))}`
          + ` A ${borderRadius + (gutter/2)} ${borderRadius + (gutter/2)} 0 0 1 ${SVG_WIDTH} ${borderRadius + (gutter/2)}`
          + ` V ${SVG_HEIGHT - (borderRadius + (gutter/2))}`
          + ` A ${borderRadius + (gutter/2)} ${borderRadius + (gutter/2)} 0 0 1 ${SVG_WIDTH - (borderRadius + (gutter/2))} ${SVG_HEIGHT}`
          + ` H ${borderRadius + (gutter/2)}`
          + ` A ${borderRadius + (gutter/2)} ${borderRadius + (gutter/2)} 0 0 1 ${0} ${SVG_HEIGHT - (borderRadius + (gutter/2))}`
          + ` Z`
        } stroke="none" strokeLinecap="round"
      />

      {/* s container */}
      <EmptyContainer top={0} left={0} width={1} height={3} color={2} showContent={false} {...options.current}>
        <SGroup mousePos={mousePos.current} nft={true} />
      </EmptyContainer>
      {/* slider container */}
      <EmptyContainer top={1} left={1} width={2} height={2} color={3} {...options.current} showContent={false}>
        <SliderGroup nft={true} />
      </EmptyContainer>
      {/* fan container */}
      { !fillSettings.current[0] ? 
        <EmptyContainer top={0} left={1} width={2} height={1} color={4} {...options.current} />
      : 
        <>
          <EmptyContainer top={0} left={1} width={2} height={1} color={4} {...options.current} isMask={`fill-0`} />
          {fillSettings.current[0].showBG && <EmptyContainer top={0} left={1} width={2} height={1} color={4} {...options.current} />}
          {getFill(fillSettings.current[0].fill, _colors.current[4], `fill-0`, 2, 1, 0, 1, fillSettings.current[0].settings, fillSettings.current[0].showBG)}
        </>
      }
      {/* about container */}
      { !fillSettings.current[1] ? 
        <EmptyContainer top={3} left={0} width={2} height={1} color={5} {...options.current} />
      : 
        <>
          <EmptyContainer top={3} left={0} width={2} height={1} color={5} {...options.current} isMask={`fill-1`} />
          {fillSettings.current[1].showBG && <EmptyContainer top={3} left={0} width={2} height={1} color={5} {...options.current} />}
          {getFill(fillSettings.current[1].fill, _colors.current[5], `fill-1`, 2, 1, 3, 0, fillSettings.current[1].settings, fillSettings.current[1].showBG)}
        </>
      }
      {/* slider container */}
      { !fillSettings.current[2] ? 
        <EmptyContainer top={3} left={2} width={1} height={1} color={9} {...options.current} />
      : 
        <>
          <EmptyContainer top={3} left={2} width={1} height={1} color={9} {...options.current} isMask={`fill-2`} />
          {fillSettings.current[2].showBG && <EmptyContainer top={3} left={2} width={1} height={1} color={9} {...options.current} />}
          {getFill(fillSettings.current[2].fill, _colors.current[9], `fill-2`, 1, 1, 3, 2, fillSettings.current[2].settings, fillSettings.current[2].showBG)}
        </>
      }
      {/* m container */}
      <EmptyContainer top={2} left={3} width={2} height={2} color={6} {...options.current} showContent={false}>
        <MGroup mousePos={mousePos.current} nft={true} />
      </EmptyContainer>
      
      {/* mint container */}
      { !fillSettings.current[3] ? 
        <EmptyContainer top={0} left={3} width={1} height={2} color={7} {...options.current} />
      : 
        <>
          <EmptyContainer top={0} left={3} width={1} height={2} color={7} {...options.current} isMask={`fill-3`}/>
          {fillSettings.current[3].showBG && <EmptyContainer top={0} left={3} width={1} height={2} color={7} {...options.current} />}
          {getFill(fillSettings.current[3].fill, _colors.current[7], `fill-3`, 1, 2, 0, 3, fillSettings.current[3].settings, fillSettings.current[3].showBG)}
        </>
      }

      {/* shuffle container */}
      { !fillSettings.current[4] ? 
        <EmptyContainer top={0} left={4} width={1} height={1} color={8} {...options.current} />
      : 
        <>
          <EmptyContainer top={0} left={4} width={1} height={1} color={8} {...options.current} isMask={`fill-4`}/>
          {fillSettings.current[4].showBG && <EmptyContainer top={0} left={4} width={1} height={1} color={8} {...options.current} />}
          {getFill(fillSettings.current[4].fill, _colors.current[8], `fill-4`, 1, 1, 0, 4, fillSettings.current[4].settings, fillSettings.current[4].showBG)}
        </>
      }

      {/* reset container */}
      { !fillSettings.current[5] ? 
        <EmptyContainer top={1} left={4} width={1} height={1} color={10} {...options.current} />
      : 
        <>
          <EmptyContainer top={1} left={4} width={1} height={1} color={10} {...options.current} isMask={`fill-5`} />
          {fillSettings.current[5].showBG && <EmptyContainer top={1} left={4} width={1} height={1} color={10} {...options.current} />}
          {getFill(fillSettings.current[5].fill, _colors.current[10], `fill-5`, 1, 1, 1, 4, fillSettings.current[5].settings, fillSettings.current[5].showBG)}
        </>
      }
      
    </svg>
  );
}

export default SVGNFT;
