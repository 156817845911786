import { useEffect, memo, useState, useMemo } from "react";

import Star from './star'
import Splat from './splat'
import Sun from './sun'

const FillerShape = memo(({size, color, active}) => {

  const [option, setOption] = useState(0)
  
  useEffect(() => {
    if (active) setOption(Math.floor(Math.random() * 3))
  }, [active])

  const _color = useMemo(() => ({...color}), [color])
  // _color.borderColor = color.bgColor
  // _color.bgColor = color.borderColor

  return (
    option === 0 ? 
      <Star size={size} color={_color} active={active} />
      : option === 1 ? 
      <Splat size={size} color={_color} active={active} />
      : 
      <Sun size={size} color={_color} active={active} />
  );
})

export default FillerShape;
