import { COLOUR_STORAGE } from '../utils/constants';
import { gsap } from "gsap/all";

// red, green, and blue coefficients
var rc = 0.2126;
var gc = 0.7152;
var bc = 0.0722;
// low-gamma adjust coefficient
var lowc = 1 / 12.92;

const adjustGamma = (a) => Math.pow((a + 0.055) / 1.055, 2.4);

// let settingsCache = [[], [], [], [], [], [], [], [], [], [], [], []]

export const relativeLuminance = (rgb) => {
  if (typeof rgb == "string") rgb = hexToRGB(rgb);
  var rsrgb = rgb[0] / 255;
  var gsrgb = rgb[1] / 255;
  var bsrgb = rgb[2] / 255;
  var r = rsrgb <= 0.03928 ? rsrgb * lowc : adjustGamma(rsrgb);
  var g = gsrgb <= 0.03928 ? gsrgb * lowc : adjustGamma(gsrgb);
  var b = bsrgb <= 0.03928 ? bsrgb * lowc : adjustGamma(bsrgb);
  return r * rc + g * gc + b * bc;
};

export const contrastRatio = (colorA, colorB) => {
  var a = relativeLuminance(colorA);
  var b = relativeLuminance(colorB);
  var l1 = Math.max(a, b);
  var l2 = Math.min(a, b);
  return (l1 + 0.05) / (l2 + 0.05);
};

export const getBestColors = (
  colors,
  others,
  min = 1,
  thresholds = [3, 2.5, 2]
) => {
  for (let i = 0; i < thresholds.length; i++) {
    const best = colors.filter((c) =>
      others.every((b) => contrastRatio(c, b) >= thresholds[i])
    );
    if (best.length >= min) return best;
  }
};

let white = '#fff'
let offWhite = '#ece3d7'
let bgGrey = '#222'
// let bgGreyRGB = hexToRGB(bgGrey)
let midGrey = '#aaa'

let brightPalette = [
  '#3abff0', // blue
  '#f08019', // orange
  '#e73d11', // red
  '#ffee04', // yellow
  '#f3a8be', // pink
  // '#787878', // grey
  '#c7bfb7', // grey
  '#1566af', // d. blue
  '#21a888', // green
  '#595f94' // d. orange
]

let neonPalette = [
  '#7170ee', // blue
  '#f5b539', // orange
  '#fb2e2d', // red
  '#fce35e', // yellow
  '#f560a0', // pink
  '#c7bfb7', // grey
  '#a06fc3', // purple
  '#11cc1c', // green
  '#e27f61' // peach
]

const bgTransition = [
  { bgColor: white }, { bgColor: white }, { bgColor: white }, { bgColor: white },
  { bgColor: white }, { bgColor: white }, { bgColor: white }, { bgColor: white },
  { bgColor: midGrey }, 
  { bgColor: bgGrey }, { bgColor: bgGrey },
  { bgColor: bgGrey }, { bgColor: bgGrey }
]
const textTransition = [{ bgColor: bgGrey }, { bgColor: bgGrey }, { bgColor: bgGrey }, { bgColor: bgGrey }, { bgColor: bgGrey }, { bgColor: white }, { bgColor: white }]
// const textTransition = [{ bgColor: bgGrey }, { bgColor: bgGrey }, { bgColor: bgGrey }, { bgColor: white }, { bgColor: white }]

let colorSettings = [

  // blue
  [
    { bgColor: brightPalette[0], bgOpacity: 1, borderColor: bgGrey, borderOpacity: 1, borderWeight: 12, containerColor: neonPalette[0], containerOpacity: 0 },
    { bgColor: offWhite, bgOpacity: 1, borderColor: brightPalette[6], borderOpacity: 1, borderWeight: 0, containerColor: neonPalette[0], containerOpacity: 1 },
    // { bgColor: white, bgOpacity: 0, borderColor: brightPalette[0], borderOpacity: 1, borderWeight: 5, containerColor: neonPalette[0], containerOpacity: 0 },
    { bgColor: neonPalette[0], bgOpacity: 1, borderColor: bgGrey, borderOpacity: 1, borderWeight: 2, containerColor: neonPalette[0], containerOpacity: 0 },
    { bgColor: bgGrey, bgOpacity: 0, borderColor: neonPalette[0], borderOpacity: 1, borderWeight: 4, containerColor: neonPalette[0], containerOpacity: 0 },
  ],
  // orange
  [
    { bgColor: brightPalette[1], bgOpacity: 1, borderColor: bgGrey, borderOpacity: 1, borderWeight: 12, containerColor: neonPalette[1], containerOpacity: 0 },
    { bgColor: offWhite, bgOpacity: 1, borderColor: brightPalette[1], borderOpacity: 1, borderWeight: 0, containerColor: neonPalette[1], containerOpacity: 1 },
    // { bgColor: white, bgOpacity: 0, borderColor: brightPalette[1], borderOpacity: 1, borderWeight: 5, containerColor: neonPalette[1], containerOpacity: 0 },
    { bgColor: neonPalette[1], bgOpacity: 1, borderColor: bgGrey, borderOpacity: 1, borderWeight: 2, containerColor: neonPalette[1], containerOpacity: 0 },
    { bgColor: bgGrey, bgOpacity: 0, borderColor: neonPalette[1], borderOpacity: 1, borderWeight: 4, containerColor: neonPalette[1], containerOpacity: 0 },
  ],
  // red 
  [
    { bgColor: brightPalette[2], bgOpacity: 1, borderColor: bgGrey, borderOpacity: 1, borderWeight: 12, containerColor: neonPalette[2], containerOpacity: 0 },
    { bgColor: offWhite, bgOpacity: 1, borderColor: brightPalette[2], borderOpacity: 1, borderWeight: 0, containerColor: neonPalette[2], containerOpacity: 1 },
    // { bgColor: white, bgOpacity: 0, borderColor: brightPalette[2], borderOpacity: 1, borderWeight: 5, containerColor: neonPalette[2], containerOpacity: 0 },
    { bgColor: neonPalette[2], bgOpacity: 1, borderColor: bgGrey, borderOpacity: 1, borderWeight: 2, containerColor: neonPalette[2], containerOpacity: 0 },
    { bgColor: bgGrey, bgOpacity: 0, borderColor: neonPalette[2], borderOpacity: 1, borderWeight: 4, containerColor: neonPalette[2], containerOpacity: 0 },
  ],
  // yellow
  [
    { bgColor: brightPalette[3], bgOpacity: 1,  borderColor: bgGrey, borderOpacity: 1, borderWeight: 12, containerColor: neonPalette[3], containerOpacity: 0 },
    { bgColor: offWhite, bgOpacity: 1, borderColor: brightPalette[1], borderOpacity: 1, borderWeight: 0, containerColor: neonPalette[3], containerOpacity: 1 },
    // { bgColor: white, bgOpacity: 0, borderColor: brightPalette[3], borderOpacity: 1, borderWeight: 5, containerColor: neonPalette[3], containerOpacity: 0 },
    { bgColor: neonPalette[3], bgOpacity: 1,  borderColor: bgGrey, borderOpacity: 1, borderWeight: 2, containerColor: neonPalette[3], containerOpacity: 0 },
    { bgColor: bgGrey, bgOpacity: 0, borderColor: neonPalette[3], borderOpacity: 1, borderWeight: 4, containerColor: neonPalette[3], containerOpacity: 0 },
  ],
  // pink
  [
    { bgColor: brightPalette[4], bgOpacity: 1, borderColor: bgGrey, borderOpacity: 1, borderWeight: 12, containerColor: neonPalette[4], containerOpacity: 0 },
    { bgColor: offWhite, bgOpacity: 1, borderColor: brightPalette[6], borderOpacity: 1, borderWeight: 0, containerColor: neonPalette[4], containerOpacity: 1 },
    // { bgColor: white, bgOpacity: 0, borderColor: brightPalette[4], borderOpacity: 1, borderWeight: 5, containerColor: neonPalette[4], containerOpacity: 0 },
    { bgColor: neonPalette[4], bgOpacity: 1, borderColor: bgGrey, borderOpacity: 1, borderWeight: 2, containerColor: neonPalette[4], containerOpacity: 0 },
    { bgColor: bgGrey, bgOpacity: 0, borderColor: neonPalette[4], borderOpacity: 1, borderWeight: 4, containerColor: neonPalette[4], containerOpacity: 0 },
  ],
  // black/grey
  [
    { bgColor: brightPalette[5], bgOpacity: 1, borderColor: bgGrey, borderOpacity: 1, borderWeight: 12, containerColor: neonPalette[5], containerOpacity: 0 },
    { bgColor: offWhite, bgOpacity: 1, borderColor: brightPalette[5], borderOpacity: 1, borderWeight: 0, containerColor: neonPalette[5], containerOpacity: 1 },
    // { bgColor: white, bgOpacity: 0, borderColor: bgGrey, borderOpacity: 1, borderWeight: 5, containerColor: neonPalette[5], containerOpacity: 0 },
    { bgColor: neonPalette[5], bgOpacity: 1, borderColor: bgGrey, borderOpacity: 1, borderWeight: 2, containerColor: neonPalette[5], containerOpacity: 0 },
    { bgColor: bgGrey, bgOpacity: 0, borderColor: neonPalette[5], borderOpacity: 1, borderWeight: 4, containerColor: neonPalette[5], containerOpacity: 0 },
  ],
  // dark blue
  [
    { bgColor: brightPalette[6], bgOpacity: 1, borderColor: bgGrey, borderOpacity: 1, borderWeight: 12, containerColor: neonPalette[6], containerOpacity: 0 },
    { bgColor: offWhite, bgOpacity: 1, borderColor: brightPalette[6], borderOpacity: 1, borderWeight: 0, containerColor: neonPalette[6], containerOpacity: 1 },
    // { bgColor: white, bgOpacity: 0, borderColor: brightPalette[6], borderOpacity: 1, borderWeight: 5, containerColor: neonPalette[6], containerOpacity: 0 },
    { bgColor: neonPalette[6], bgOpacity: 1, borderColor: bgGrey, borderOpacity: 1, borderWeight: 2, containerColor: neonPalette[6], containerOpacity: 0 },
    { bgColor: bgGrey, bgOpacity: 0, borderColor: neonPalette[6], borderOpacity: 1, borderWeight: 4, containerColor: neonPalette[6], containerOpacity: 0 },
  ],
  // green
  [
    { bgColor: brightPalette[7], bgOpacity: 1, borderColor: bgGrey, borderOpacity: 1, borderWeight: 12, containerColor: brightPalette[7], containerOpacity: 0 },
    { bgColor: offWhite, bgOpacity: 1, borderColor: brightPalette[7], borderOpacity: 1, borderWeight: 0, containerColor: neonPalette[7], containerOpacity: 1 },
    // { bgColor: white, bgOpacity: 0, borderColor: brightPalette[7], borderOpacity: 1, borderWeight: 5, containerColor: neonPalette[7], containerOpacity: 0 },
    { bgColor: neonPalette[7], bgOpacity: 1, borderColor: bgGrey, borderOpacity: 1, borderWeight: 2, containerColor: brightPalette[7], containerOpacity: 0 },
    { bgColor: bgGrey, bgOpacity: 0, borderColor: neonPalette[7], borderOpacity: 1, borderWeight: 4, containerColor: brightPalette[7], containerOpacity: 0 },
  ],
  // orange/peach
  [
    { bgColor: brightPalette[8], bgOpacity: 1, borderColor: bgGrey, borderOpacity: 1, borderWeight: 12, containerColor: brightPalette[8], containerOpacity: 0 },
    { bgColor: offWhite, bgOpacity: 1, borderColor: brightPalette[8], borderOpacity: 1, borderWeight: 0, containerColor: neonPalette[8], containerOpacity: 1 },
    // { bgColor: white, bgOpacity: 0, borderColor: brightPalette[7], borderOpacity: 1, borderWeight: 5, containerColor: neonPalette[7], containerOpacity: 0 },
    { bgColor: neonPalette[8], bgOpacity: 1, borderColor: bgGrey, borderOpacity: 1, borderWeight: 2, containerColor: brightPalette[8], containerOpacity: 0 },
    { bgColor: bgGrey, bgOpacity: 0, borderColor: neonPalette[8], borderOpacity: 1, borderWeight: 4, containerColor: brightPalette[8], containerOpacity: 0 },
  ],
  
]

// let defaultColors = colorSettings.map(h => h.map(r => ({...r, ...{ bgColor: r.bgColor, containerColor: r.containerColor || bgGrey, borderColor: r.borderColor || bgGrey }}) ) );
let defaultRgbColors = colorSettings.map(h => h.map(r => ({...r, ...{ bgColor: hexToRGB(r.bgColor), containerColor: hexToRGB(r.containerColor || bgGrey), borderColor: hexToRGB(r.borderColor || bgGrey) }}) ) );
// let rgbColors = colorSettings.map(h => h.sort(() => (Math.random() > .5) ? 1 : -1).map(r => ({...r, ...{ bgColor: hexToRGB(r.bgColor), containerColor: hexToRGB(r.containerColor || bgGrey), borderColor: hexToRGB(r.borderColor || bgGrey) }}) ) );

const shuffle = (_order) => {
  let order

  // settingsCache = [[], [], [], [], [], [], [], [], [], [], [], []]

  if (_order) {
    order = _order;
  } else {
    // init order array
    order = [...Array(colorSettings.length)].map((c, i) => i)

    // rearrange colours
    // order = order.sort(() => (Math.random() > .5) ? 1 : -1)  
    
    // random (can repeat) colours
    order = order.map(_ => Math.floor(Math.random() * order.length))

    order = order.map(o => ({index: o, order: [...Array(4)].map((c, i) => i).sort(() => (Math.random() > .5) ? 1 : -1)}))
  }

  localStorage.setItem(COLOUR_STORAGE, JSON.stringify(order));

  let shuffledColors = [...order].map(o => o.order.map(p => colorSettings[o.index][p] ))
  let shuffled = [...shuffledColors].map(h => h.map(r => ({...r, ...{ bgColor: hexToRGB(r.bgColor), containerColor: hexToRGB(r.containerColor || bgGrey), borderColor: hexToRGB(r.borderColor || bgGrey) }}) ) );
  
  // let shuffledColors = [...colorSettings].sort(() => (Math.random() > .5) ? 1 : -1)
  // let shuffled = [...shuffledColors].map(h => h.sort(() => (Math.random() > .5) ? 1 : -1).map(r => ({...r, ...{ bgColor: hexToRGB(r.bgColor), containerColor: hexToRGB(r.containerColor || bgGrey), borderColor: hexToRGB(r.borderColor || bgGrey) }}) ) );
  
  return [
    bgTransition.map(r => ({...r, ...{ bgColor: hexToRGB(r.bgColor), containerColor: hexToRGB(r.containerColor || bgGrey), borderColor: hexToRGB(r.borderColor || bgGrey) }}) ),
    textTransition.map(r => ({...r, ...{ bgColor: hexToRGB(r.bgColor), containerColor: hexToRGB(r.containerColor || bgGrey), borderColor: hexToRGB(r.borderColor || bgGrey) }}) ),
    ...shuffled
  ]
}

// let hexColors = [
//   bgTransition.map(r => ({...r, ...{ bgColor: r.bgColor, containerColor: r.containerColor || bgGrey, borderColor: r.borderColor || bgGrey }}) ),
//   textTransition.map(r => ({...r, ...{ bgColor: r.bgColor, containerColor: r.containerColor || bgGrey, borderColor: r.borderColor || bgGrey }}) ),
//   ...defaultColors
// ]

// let rgbColors = [
//   bgTransition.map(r => ({...r, ...{ bgColor: hexToRGB(r.bgColor), containerColor: hexToRGB(r.containerColor || bgGrey), borderColor: hexToRGB(r.borderColor || bgGrey) }}) ),
//   textTransition.map(r => ({...r, ...{ bgColor: hexToRGB(r.bgColor), containerColor: hexToRGB(r.containerColor || bgGrey), borderColor: hexToRGB(r.borderColor || bgGrey) }}) ),
//   ...defaultRgbColors
// ]

let rgbColors = [
  bgTransition.map(r => ({...r, ...{ bgColor: hexToRGB(r.bgColor), containerColor: hexToRGB(r.containerColor || bgGrey), borderColor: hexToRGB(r.borderColor || bgGrey) }}) ),
  textTransition.map(r => ({...r, ...{ bgColor: hexToRGB(r.bgColor), containerColor: hexToRGB(r.containerColor || bgGrey), borderColor: hexToRGB(r.borderColor || bgGrey) }}) ),
  ...defaultRgbColors
]

function hexToRGB(h) {
  let r = 0, g = 0, b = 0;

  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

  // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }
  
  // return {r: +r, g: +g, b: +b};
  return [+r, +g, +b];
}

function getTextColor (color, position) {
  let textColor = color.borderColor;

  // if (position === 0) position = 0.001
  position = gsap.utils.clamp( 0.001, 0.999, position)
  let controll = position < 0.8 ? 1 - gsap.utils.clamp(0, 1, (position - 0.55) * 4) : 0;

  // if (position < 0.8) {
  //   controll = 1 - gsap.utils.clamp(0, 1, (position - 0.55) * 4)
  // }

  try {
    if (color.bgOpacity < 1 && controll > 0 && rgbColors[1].length && position) {
      const transparentTextColor = gsap.utils.interpolate(rgbColors[1], position).bgColor.split(',')
      if (color.borderColor && transparentTextColor) {
        textColor = gsap.utils.interpolate([color.borderColor, transparentTextColor],  gsap.utils.clamp(0, 0.5, (1 - color.bgOpacity) * controll));
      }
    }
  } catch (err) {
    console.log('textcolor err')
  }

  return textColor
}

function getSettingsAt({gradient, position, colorGroup, useCache = true, slideVal, shuffled, mobile}) {
  const _pos = Math.floor(position * 100)

  // if (useCache && gradient && settingsCache[gradient][_pos]) {
  //   return settingsCache[gradient][_pos]
  // }

  colorGroup = colorGroup || rgbColors
  const colors = colorGroup[gradient];
  let cols = gsap.utils.interpolate(colors, _pos / 100)

  cols = { ...cols, ...{                   
    bgColor: typeof cols.bgColor === 'string' ? cols.bgColor.split(',') : cols.bgColor,
    borderColor: typeof cols.borderColor === 'string' ? cols.borderColor.split(',') : cols.borderColor,
    containerColor: typeof cols.containerColor === 'string' ? cols.containerColor.split(',') : cols.containerColor,
  }}

  if (mobile) cols.borderWeight = cols.borderWeight * 0.6

  // cols.textColor = cols.borderColor;
  cols.textColor = getTextColor(cols, slideVal || (_pos / 100), shuffled);

  // if (useCache && gradient) settingsCache[gradient][_pos] = cols

  return cols;

  /**
   * use contrastRatio
   */

  // let bgColor = gsap.utils.interpolate(rgbColors[0], position)
  // bgColor = typeof bgColor.bgColor === 'string' ? bgColor.bgColor.split(',') : bgColor.bgColor
  // let contrast = contrastRatio(cols.borderColor, bgColor)

  // if (contrast < 2 && cols.bgOpacity < 0.5) {
  //   const contrastDiff = gsap.utils.clamp(0, 1, 1 - (contrast - 1))

  //   let betterColors = getBestColors(brightPalette, [bgColor])
  //   if (betterColors.length) {
  //     let replacement = hexToRGB(betterColors[betterColors.length-1])
  //     cols.textColor = gsap.utils.interpolate([cols.textColor, replacement], contrastDiff)
  //   }
  // }

  /**
   * Original method
   */

  // colorGroup = colorGroup || rgbColors
  // const colors = colorGroup[gradient];
  // const posScale = position * (colors.length - 1)

  // const before = colors[Math.floor(posScale)]
  // const after = colors[Math.ceil(posScale)]

  // const sectionProgress = posScale === 1 ? 1 : posScale - Math.floor(posScale)

  // let width = window.innerWidth
  // let borderScale = applyScale ? width > 1120 ? 1 : width > 760 ? 0.8 : 0.6 : 1

  // let _borderWeight = (before.borderWeight + ((after.borderWeight - before.borderWeight) * sectionProgress)) * borderScale
  // let borderWeight = Math.floor(_borderWeight * 4)/4

  // let bOpacity = _borderWeight < 1 ? _borderWeight : 1

  // return {
  //   // bgColor: {
  //   //   r: before.bgColor[0] + ((after.bgColor[0] - before.bgColor[0]) * sectionProgress),
  //   //   g: before.bgColor[1] + ((after.bgColor[1] - before.bgColor[1]) * sectionProgress),
  //   //   b: before.bgColor[2] + ((after.bgColor[2] - before.bgColor[2]) * sectionProgress)
  //   // },
  //   bgColor: [
  //     before.bgColor[0] + ((after.bgColor[0] - before.bgColor[0]) * sectionProgress),
  //     before.bgColor[1] + ((after.bgColor[1] - before.bgColor[1]) * sectionProgress),
  //     before.bgColor[2] + ((after.bgColor[2] - before.bgColor[2]) * sectionProgress)
  //   ],
  //   bgOpacity: before.bgOpacity + ((after.bgOpacity - before.bgOpacity) * sectionProgress),
  //   containerColor: [
  //     before.containerColor[0] + ((after.containerColor[0] - before.containerColor[0]) * sectionProgress),
  //     before.containerColor[1] + ((after.containerColor[1] - before.containerColor[1]) * sectionProgress),
  //     before.containerColor[2] + ((after.containerColor[2] - before.containerColor[2]) * sectionProgress)
  //   ],
  //   containerOpacity: before.containerOpacity + ((after.containerOpacity - before.containerOpacity) * sectionProgress),
  //   borderColor: [
  //     before.borderColor[0] + ((after.borderColor[0] - before.borderColor[0]) * sectionProgress),
  //     before.borderColor[1] + ((after.borderColor[1] - before.borderColor[1]) * sectionProgress),
  //     before.borderColor[2] + ((after.borderColor[2] - before.borderColor[2]) * sectionProgress)
  //   ],
  //   borderWeight: borderWeight,
  //   borderOpacity: (before.borderOpacity + ((after.borderOpacity - before.borderOpacity) * sectionProgress)) * bOpacity
  // }
}

const clamp = (value, min, max) => Math.max(Math.min(value, max), min);

function rgbToHex(rgb) {
  // console.log('rgb', rgb)
  var r = clamp(~~rgb[0], 0, 255);
  var g = clamp(~~rgb[1], 0, 255);
  var b = clamp(~~rgb[2], 0, 255);
  return "#" + (b | (g << 8) | (r << 16) | (1 << 24)).toString(16).slice(1);
}

export {rgbColors, hexToRGB, getSettingsAt, shuffle, rgbToHex, brightPalette}