import classNames from 'classnames'
import ArrowIcon from '../arrowIcon'
import styles from  './expandButton.module.scss';

function ExpandButton({ title, width = 0, height = 0, opacity = 1, expanded = 0, flip = false, vertical = false, color, onHoverCB, onClickCB, direction, hideArrow, disabled }) {

  const onHover = () => {
    if (disabled) return
    if (onHoverCB) onHoverCB()
  }
  
  const onClick = () => {
    if (disabled) return
    if (onClickCB) onClickCB()
  }

  return (
      <div 
        className={classNames([styles.header, expanded === 0 && styles.isClosed, disabled && styles.disabled])} 
        style={{
          width: `${width - (color.borderWeight * 2)}px`, 
          height: `${height - (color.borderWeight * 2)}px`, 
          color: `rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`,
          opacity: opacity
        }}
        onClick={onClick} onMouseOver={onHover}>
        <div className={styles.center} style={{
          width: vertical ? `${height - (color.borderWeight * 2)}px` : `${width - (color.borderWeight * 2)}px`, 
          height: vertical ? `${width - (color.borderWeight * 2)}px` : `${height - (color.borderWeight * 2)}px`, 
          transform: vertical ? `rotate(${flip ? 270 : 90}deg)` : 'none'
        }}>
          <div className={styles.content}>
            
            <div className={styles.title} style={{paddingRight: expanded > 0.5 && hideArrow ? 0 : '40px' }}>
              {title}
              {!disabled && (
                <>
                  <div className={styles.arrow} style={{opacity: expanded > 0.5 && hideArrow ? 0 : 1 }}><ArrowIcon color={color} direction={direction} /></div>
                  <span className={styles.expandLabel}>{expanded ? 'close' : 'find out more'}</span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
  );
}

export default ExpandButton;
