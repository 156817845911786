function RefreshIcon({ color, direction }) {
  return (
    <svg style={{ width: '60%', height: '60%', verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden'}} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path 
        stroke={`rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`}
        strokeLinecap="round" strokeWidth={1}
        fill={`rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`}
        d="M227.4,432.3l58.3-35.1c17.5-10.5,40.2-4.9,50.7,12.6c10.5,17.5,4.9,40.2-12.6,50.7l-161.2,96.9L65.7,396.3
	c-10.5-17.5-4.9-40.2,12.6-50.7c17.5-10.5,40.2-4.8,50.7,12.6l29,48.2c30.9-103.7,106.3-190.6,209.7-234.5
	c187.8-79.7,404.6,7.9,484.4,195.8c79.7,187.8-7.9,404.7-195.6,484.4c-135.6,57.6-290.6,28.8-396.5-70
	c-14.9-13.9-15.7-37.3-1.8-52.2c13.9-14.9,37.3-15.7,52.2-1.8c84.7,79,208.7,102.1,317.2,56C777.7,720.3,847.8,546.8,784,396.6
	c-63.8-150.3-237.3-220.4-387.5-156.6C312.3,275.7,251.2,347.3,227.4,432.3z"
      />
    </svg>
  );
}

export default RefreshIcon;