import { cloneElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import ScrollProgress from './scrollProgress';
import { AppContext } from "../../context/appContext";
import { gsap, Power2 } from "gsap/all";
import styles from  './scrollContent.module.scss';

function ScrollContent({ children, color, width = 2, height = 2, active }) {

  const { screenSize, sizes } = useContext(AppContext);

  const _body = useRef();
  const _content = useRef();
  const [progress, setProgress] = useState(0);
  const hideScrollerTO = useRef();
  const [hideScroller, setHideScroller] = useState(0);

  // const [_active, setActive] = useState(active===1)

  const _active = useRef(active===1)

  const updateProgress = useCallback((e, _progress) => {
    const wrapperHeight = _body.current.getBoundingClientRect().height;
    const _height = _content.current.getBoundingClientRect().height;

    if (_progress) {
      _body.current.scrollTop = _progress * (_height - wrapperHeight)
      setProgress(_progress);
    } else {
      setProgress(_body.current.scrollTop / (_height - wrapperHeight));
    }
  }, [])

  const collapse = useCallback(() => {
    let start = {val: progress}
    gsap.to(start, {val: 0, ease: Power2.easeInOut, duration: 0.25, onUpdate: () => {
        updateProgress({}, start.val)
      },
      onComplete: () => {
        collapsing.current = false
      }
    })
  }, [progress, updateProgress])

  const collapsing = useRef(false)

  useEffect(() => {
    if (collapsing.current && _active.current && active === 0) {
      _active.current = false
    } else if (collapsing.current) {
      return
    } else if (active === 1 && !_active.current) {
      _active.current = true
    } else if (_active && active < 1 && active > 0) {
      // transitioning
      collapsing.current = true
      collapse()
    } else if (_active && active === 0) { 
      _active.current = false
    }
  }, [active, collapse])

  useEffect(() => {
    setHideScroller(false)
    clearTimeout(hideScrollerTO.current)
    hideScrollerTO.current = null

    hideScrollerTO.current = setTimeout(() => {
      clearTimeout(hideScrollerTO.current)
      hideScrollerTO.current = null
      setHideScroller(true)
    }, 1000)

    return () => clearTimeout(hideScrollerTO.current)
  }, [progress])

  useEffect(() => {
    _body.current.addEventListener('scroll', updateProgress)
  }, [updateProgress])

  useEffect(() => {
    updateProgress()
  }, [screenSize, updateProgress])

  const progressSize = useMemo(() => {
    return { width: 60, height: (sizes.heights[2] - sizes.heights[0]) * 0.6 }
  }, [sizes.heights])

  const size = useMemo(() => {
    return {
      width: sizes.widths[width] - (color.borderWeight * 2),
      height: sizes.heights[height] - (color.borderWeight * 2)
    }
  }, [color.borderWeight, sizes, width, height])

  return (
    <div className={styles.scrollWrapper} style={{
      width: `${size.width}px`,
      height: `${size.height}px`,
      WebkitMaskImage: `-webkit-linear-gradient(top, rgba(0,0,0,0) 1%, rgba(0,0,0,1) ${Math.min(progress * 10, 1) * 10}%, rgba(0,0,0,1) ${99 - ((1 - Math.min(Math.max((progress - 0.9) * 10, 0), 1)) * 10)}%, rgba(0,0,0,0) 99%)`
    }}>
      <div className={styles.scrollableContent} ref={_body} style={{
        color: `rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`,
        overflow: active ? 'scroll' : 'hidden',
        WebkitMaskImage: `-webkit-linear-gradient(top, rgba(0,0,0,0) 1%, rgba(0,0,0,1) ${Math.min(progress * 10, 1) * 10}%, rgba(0,0,0,1) ${99 - ((1 - Math.min(Math.max((progress - 0.9) * 10, 0), 1)) * 10)}%, rgba(0,0,0,0) 99%)`
      }}>
        <div className={styles.content} ref={_content}>
          {children && cloneElement(children, { size: size, sizes, color: color})}
        </div>
      </div>

      <div className={classNames([styles.progressWrapper, hideScroller && styles.hidden])} style={{
        height: `${size.height}px`
      }}>
          <ScrollProgress color={color} size={progressSize} progress={progress} updateProgress={updateProgress} />
      </div>
      
    </div>
  )
}

export default ScrollContent;
