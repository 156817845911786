// import { useContext } from "react";
// import { AppContext } from "../../context/appContext";
import { gsap } from "gsap/all";
import { SVG_WIDTH, SVG_HEIGHT } from '../../utils/constants'

function Dots({ width, height, top = 0, left = 0, id, rotation = 0, radius = 20, aligned = false, color, stroke = false, factor = 1, showBG }) {

  let colWidth = SVG_WIDTH / 5
  let colHeight = SVG_HEIGHT / 4
  
  let expandedWidth = Math.round(((colWidth * width) * 2) * 100) / 100
  let expandedHeight = Math.round(((colHeight * height) * 2) * 100) / 100

  if (expandedWidth > expandedHeight) {
    expandedHeight = expandedWidth
  } else {
    expandedWidth = expandedHeight
  }

  let expandedLeft = Math.round(((colWidth * left) - (expandedWidth/4)) * 100) / 100
  let expandedtTop = Math.round(((colHeight * top) - (expandedHeight/4)) * 100) / 100

  // const { colors } = useContext(AppContext);
  const strokeWidth = stroke ? Math.round((gsap.utils.clamp(2, 12, color.borderWeight)) * 100) / 100 : 0
  const dotWidth = Math.round(((radius * 2) + strokeWidth) * 100) / 100
  const gutter = Math.round((dotWidth * (.1 * factor)) * 100) / 100
  const columns = Math.ceil(expandedWidth / (dotWidth + gutter)) + 1
  const rows = Math.ceil(expandedHeight / (dotWidth + gutter)) + 1
  const colorChoice = showBG ? 'borderColor' : 'bgColor'

  const rotatePoints = (x, y, cx, cy, angle) => {
    let cos = Math.cos(angle);
    let sin = Math.sin(angle);
    let temp;
        temp = ((x-cx)*cos - (y-cy)*sin) + cx;  
        y = Math.round((((x-cx)*sin + (y-cy)*cos) + cy) * 100) / 100;
        x = Math.round(temp * 100) / 100;
    return {x, y};
  }

  const drawDot = (i, j, x, y) => {
    const centerX = expandedLeft + (expandedWidth/2)
    const centerY = expandedtTop + (expandedHeight/2)
    const rotated = rotatePoints(x, y, centerX, centerY, rotation)
    if (rotated.x < (colWidth * left) || rotated.x > (colWidth * left) + (colWidth * width) || rotated.y < colHeight * top || rotated.y > (colHeight * top) + (colHeight * height)) return null

    return (
      <circle 
        key={`dot-${id}-${i}-${j}`}
        cx={rotated.x} cy={rotated.y} r={radius} 
        fill={stroke ? 'none' : color[colorChoice]} 
        stroke={stroke ? color[colorChoice] : 'transparent'} 
        strokeWidth={strokeWidth}
      />
    )
  }

  return (
    <g clipPath={`url(#${id})`}>
      { [...Array(rows)].map((a, i) => 
        [...Array(columns)].map((b, j) =>  
          drawDot(i, j, expandedLeft + (j * (dotWidth + gutter)) + (dotWidth/2) - (!aligned && i%2 === 0 ? ((dotWidth + gutter) / 2) : 0 ), expandedtTop + (i * (dotWidth + gutter)) + (dotWidth/2))
        )
      )}
    </g>
  );
}

export default Dots;
