import { useCallback, useEffect, useRef, memo, useState } from "react";
import { gsap, Elastic } from "gsap/all";

const Sun = memo(({size, color, active}) => {

  const [_size, updateSize] = useState(size)
  const canvas = useRef()
  const ctx = useRef()
  const resizeTO = useRef()
  const visible = useRef(false)
  const scale = useRef(0.5)
  const opacity = useRef(0)
  const rotation = useRef(1)
  const revealTO = useRef()
  const spikes = useRef(0)
  const spikeOptions = useRef([3, 4, 6, 10, 16])
  const radius = useRef(0)
  const radiusOptions = useRef([5, 10, 20])
  const shape = useRef(0)
  let inTweens = useRef([])

  useEffect(() => {
    ctx.current = canvas.current.getContext("2d");
  }, [])

  const reveal = useCallback(() => {
    let val = { scale: scale.current, opacity: opacity.current, rotation: rotation.current }
    spikes.current = Math.floor(Math.random() * spikeOptions.current.length)
    radius.current = Math.floor(Math.random() * radiusOptions.current.length)
    shape.current = Math.round(Math.random())

    inTweens.current = [
      gsap.to(val, {duration: 0.1, opacity: 1, ease: 'Power2.easeOut',
      // gsap.to(val, {duration: 1, opacity: 1, ease: Elastic.easeOut.config(1, 0.5),
        onUpdate: () => {
          opacity.current = val.opacity
        }
      }),
      gsap.to(val, {duration: 1, scale: 1, rotation: 0, ease: Elastic.easeOut.config(1, 0.5),
        onUpdate: () => {
          scale.current = val.scale
          rotation.current = val.rotation
        }
      })
    ]
  }, [])

  const hide = useCallback(() => {
    let val = { scale: scale.current, opacity: opacity.current, rotation: rotation.current }
    
    for (let tween of inTweens.current) {
      tween.kill();
    }
      
    gsap.to(val, {duration: 0.25, scale: 0.5, opacity: 0, ease: 'Power2.easeOut',
      onUpdate: () => {
        scale.current = val.scale
        opacity.current = val.opacity
      },
      onComplete: () => {
        visible.current = false
      }
    });

    gsap.to(val, {duration: 0.25, rotation: 1, ease: 'Power2.easeOut',
      onUpdate: () => {
        rotation.current = val.rotation
      }
    });
  }, [])

  useEffect(() => {
    if (active && !visible.current) {
      // show
      clearTimeout(revealTO.current)
      revealTO.current = null
      visible.current = true
      revealTO.current = setTimeout(reveal, 100)
    } else if (!active && visible.current) {
      // hide
      clearTimeout(revealTO.current)
      revealTO.current = null
      hide()
    }
  }, [active, hide, reveal])

  useEffect(() => {
    clearTimeout(resizeTO.current)
    resizeTO.current = null

    resizeTO.current = setTimeout(() => {
      clearTimeout(resizeTO.current)
      resizeTO.current = null
      canvas.current.width = size.width
      canvas.current.height = size.height
      updateSize({width: size.width, height: size.height})
    }, 100)
  }, [size.width, size.height])

  const ctg = (x) => { return 1 / Math.tan(x); }

  const getPoint = useCallback((phi, width, height) => {
    let c = Math.cos(phi)
    let s = Math.sin(phi)
    let x, y

    if (width * Math.abs(s) < height * Math.abs(c)) {
      x = Math.sign(c) * width / 2
      y = Math.tan(phi) * x
    } else {
      y = Math.sign(s) * height / 2
      x = ctg(phi) * y
    }

    return {x, y}
  }, [])

  const getLength = (p1, p2) => {
    var xDist = p2.x - p1.x;
    var yDist = p2.y - p1.y;
    return Math.sqrt(xDist * xDist + yDist * yDist);
  }

  const drawShape = useCallback(() => {
    ctx.current.globalAlpha = opacity.current
    ctx.current.fillStyle = `rgb(${color.bgColor[0]}, ${color.bgColor[1]}, ${color.bgColor[2]})`;
    
    let noOfSpikes = spikeOptions.current[spikes.current]
    let lineThickness = radiusOptions.current[radius.current]
    let padding = 20
    
    let width = _size.width - padding
    let height = _size.height - padding
    let rotationOffset2 = rotation.current * 20
    let centerPoint = {x: _size.width/2, y: _size.height/2}

    for (let i = 0; i < noOfSpikes; i++) {
      let outerDegreeAngle = ((180/noOfSpikes) * i) + tick.current + rotationOffset2
      let outerRadianAngle = (Math.PI/180) * outerDegreeAngle
      let outerPoint = getPoint(outerRadianAngle, width * scale.current - padding, height * scale.current - padding);

      let distance = getLength({x: outerPoint.x + centerPoint.x, y: outerPoint.y + centerPoint.y}, {x: centerPoint.x, y: centerPoint.y})
      distance = distance * 2
      
      ctx.current.translate(centerPoint.x, centerPoint.y);
      ctx.current.rotate(outerRadianAngle);
      if (shape.current) {
        ctx.current.beginPath();
        ctx.current.ellipse(0, 0, distance/2, lineThickness/2, 0, 0, 2 * Math.PI)
        ctx.current.fill();
      } else {
        ctx.current.fillRect(-(distance/2), -(lineThickness/2), distance, lineThickness);
      }
      ctx.current.rotate(-outerRadianAngle);
      ctx.current.translate(-centerPoint.x, -centerPoint.y);
    }
  }, [_size, getPoint, color])

  const requestRef = useRef()
  const tick = useRef(0)
  const lastTick = useRef(0)

  const animate = useCallback(() => {
    let now = Date.now()
    if (now - lastTick.current > 1000/60) {
      tick.current += 0.05;
      lastTick.current = now
      if (ctx.current && canvas.current && visible.current) {
        ctx.current.clearRect(0, 0, canvas.current.width, canvas.current.height);
        drawShape()
      }
    }
    requestRef.current = requestAnimationFrame(animate);
  }, [drawShape])

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [animate])

  return (
    <canvas ref={canvas} style={{ width: '100%', height: '100%' }} />
  );
})

export default Sun;
