import { Composite, Bodies, Body, Common } from 'matter-js'
import { rgbToHex } from '../../utils/colors'
import { gsap, Power2 } from "gsap/all";

const sidesCat = 0x0001
const topCat = 0x0002
const bottomCat = 0x0004

function Container ({world, width, height, left, top, radius, gutter, screenSize, color, bgColor, slideVal = 0, collides, staticCat, randomShape}) {
  this.world = world;
  this.collides = collides
  this.staticCat = staticCat

  
  let borderWeight = color.borderWeight
  
  let colWidth = screenSize.width / 5
  let colHeight = screenSize.height / 4
  let _width = (colWidth * width) - gutter
  let _height = (colHeight * height) - gutter
  let _left = (colWidth * left) + (_width/2) + (gutter / 2)
  let _top = (colHeight * top) + (_height/2) + (gutter / 2)
  this.size = {width: _width, height: _height}
  
  let _radius = _height/2 < radius ? _height/2 : radius
  let offset = -(screenSize.height/2) + ((screenSize.height/2) * slideVal)

  this.startPos = {
    x: _left,
    y: _top
  }

  this.static = true

  this.collidesTop = Math.random() < 0.7
  let collidePoint = this.collidesTop ? topCat | sidesCat : bottomCat | sidesCat

  if (randomShape) {
    let sides = Math.round(Common.random(1, 8));
    this.shape = Bodies.polygon(_left, _top + (staticCat ? 0 : offset), sides, _width/3, { 
      chamfer: { radius: Common.random(0, 40) },
      collisionFilter: {
        mask: this.collides ? collidePoint : sidesCat,
      },
      render: {
        fillStyle: rgbToHex(color.bgColor),
        strokeStyle: rgbToHex(color.borderColor),
        lineWidth: borderWeight * 2
      },
      isStatic: true,
      mass: 1,
      restitution: 0.9,
      friction: 0.05,
    })
  } else {
    let texture = getTexture(_width, _height, color, _radius, borderWeight)

    this.shape = Bodies.rectangle(_left, _top + (staticCat ? 0 : offset), _width, _height, { 
      render: { 
        sprite: {
          texture: texture
        },
        opacity: staticCat ? 0 : 1,
      },
      collisionFilter: {
        mask: this.collides ? collidePoint : sidesCat,
      },
      chamfer: { radius: _radius },
      isStatic: true,
      mass: 1,
      restitution: 0.9,
      friction: 0.05,
    })
  }

  let rotation = { angle: 0 }

  var wobbleTL = gsap.timeline({onComplete: () => { this.release() }});
  wobbleTL.to(rotation, { angle: 0.002, ease: Power2.easeOut, duration: 0.1, onUpdate: () => { Body.rotate(this.shape, rotation.angle) }});
  wobbleTL.to(rotation, { angle: -0.004, ease: Power2.easeOut, duration: 0.1, onUpdate: () => { Body.rotate(this.shape, rotation.angle) }});
  wobbleTL.to(rotation, { angle: 0.004, ease: Power2.easeOut, duration: 0.1, onUpdate: () => { Body.rotate(this.shape, rotation.angle) }});
  wobbleTL.to(rotation, { angle: -0.004, ease: Power2.easeOut, duration: 0.1, onUpdate: () => { Body.rotate(this.shape, rotation.angle) }});
  wobbleTL.to(rotation, { angle: 0.004, ease: Power2.easeOut, duration: 0.1, onUpdate: () => { Body.rotate(this.shape, rotation.angle) }});
  wobbleTL.to(rotation, { angle: -0.004, ease: Power2.easeOut, duration: 0.1, onUpdate: () => { Body.rotate(this.shape, rotation.angle) }});
  wobbleTL.to(rotation, { angle: 0.002, ease: Power2.easeOut, duration: 0.1, onUpdate: () => { Body.rotate(this.shape, rotation.angle) }});
  wobbleTL.pause();

  if (staticCat) this.shape.collisionFilter.category = staticCat

  Composite.add(world, this.shape)

  this.wobble = () => {
    wobbleTL.play(0);
  }

  this.release = () => {
    this.static = false
    Body.setStatic(this.shape, false)

    if (this.collides) {
      let ls = Math.round(Math.random() * 5000)
      this.collideTO = setTimeout(() => {
        if (this.collides) {

          let chanceToBot = Math.random() < 0.3
          if (this.collidesTop && chanceToBot) {
            let ls2 = Math.round(Math.random() * 3000)
            this.collidesTop = false
            // bottomCat | sidesCat
            // Body.set(this.shape, { collisionFilter: { mask: 5 } })
            this.shape.collisionFilter.mask = 5
            this.scaleUp()
            setTimeout(() => {
              this.scaleUp()
              this.collideOff()
            }, ls2)
          } else {
            this.scaleUp()
            this.collideOff()
          }
        }
      }, ls)
    }
  }

  this.scaleUp = () => {
    let scale = this.shape.render.sprite.xScale + 0.02
    this.shape.render.sprite.xScale = scale
    this.shape.render.sprite.yScale = scale
    Body.scale(this.shape, scale, scale)
  }

  this.canCollide = () => {
    return this.collides
  }

  this.collideOff = () => {
    this.collides = false
    if (this.collideTO) {
      clearTimeout(this.collideTO)
      this.collideTO = null
    }
    Body.set(this.shape, {collisionFilter: {mask: sidesCat}})
  }

  this.updatePosition = (slideVal) => {
    if (!this.static || this.staticCat) return
    let offset = -(screenSize.height/2) + ((screenSize.height/2) * slideVal)
    Body.setPosition(this.shape, {x: this.startPos.x, y: this.startPos.y + offset})
  }

  function roundRect(ctx, width, height, radius = 5, fill = false, stroke = true, x = 0, y = 0) {
    if (typeof radius === 'number') {
      radius = {tl: radius, tr: radius, br: radius, bl: radius};
    } else {
      radius = {...{tl: 0, tr: 0, br: 0, bl: 0}, ...radius};
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    if (fill) {
      ctx.fill();
    }
    if (stroke) {
      ctx.stroke();
    }
  }

  function getTexture (_width, _height, _color, radius, borderWeight) {
    let canvas = document.createElement('canvas');
    canvas.id = "boxtexture";
    canvas.width = _width;
    canvas.height = _height;
    canvas.style.zIndex = 9999;
    canvas.style.position = "absolute";
    canvas.style.top = 0;
    canvas.style.left = 0;
    let ctx = canvas.getContext("2d");

    // border
    ctx.fillStyle = `rgba(${_color.borderColor[0]}, ${_color.borderColor[1]}, ${_color.borderColor[2]}, ${_color.borderOpacity})`;
    roundRect(ctx, _width - 2, _height - 2, radius, true, false, 1, 1)

    // bg
    ctx.fillStyle = `rgb(${bgColor.bgColor[0]}, ${bgColor.bgColor[1]}, ${bgColor.bgColor[2]})`;
    roundRect(ctx, _width - (borderWeight * 2) - 2, _height - (borderWeight * 2) - 2, Math.max(radius - borderWeight, 0), true, false, borderWeight + 1, borderWeight + 1)

    // fill
    ctx.fillStyle = `rgba(${_color.bgColor[0]}, ${_color.bgColor[1]}, ${_color.bgColor[2]}, ${_color.bgOpacity})`;
    roundRect(ctx, _width - (borderWeight * 2) - 2, _height - (borderWeight * 2) - 2, Math.max(radius - borderWeight, 0), true, false, borderWeight + 1, borderWeight + 1)
    let texture = canvas.toDataURL()
    canvas = null
    return texture
  }
}

export default Container;