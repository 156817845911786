import React, { createContext, useState, useEffect, useRef, useCallback, useMemo } from "react";
// import { useLocation, useHistory } from 'react-router-dom'
import ReactGA from "react-ga4";
import { rgbColors, getSettingsAt, shuffle } from '../utils/colors';
import { POS_STORAGE, COLOUR_STORAGE, RADIUS_STORAGE, SLIDER_ONBOARDING_STORAGE, MOBILE_THRESHOLD, MIDDLE_THRESHOLD, RESIZE_TO } from '../utils/constants'
import { gsap, Power2 } from "gsap/all";

export const AppContext = createContext({});

let startingProgress = localStorage.getItem(POS_STORAGE);
startingProgress = startingProgress ? parseFloat(startingProgress) : 0
let startingRadii = localStorage.getItem(RADIUS_STORAGE);
let sliderOnboarding = localStorage.getItem(SLIDER_ONBOARDING_STORAGE) || startingProgress > 0.05;
startingRadii = startingRadii ? startingRadii.split(',') : [1, 1, 0, 1]

const AppProvider = ({ children }) => {

  /*
  ** Browser
  */

  const ua = navigator.userAgent || navigator.vendor || window.opera;
  // var isInstagram = (ua.indexOf('Instagram') > -1) ? true : false;

  const fps = useRef(60)
  const colorGroup = useRef()
  const transitioning = useRef(false)
  const [showSliderOnboarding, setSliderOnboarding] = useState(!sliderOnboarding)

  const browser = useRef({
    instagram: ua.indexOf('Instagram') > -1,
    ios: /iPad|iPhone|iPod/.test(ua) && !window.MSStream
  });

  const [showMobile, setShowMobile] = useState(window.innerWidth < MOBILE_THRESHOLD && window.innerHeight > window.innerWidth);
  const [isMobile, setIsMobile] = useState(undefined);
  const [orientation, setOrientation] = useState(undefined);

  // const _screenSize = useRef({width: window.innerWidth, height: window.innerHeight})
  const [screenSize, setScreenSize] = useState({width: window.innerWidth, height: window.innerHeight});

  const [shuffled, setShuffled] = useState(false);
  const [borderRadius, setBorderRadius] = useState(50);
  // const [gutter, setGutter] = useState(40);
  const [fontSize, setFontSize] = useState(20);
  const [colors, setColors] = useState();
  const [borderRadii, setBorderRadii] = useState(startingRadii)
  
  const [physicsActive, setPhysicsActive] = useState(false);

  const colWidth = useMemo(() => screenSize.width / 5, [screenSize.width])
  const colHeight = useMemo(() => screenSize.height / 4, [screenSize.height])

  const gutter = useMemo(() => {
    if (screenSize.width < MOBILE_THRESHOLD) {
      return 15
    } else if (screenSize.width < MIDDLE_THRESHOLD) {
      return 25
    } else {
      return 40
    }
  }, [screenSize.width])

  const sizes = useMemo(() => ({
    widths: [colWidth - gutter, (colWidth * 2) - gutter, (colWidth * 3) - gutter, (colWidth * 4) - gutter, (colWidth * 5) - gutter],
    heights: [colHeight - gutter, (colHeight * 2) - gutter, (colHeight * 3) - gutter, (colHeight * 4) - gutter],
  }), [gutter, colWidth, colHeight])

  useEffect(() => {
    onResize()
    fps.current = isMobile ? 30  : 60
  }, [isMobile])

  const onResize = (e) => {
    let width = window.innerWidth;
    let height = window.innerHeight;
    
    setScreenSize({ width, height });
    setOrientation(height > width ? 'portrait' : 'landscape');
  }

  /*
  ** Sliders
  */

  const [slideVal, setSlideVal] = useState(startingProgress);
  const _slideValue = useRef(startingProgress)
  const progressTarget = useRef(slideVal)

  const updateSlider = useCallback((target) => {
    progressTarget.current = target
  }, [])

  const verticalStart = showMobile ? 1 : 0
  const [explosion, setExplosion] = useState(false);
  const [verticalSlide1Val, setVerticalSlide1Val] = useState(verticalStart);
  const _verticalSlide1Val = useRef(verticalStart)
  const verticalSlide1Target = useRef(verticalStart)

  const updateVerticalSlider1 = (target) => {
    verticalSlide1Target.current = target
  }

  const [pullDownVal, setPullDownVal] = useState(0);
  const _pullDownVal = useRef(0)
  const pullDownTarget = useRef(0)

  const updatePullDown = (target) => {
    pullDownTarget.current = target
  }

  const [rtlSlideVal, setRtlSlideVal] = useState(1);
  const _rtlSlideValue = useRef(1)
  const rtlProgressTarget = useRef(1)

  const isRTLOpen = useRef(false)

  const updateRtlSlider = (target) => {

    if (target === 0 && isRTLOpen.current) {
      isRTLOpen.current = false
      ReactGA.event({
        category: "page2",
        action: "drag",
        label: 'reveal'
      });
    }
    
    if (target === 1 && !isRTLOpen.current) {
      isRTLOpen.current = true
      ReactGA.event({
        category: "page2",
        action: "drag",
        label: 'hide'
      });
    }

    rtlProgressTarget.current = target
  }

  const [btmSlideVal, setBtmSlideVal] = useState(0);
  const _btmSlideValue = useRef(0)
  const btmProgressTarget = useRef(0)

  const isOpen = useRef(false)

  const updateBtmSlider = (target) => {

    if (target === 0 && isOpen.current) {
      isOpen.current = false
      ReactGA.event({
        category: showMobile ? 'about' : "noumena",
        action: "drag",
        label: showMobile ? 'hide' : 'collapse'
      });
    }
    
    if (target === 1 && !isOpen.current) {
      isOpen.current = true
      ReactGA.event({
        category: showMobile ? 'about' : "noumena",
        action: "drag",
        label: showMobile ? 'reveal' : 'expand'
      });
    }

    btmProgressTarget.current = target
  }

  /*
  ** calculate colours & radius
  */

  const getColors = useCallback((group, pos, cache = true) => {
    let _colors = []
    let _group = group || colorGroup.current || rgbColors
    let _pos = pos || slideVal
    for (let i = 0; i < _group.length; i++) {
      _colors.push(getSettingsAt({gradient: i, position: _pos, colorGroup: _group, useCache: cache, slideVal: slideVal, shuffled: shuffled, mobile: showMobile}))
    }

    return _colors
  }, [showMobile, shuffled, slideVal])

  const radiusScale = useMemo(() => screenSize.width > 1120 ? 1 : screenSize.width > 760 ? 0.8 : 0.8, [screenSize])

  const getRadius = useCallback((to) => {
    let maxRadi = sizes.widths[0]/2 < 120 ? sizes.widths[0]/2 : 120
    let border = colors ? colors[2].borderWeight : 0
    maxRadi = (maxRadi - (border/2)) * radiusScale
    to = to || borderRadii
    let radiii = [[to[0], 0], [to[1], 0.33], [to[2], 0.66], [to[3], 1]];
    radiii = radiii.map(i => [parseFloat(i[0]), i[1]])
    
    let before, after
    
    for (let i = 0; i < radiii.length - 1; i++) {
      if (slideVal >= radiii[i][1] && slideVal <= radiii[i+1][1]) {
        before = radiii[i]
        after = radiii[i+1]
        break;
      }
    }

    let progress = (slideVal - before[1]) / (after[1] - before[1])
    
    let _radius = before[0] + ((after[0] - before[0]) * progress)
    _radius = Math.floor(((_radius * maxRadi) - 1) * 2)/2
    _radius = gsap.utils.clamp(1, maxRadi, _radius)

    if (_radius) setBorderRadius(_radius)
  }, [colors, slideVal, sizes, radiusScale, borderRadii])

  /*
  ** if screensize changes update radius after delay
  */

  let radiusTO = useRef()

  useEffect(() => {    
    clearTimeout(radiusTO.current)
    radiusTO.current = null

    radiusTO.current = setTimeout(() => {
      clearTimeout(radiusTO.current)
      radiusTO.current = null
      getRadius()
    }, RESIZE_TO)

    return () => clearTimeout(radiusTO.current)
  }, [screenSize, getRadius])

  /*
  ** if slideval changes update colours, radius and storage after delay
  */

  const storageTO = useRef()

  useEffect(() => {
    setColors(getColors())
    getRadius()

    clearTimeout(storageTO.current);
    storageTO.current = null;

    storageTO.current = setTimeout(() => {
      clearTimeout(storageTO.current);
      storageTO.current = null;

      localStorage.setItem(POS_STORAGE, slideVal);

      ReactGA.event({
        category: "style-slider",
        action: "drag",
        label: slideVal
      });
    }, 1000)

    return () => clearTimeout(storageTO.current);
  }, [slideVal]) // eslint-disable-line


  /*
  ** reset colours, clear storage, reset expanded areas
  */

  const resetStyle = async () => {
    if (transitioning.current) return
    transitioning.current = true

    const from = getColors(colorGroup.current)
    const to = getColors(rgbColors)

    localStorage.removeItem(COLOUR_STORAGE);

    await Promise.all([shuffleRadius(true), transitionShuffle(from, to)])

    ReactGA.event({
      category: "reset-style",
      action: "click"
    });

    colorGroup.current = null
    setShuffled(false);
    resetOverlays();
    transitioning.current = false
  }

  /*
  ** transition between colour groups
  */

  const transitionShuffle = useCallback((start, end) => {
    return new Promise((resolve) => {
      const group = start.map((s, i) => {
        return [s, end[i]]
      })

      let val = { pos: 0 }

      gsap.to(val, {
        duration: 0.25,
        pos: 1, 
        onUpdate: () => {
          setColors(getColors(group, val.pos, false))
        },
        onComplete: () => { 
          resolve()
        }
      });
    })
  }, [getColors])

  const shuffleRadius = useCallback((reset) => {
    return new Promise(resolve => {
      const start = [...borderRadii]
      // let newRadii = [Math.round(Math.random() * 4) / 4, Math.round(Math.random() * 4) / 4, Math.round(Math.random() * 4) / 4, Math.round(Math.random() * 4) / 4]
      let newRadii = [...Array(4)].map(() => Math.round(Math.random() * 4) / 4)

      if (reset) {
        newRadii = [1, 1, 0, 1]
        localStorage.removeItem(RADIUS_STORAGE);
      } else {
        localStorage.setItem(RADIUS_STORAGE, newRadii);
      }

      let val = {pos: 0}

      gsap.to(val, {
        duration: 0.25,
        pos: 1, 
        onUpdate: () => {
          getRadius(gsap.utils.interpolate(start, newRadii, val.pos))
        },
        onComplete: () => { 
          setBorderRadii(newRadii)
          resolve()
        }
      });
    })
  }, [borderRadii, getRadius])


  /*
  ** shuffle colours
  */

  const shuffleStyle = useCallback(async () => {
    if (transitioning.current) return
    transitioning.current = true

    const from = colorGroup.current ? getColors(colorGroup.current) : getColors(rgbColors)
    colorGroup.current = shuffle()
    const to = getColors(colorGroup.current)

    await Promise.all([shuffleRadius(), transitionShuffle(from, to)])

    ReactGA.event({
      category: "shuffle-style",
      action: "click",
    });

    setShuffled(true);
    transitioning.current = false
  }, [getColors, shuffleRadius, transitionShuffle])

  /*
  ** the thickness of the slider shape
  */

  const sliderThickness = useMemo(() => {
    if (screenSize.width < MOBILE_THRESHOLD) {
      return 70
    } else {
      return Math.max(Math.min(screenSize.width * 0.1, 130), 60)
    }
  }, [screenSize.width])

  /*
  ** container states (expanded/collapsed)
  */

  const [bottomScale, setBottomScale] = useState(0);
  const [infoExpanded, setInfoExpanded] = useState(0);
  const [rightScale, setRightScale] = useState(0);
  const [rightExpanded, setRightExpanded] = useState(0);
  const [extraBitScale, setExtraBitScale] = useState(0);
  const layoutVals = useRef({ infoHover: 0, rightHover: 0, infoClick: 0, rightClick: 0, extraHover: 0 })

  const onInfoHover = (hover) => {
    gsap.to(layoutVals.current, {infoHover: hover ? 1 : 0, ease: Power2.easeOut, duration: 0.25, onUpdate: () => {
      setBottomScale(layoutVals.current.infoHover);
    }});
  }

  const onExtraHover = (hover) => {
    gsap.to(layoutVals.current, {extraHover: hover ? 1 : 0, ease: Power2.easeOut, duration: 0.25, onUpdate: () => {
      setExtraBitScale(layoutVals.current.extraHover);
    }});
  }

  const onRightHover = (hover) => {
    gsap.to(layoutVals.current, {rightHover: hover ? 1 : 0, ease: Power2.easeOut, duration: 0.25, onUpdate: () => {
      setRightScale(layoutVals.current.rightHover);
    }});
  }

  const onRightClick = (close = false) => {
    ReactGA.event({
      category: "minter",
      action: "click",
      label: close ? 'collapse' : 'expand'
    });

    gsap.to(layoutVals.current, {rightClick: close ? 0 : 1, ease: Power2.easeOut, duration: 0.25, onUpdate: () => {
      setRightExpanded(layoutVals.current.rightClick);
    }});
  }

  const onInfoClick = (close = false) => {
    ReactGA.event({
      category: "about-me",
      action: "click",
      label: close ? 'collapse': 'expand'
    });
    gsap.to(layoutVals.current, {infoClick: close ? 0 : 1, ease: Power2.easeInOut, duration: 0.25, onUpdate: () => {
      setInfoExpanded(layoutVals.current.infoClick);
    }});
  }

  const resetOverlays = () => {
    onInfoClick(true)
    onRightClick(true)
    onRightHover()
    onInfoHover()
    updateBtmSlider(0)
    updateRtlSlider(1)
    updateVerticalSlider1(verticalStart)
  }

  /*
  ** Mouse/Touch
  */

  const [mousePos, setMousePos] = useState({x: 0.5, y: 0.5});
  const _mousePos = useRef({x: 0.5, y: 0.5});
  const mousePosTarget = useRef({x: 0.5, y: 0.5});

  const onMouseMove = (e) => {
    if (!e) return
    let x = e.touches && e.touches.length ? e.touches[0].clientX : e.clientX
    let y = e.touches && e.touches.length ? e.touches[0].clientY : e.clientY

    let width = window.innerWidth
    let height = window.innerHeight

    mousePosTarget.current = {x: x/width, y: y/height}
  }

  useEffect(() => {
    // _screenSize.current = screenSize
    // if (screenSize && orientation) setShowMobile(screenSize.width < MOBILE_THRESHOLD && orientation === 'portrait')
    if (screenSize) setShowMobile(screenSize.width < MOBILE_THRESHOLD)
    // getSizes()
  }, [screenSize, gutter])


  // add events
  
  const requestRef = useRef();

  useEffect(() => {
    window.addEventListener('resize', onResize)

    if (window.screen.orientation) {
      window.screen.orientation.addEventListener("change", onResize)
    } else {
      window.addEventListener('orientationchange', onResize);
    }

    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('touchmove', onMouseMove)

    requestRef.current = requestAnimationFrame(animate.current);

    return () => {
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('touchmove', onMouseMove)
      window.removeEventListener('orientationchange', onResize);
      window.screen.orientation.removeEventListener("change", onResize)
      window.removeEventListener('resize', onResize)
      cancelAnimationFrame(requestRef.current);
    }
  }, [])

  /*
  ** Animation Frame
  */

  const previousTimeRef = useRef();

  const animate = useRef((time) => {
    if (!previousTimeRef.current || time - previousTimeRef.current > 1000/fps.current) {

      let lerpFactor = showMobile ? 0.4 : 0.2

      if (Math.round(_slideValue.current * 100) / 100 !== Math.round(progressTarget.current * 100) / 100) {
        let _progress = _slideValue.current + ((progressTarget.current - _slideValue.current) * lerpFactor)
        _progress = _progress >= 0.995 ? 0.999 : _progress <= 0.005 ? 0.001 : (Math.round(_progress * 10000) / 10000)
        _slideValue.current = _progress
        setSlideVal(_progress)
      }

      if (_btmSlideValue.current !== Math.round(btmProgressTarget.current * 1000) / 1000) {
        let _btmProgress = _btmSlideValue.current + ((btmProgressTarget.current - _btmSlideValue.current) * lerpFactor)
        _btmProgress = _btmProgress >= 0.995 ? 1 : _btmProgress <= 0.005 ? 0 : (Math.round(_btmProgress * 10000) / 10000)
        _btmSlideValue.current = _btmProgress
        setBtmSlideVal(_btmProgress)
      }

      if (_rtlSlideValue.current !== Math.round(rtlProgressTarget.current * 1000) / 1000) {
        let _rtlProgress = _rtlSlideValue.current + ((rtlProgressTarget.current - _rtlSlideValue.current) * lerpFactor)
        _rtlProgress = _rtlProgress >= 0.995 ? 1 : _rtlProgress <= 0.005 ? 0 : (Math.round(_rtlProgress * 10000) / 10000)
        _rtlSlideValue.current = _rtlProgress
        setRtlSlideVal(_rtlProgress)
      }

      
      if (_verticalSlide1Val.current !== Math.round(verticalSlide1Target.current * 1000) / 1000) {
        let _vrtcl1Progress = _verticalSlide1Val.current + ((verticalSlide1Target.current - _verticalSlide1Val.current) * lerpFactor)
        _vrtcl1Progress = _vrtcl1Progress >= 0.995 ? 1 : _vrtcl1Progress <= 0.005 ? 0 : (Math.round(_vrtcl1Progress * 10000) / 10000)
        _verticalSlide1Val.current = _vrtcl1Progress
        setVerticalSlide1Val(_vrtcl1Progress)
      }

      if (_pullDownVal.current !== Math.round(pullDownTarget.current * 1000) / 1000) {
        let _pullProgress = _pullDownVal.current + ((pullDownTarget.current - _pullDownVal.current) * lerpFactor)
        _pullProgress = _pullProgress >= 0.995 ? 1 : _pullProgress <= 0.005 ? 0 : (Math.round(_pullProgress * 10000) / 10000)
        _pullDownVal.current = _pullProgress
        setPullDownVal(_pullProgress)
      }
     
      // mouse position

      const mousefactor = 100
      if (Math.floor(_mousePos.current.x * mousefactor) !== Math.floor(mousePosTarget.current.x * mousefactor) || Math.floor(_mousePos.current.y * mousefactor) !== Math.floor(mousePosTarget.current.y * mousefactor)) {
        _mousePos.current = {
          x: _mousePos.current.x + ((mousePosTarget.current.x - _mousePos.current.x) * 0.15),
          y: _mousePos.current.y + ((mousePosTarget.current.y - _mousePos.current.y) * 0.15)
        }
        setMousePos(_mousePos.current)
      }

      previousTimeRef.current = time
    }
    requestRef.current = requestAnimationFrame(animate.current);
  })

  /*
  ** starting properties
  */

  const onInit = useRef(() => {

    onResize()
    setTimeout(onResize, 600)

    // set initial properties

    setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    getRadius()

    // set initial colours

    let existingColours = localStorage.getItem(COLOUR_STORAGE)
    if (existingColours) {
      setShuffled(true);
      let existingArrangement = JSON.parse(existingColours)
      let _shuffledGroup = shuffle(existingArrangement)
      colorGroup.current = _shuffledGroup
      let _colours = getColors(colorGroup.current, startingProgress);
      setColors(_colours);
    } else {
      setColors(getColors())
    }
  })

  useEffect(() => {
    // setSliderOnboarding
    if (slideVal > 0 && showSliderOnboarding) {
      localStorage.setItem(SLIDER_ONBOARDING_STORAGE, true);
      setSliderOnboarding(false)
    }
  }, [slideVal, showSliderOnboarding])

  useEffect(() => {
    onInit.current()
  }, [])
  
  return (
    <AppContext.Provider value={{
      browser, isMobile, orientation, screenSize, mousePos, 
      gutter, borderRadius, setBorderRadius, 
      fontSize, setFontSize, slideVal, updateSlider, 
      colors, shuffleStyle, resetStyle, shuffled,
      onInfoHover, bottomScale, onInfoClick, infoExpanded,
      resetOverlays, rightScale, onRightHover,
      rightExpanded, onRightClick, sliderThickness,
      btmSlideVal, updateBtmSlider, sizes, showMobile,
      rtlSlideVal, updateRtlSlider, showSliderOnboarding,
      extraBitScale, onExtraHover, 
      verticalSlide1Val, updateVerticalSlider1,
      physicsActive, setPhysicsActive,
      pullDownVal, updatePullDown,
      explosion, setExplosion
    }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;