import { useEffect, useRef, useState, useContext, Fragment, useCallback, useMemo } from "react";
import { AppContext } from "../../context/appContext";
// import { gsap } from 'gsap/all'
import SliderCanvas from './canvas'
import CircleButton from "../circleButton";
import classNames from "classnames";
import { RESIZE_TO } from '../../utils/constants';
import styles from  './centralSlider.module.scss';

const CentralSlider = ({ color, size, disabled = false }) => {

  const { borderRadius, screenSize, slideVal, updateSlider, colors, sliderThickness, showSliderOnboarding } = useContext(AppContext);
  
  const [canvasBox, setCanvasBox] = useState();
  const [canvasLineThickness, setCanvasLineThickness] = useState();
  const [canvasBorderRadius, setCanvasBorderRadius] = useState();

  const [grabbing, setGrabbing] = useState();
  const [handlePoint, setHandlePoint] = useState();
  const wrapper = useRef();
  const childRef = useRef();

  const svgPath = useRef();
  const svgLine = useRef();

  const lineRadius = useMemo(() => {
    return borderRadius < sliderThickness/2 ? borderRadius : sliderThickness/2
  }, [borderRadius, sliderThickness])

  const box = useMemo(() => {
    return {
      width: size.width - color.borderWeight,
      height: size.height - color.borderWeight,
      x: size.left,
      y: size.top,
    }
  }, [color.borderWeight, size])

  const radius = useMemo(() => {
    return (sliderThickness/2) - color.borderWeight - 10
  }, [sliderThickness, color.borderWeight])

  const updateHandle = useCallback(() => {
    if (svgLine.current && !disabled) setHandlePoint(svgLine.current.getPointAtLength(slideVal * svgLine.current.getTotalLength()))
  }, [disabled, slideVal])

  useEffect(() => {
    updateHandle()
  }, [updateHandle, color])

  const resizeTO = useRef()

  useEffect(() => {
    clearTimeout(resizeTO.current)
    resizeTO.current = null

    resizeTO.current = setTimeout(() => {
      clearTimeout(resizeTO.current)
      resizeTO.current = null
      updateHandle()
    }, RESIZE_TO)

    return () => clearTimeout(resizeTO.current)
  }, [screenSize, updateHandle])
  
  const initTO = useRef()

  const updateCanvas = useCallback(() => {
    if (box && box.width) {
      setCanvasBox(box)
      setCanvasLineThickness(sliderThickness)
      setCanvasBorderRadius(borderRadius)
    }
  }, [box, borderRadius, sliderThickness])


  const canvasInterval = useRef();
  const lastVal = useRef(0);

  useEffect(() => {
    canvasInterval.current = setInterval(() => {
      lastVal.current = slideVal
    }, 500)
    return () => clearInterval(canvasInterval.current)
  }, []) // eslint-disable-line

  useEffect(() => {
    clearTimeout(initTO.current)
    initTO.current = null

    initTO.current = setTimeout(() => {
      clearTimeout(initTO.current)
      initTO.current = null
      updateCanvas()
    }, 200)

    if (Math.abs(slideVal - lastVal.current) > 0.2) {
      updateCanvas()
      lastVal.current = slideVal
    }
    
    return () => clearTimeout(initTO.current)
  }, [updateCanvas, slideVal])

  const startPos = useRef(undefined);

  const handleDown = (e) => {
    if (disabled || !e) return
    setGrabbing(true)
    let x = e.touches && e.touches.length ? e.touches[0].clientX : e.clientX
    let y = e.touches && e.touches.length ? e.touches[0].clientY : e.clientY
    startPos.current = { x: x - box.x, y: y - box.y }
    let _target = childRef.current.getClosestPoint(color, box, slideVal, x - box.x, y - box.y)
    updateSlider(_target)
  }

  const handleUp = (e) => {
    setGrabbing(false)
    startPos.current = undefined
  }

  const onContainerMove = (e) => {
    if (disabled || !e) return
    let x = e.touches && e.touches.length ? e.touches[0].clientX : e.clientX
    let y = e.touches && e.touches.length ? e.touches[0].clientY : e.clientY

    if (startPos.current) {
      let progress = childRef.current.getClosestPoint(color, box, slideVal, x - box.x, y - box.y)
      if (Math.abs(progress - slideVal) > 0.5) return
      updateSlider(progress)
    }
  }

  const arrowAngle = useMemo(() => {
    if (!box) return 

    const threshold = 0.05
    const _startPos = box.width - sliderThickness * 1.5 - (color.borderWeight * 2)

    const lineParts = [
      _startPos - (sliderThickness/2),
      box.height - sliderThickness,
      box.width - sliderThickness,
      box.height - sliderThickness
    ]

    const totalLength = lineParts[0] + lineParts[1] + lineParts[2] + lineParts[3]
    const linePartsRelative = lineParts.map(p => p/totalLength)

    let angle = -90
    if (slideVal < linePartsRelative[0]) {
      // angle
      let curve = slideVal - (linePartsRelative[0] - threshold)
      if (curve > 0) {
        angle = angle + ((curve / threshold) * 45)
      }
    } else if (slideVal < linePartsRelative[0] + linePartsRelative[1]) {
      angle = 0
      let progressStart = slideVal - linePartsRelative[0]
      let progressEnd = progressStart - (linePartsRelative[1] - threshold)
      if (progressStart < threshold) {
        angle = angle - ((1 - (progressStart / threshold)) * 45)
      } else if (progressEnd > 0) {
        angle = angle + ((progressEnd / threshold) * 45)
      }
    } else if (slideVal < linePartsRelative[0] + linePartsRelative[1] + linePartsRelative[2]) {
      angle = 90
      let progressStart = slideVal - (linePartsRelative[0] + linePartsRelative[1])
      let progressEnd = progressStart - (linePartsRelative[2] - threshold)
      if (progressStart < threshold) {
        angle = angle - ((1 - (progressStart / threshold)) * 45)
      } else if (progressEnd > 0) {
        angle = angle + ((progressEnd / threshold) * 45)
      }
    } else if (slideVal < linePartsRelative[0] + linePartsRelative[1] + linePartsRelative[2] + linePartsRelative[3]) {
      angle = 180
      let progressStart = slideVal - (linePartsRelative[0] + linePartsRelative[1] + linePartsRelative[3])
    
      if (progressStart < 0.2) {
        angle = angle - ((1 - (progressStart / 0.2)) * 45)
      }
    }

    return angle - 90
  }, [box, color, sliderThickness, slideVal])

  const getPath = useMemo(() => {
    if (!box.width || !box.height) return null
    let path = `M 0 ${box.height / 2}`

    if (borderRadius > 1) {
      path += ` V ${borderRadius}`
      path += ` A ${borderRadius} ${borderRadius} 0 0 1 ${borderRadius} 0`
    } else {
      path += ` V 0`
    }

    if (borderRadius > 1) {
      path += ` H ${box.width - borderRadius}`
      path += ` A ${borderRadius} ${borderRadius} 0 0 1 ${box.width} ${borderRadius}`
      } else {
      path += ` H ${box.width}`
    }

    if (borderRadius * 2 > sliderThickness) {
      path += ` V ${box.height - (sliderThickness/2)}`
      path += ` A ${sliderThickness/2} ${sliderThickness/2} 0 0 1 ${box.width - sliderThickness} ${box.height - (sliderThickness/2)}`
    } else {
      if (borderRadius > 1) {
        path += ` V ${box.height - borderRadius}`
        path += ` A ${borderRadius} ${borderRadius} 0 0 1 ${box.width - borderRadius} ${box.height}`
      } else {
        path += ` V ${box.height}`
      }

      if (borderRadius > 1) {
        path += ` H ${box.width - sliderThickness + borderRadius}`
        path += ` A ${borderRadius} ${borderRadius} 0 0 1 ${box.width - sliderThickness} ${box.height - borderRadius}`
      } else {
        path += ` H ${box.width - sliderThickness}`
      }
    }

    if (borderRadius > 1) {
      path += ` V ${sliderThickness + borderRadius}`
      path += ` A ${borderRadius} ${borderRadius} 1 0 0 ${box.width - sliderThickness - borderRadius} ${sliderThickness}`
    } else {
      path += ` V ${sliderThickness}`
    }

    if (box.height - (sliderThickness * 2) < (borderRadius * 2)) {
      path += ` H ${sliderThickness + ((box.height - (sliderThickness * 2)) / 2)}`
      path += ` A ${((box.height - (sliderThickness * 2)) / 2)} ${((box.height - (sliderThickness * 2)) / 2)} 1 0 0 ${sliderThickness + ((box.height - (sliderThickness * 2)) / 2)} ${box.height - sliderThickness}`
    } else {
      if (borderRadius > 1) {
        path += ` H ${sliderThickness + borderRadius}`
        path += ` A ${borderRadius} ${borderRadius} 1 0 0 ${sliderThickness} ${sliderThickness + borderRadius}`
      } else {
        path += ` H ${sliderThickness}`
      }

      if (borderRadius > 1) {
        path += ` V ${box.height - sliderThickness - borderRadius}`
        path += ` A ${borderRadius} ${borderRadius} 1 0 0 ${sliderThickness + borderRadius} ${box.height - sliderThickness}`
      } else {
        path += ` V ${box.height - sliderThickness}`
      }
    }          

    if (borderRadius * 2 > sliderThickness) {
      path += ` H ${box.width - (sliderThickness * 1.5) - (color.borderWeight * 3)}`
      path += ` A ${sliderThickness/2} ${sliderThickness/2} 0 0 1 ${box.width - (sliderThickness * 1.5) - (color.borderWeight * 3)} ${box.height}`
    } else {
      if (borderRadius > 1) {
        path += ` H ${box.width - sliderThickness - (color.borderWeight * 3) - borderRadius}`
        path += ` A ${borderRadius} ${borderRadius} 0 0 1 ${box.width - sliderThickness - (color.borderWeight * 3)} ${box.height - sliderThickness + borderRadius}`
      } else {
        path += ` H ${box.width - sliderThickness - (color.borderWeight * 3)}`
      }

      if (borderRadius > 1) {
        path += ` V ${box.height - borderRadius}`
        path += ` A ${borderRadius} ${borderRadius} 0 0 1 ${box.width - sliderThickness - (color.borderWeight * 3) - borderRadius} ${box.height}`
      } else {
        path += ` V ${box.height}`
      }
    }

    if (borderRadius > 1) {
      path += ` H ${borderRadius}`
      path += ` A ${borderRadius} ${borderRadius} 0 0 1 ${0} ${box.height - borderRadius}`
    } else {
      path += ` H 0`
    }

    path += ` Z`

    return path
  }, [borderRadius, box.width, box.height, color, sliderThickness])

  const getLine = useMemo(() => {
    if (!box.width || ! box.height) return null
    let path = `M ${box.width - (sliderThickness * 1.5) - (color.borderWeight * 3)} ${box.height - (sliderThickness/2)}`

    if (borderRadius > 1) {
      path += ` H ${(sliderThickness/2) + lineRadius}`
      path += ` A ${lineRadius} ${lineRadius} 0 0 1 ${sliderThickness/2} ${box.height - (sliderThickness/2) - lineRadius}`
    } else {
      path += ` H ${sliderThickness/2}`
    }

    if (borderRadius > 1) {
      path += ` V ${(sliderThickness/2) + lineRadius}`
      path += ` A ${lineRadius} ${lineRadius} 0 0 1 ${sliderThickness/2 + lineRadius} ${sliderThickness/2}`
    } else {
      path += ` V ${sliderThickness/2}`
    }
    
    if (borderRadius > 1) {
      path += ` H ${box.width - (sliderThickness/2) - lineRadius}`
      path += ` A ${lineRadius} ${lineRadius} 0 0 1 ${box.width - (sliderThickness/2)} ${sliderThickness/2 + lineRadius}`
    } else {
      path += ` H ${box.width - (sliderThickness/2)}`
    }

    path += ` V ${box.height - (sliderThickness/2)}`

    return path
  }, [borderRadius, box.width, box.height, color, lineRadius, sliderThickness])

  return (
    <div className={classNames([styles.container, grabbing && styles.grabbing, disabled && styles.disabled])} ref={wrapper} >
      { box && box.width > 0 && box.height > 0 && ( <>
        <svg preserveAspectRatio="none" className={styles.svg} width={box.width} height={box.height} viewBox={`0 0 ${box.width} ${box.height}`} style={{ top: `${color.borderWeight / 2}px`, left: `${color.borderWeight / 2}px` }}>
          
          <path 
          className={styles.sliderPath}
          fill={`rgb(${color.bgColor[0]}, ${color.bgColor[1]}, ${color.bgColor[2]})`} 
          fillOpacity={color.bgOpacity}
          strokeWidth={color.borderWeight} 
          stroke={`rgb(${color.borderColor[0]}, ${color.borderColor[1]}, ${color.borderColor[2]})`}
          strokeOpacity={color.borderOpacity}
          ref={svgPath} 
          onTouchStart={handleDown} onMouseDown={handleDown} 
          onTouchMove={onContainerMove} onMouseMove={onContainerMove}
          onTouchEnd={handleUp}
          onMouseUp={handleUp} onMouseOut={handleUp} 
          strokeLinecap="round" 
          // style={{filter: `url(#displacementFilter)`}}
          d={getPath} />

          <path id="sliderPath" ref={svgLine} style={{pointerEvents: 'none'}} 
            d={getLine} fill="none" 
            strokeWidth={Math.max(color.borderWeight, 2)}
            stroke={`rgb(${color.borderColor[0]}, ${color.borderColor[1]}, ${color.borderColor[2]})`}
            strokeLinecap="round" />

            {handlePoint && !disabled && (
              <Fragment>

                <circle cx={handlePoint.x} cy={handlePoint.y} r={radius} id='path-handle'
                  fill={`rgb(${colors[0].bgColor[0]}, ${colors[0].bgColor[1]}, ${colors[0].bgColor[2]})`} 
                  strokeWidth={color.borderWeight * 2}
                  stroke={`rgb(${color.borderColor[0]}, ${color.borderColor[1]}, ${color.borderColor[2]})`}
                  strokeOpacity={color.borderOpacity}
                  style={{pointerEvents: 'none', paintOrder: 'stroke'}}
                />

                <path strokeWidth={1.5} stroke={`rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`} strokeLinecap="round" fill="none"
                  className={styles.arrow}
                  transform={`rotate(${arrowAngle}, ${handlePoint.x} ${handlePoint.y})`}
                  d={`M ${handlePoint.x - 10} ${handlePoint.y} H ${handlePoint.x + 10}`}
                /> 

                <path strokeWidth={1.5} stroke={`rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`} strokeLinecap="round" fill="none"
                  className={styles.arrow}
                  transform={`rotate(${arrowAngle}, ${handlePoint.x} ${handlePoint.y})`}
                  d={`M ${handlePoint.x} ${handlePoint.y - 9} L ${handlePoint.x + 10} ${handlePoint.y} L ${handlePoint.x} ${handlePoint.y + 9}`}
                />

                {/* <text>
                  <textPath href='#path-handle' alignmentBaseline='after-edge' textLength={30} startOffset={0} fill={'black'}>hihi</textPath>
                </text> */}

              </Fragment>
            )}
        </svg>
      </>)}

      {handlePoint && !disabled && showSliderOnboarding && (
        <CircleButton copy="drag to change the style → " r={radius + (color.borderWeight*4)} center={{x: handlePoint.x + (color.borderWeight/2), y: handlePoint.y + (color.borderWeight/2)}} />
      )}

      <SliderCanvas ref={childRef} box={canvasBox} lineThickness={canvasLineThickness} borderRadius={canvasBorderRadius} />
    </div>
  )
}

export default CentralSlider;
