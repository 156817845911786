import ReactGA from "react-ga4";
import AppProvider from './context/appContext';
import Grid from './components/grid';
import Helmet from 'react-helmet'
import { useEffect, useRef, useState } from "react";

ReactGA.initialize("G-S0N0RT83W5");
ReactGA.send("pageview");

function App() {

  const [iconId, setIconId] = useState(1)
  const intervalRef = useRef()

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setIconId(id => id === 15 ? 1 : id += 1)
    }, 1000)

    return () => clearInterval(intervalRef.current) 
  }, [])

  return (
    <AppProvider>
      <Helmet>
          <link rel="icon" href={`/favicons/128x128/sams_${iconId}.png`} />
          {/* <link rel="icon" type="image/png" href={`/favicons/32x32/sams_${iconId}.png`} />
          <link rel="icon" type="image/png" sizes="192x192" href={`/favicons/192x192/sams_${iconId}.png`} /> */}
          {/* <link rel="icon" type="image/png" sizes="192x192" href={icons192[iconId]} /> */}
          {/* <link rel="icon" type="image/png" sizes="32x32" href={`/favicons/32x32/sams_${iconId}.png`} />
          <link rel="icon" type="image/png" sizes="96x96" href={`/favicons/96x96/sams_${iconId}.png`} />
          <link rel="icon" type="image/png" sizes="16x16" href={`/favicons/16x16/sams_${iconId}.png`} /> */}
      </Helmet>
      <Grid />
    </AppProvider>
  );
}

export default App;
