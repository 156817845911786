import { useContext, useEffect, useRef, useCallback, useMemo } from "react";
import { AppContext } from "../../context/appContext";
import ArrowIcon from '../arrowIcon'
import styles from  './grabber.module.scss';

function Grabber({ color, active }) {

  const { sizes, gutter, pullDownVal, updatePullDown, updateVerticalSlider1, setExplosion, physicsActive, setPhysicsActive } = useContext(AppContext);

  const startPos = useRef(undefined)

  const onPress = useCallback((e) => {
    if (!active) return false
    startPos.current = {x: e.clientX, y: e.clientY}
  }, [active])

  const onMouseMove = useCallback((e) => { 
    if (!active || !startPos.current) return false
    let progress = Math.min((e.clientY - startPos.current.y)/sizes.heights[1], 1)
    updatePullDown(progress)
  }, [active, sizes.heights, updatePullDown])

  useEffect(() => {
    if (!physicsActive && pullDownVal > 0.2) setPhysicsActive(true)
  }, [pullDownVal, physicsActive, setPhysicsActive])

  const onMouseUp = useCallback(() => { 
    if (startPos.current) {
      if (pullDownVal > 0.4) {
        updateVerticalSlider1(0)
        setExplosion(true)
      }
      startPos.current = undefined
      updatePullDown(0)
    }
  }, [updatePullDown, pullDownVal, updateVerticalSlider1, setExplosion])

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('mouseup', onMouseUp)
    
    return () => {
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('mouseup', onMouseUp)
    }
  }, [onMouseMove, onMouseUp])

  const topHeight = useMemo(() => { return sizes.heights[1] - (color.borderWeight * 2) }, [sizes, color.borderWeight])

  return (
    <div className={styles.grabber} onMouseDown={onPress}>
      <div className={styles.topPart} style={{
          top: `${sizes.heights[0] + gutter}px`, 
          width: `${sizes.widths[0] - (color.borderWeight * 2)}px`, 
          height: `${topHeight}px`
      }}>
        <p style={{
          color: `rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`
        }}>available for freelance</p>
        <svg width={30} height={topHeight - 50} viewBox={`0 0 30 ${topHeight - 50}`} className={styles.svg}>

          <path strokeWidth={1.5} stroke={`rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`} strokeLinecap="round" fill="none"
            transform-origin="15 15"
            d={`
            M 15 ${topHeight - 70}
            V 20
            `}
          /> 

          <path strokeWidth={1.5} stroke={`rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`} strokeLinecap="round" fill="none"
            d={`
              M 6 30
              L 15 20
              L 24 30
            `}
          />

        </svg>
      </div>
      <div className={styles.arrowWrapper} style={{
          width: `${sizes.widths[0] - (color.borderWeight * 2)}px`, 
          height: `${sizes.heights[0] - (color.borderWeight * 2)}px`,
          color: `rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`
      }}>
        <ArrowIcon color={color} direction={'down'} />
      </div>
    </div>
  );
}

export default Grabber;
