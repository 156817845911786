import styles from  './arrowIcon.module.scss';

function ArrowIcon({ color, direction }) {
  let rotation = direction === 'left' ? 180 : direction === 'up' ? -90 : direction === 'down' ? 90 : 0

  return (
    <svg width={30} height={30} viewBox={`0 0 30 30`} className={styles.svg} style={{ 
      transform: `rotate(${rotation}deg)`
      }}>

      <path strokeWidth={1.5} stroke={`rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`} strokeLinecap="round" fill="none"
        transform-origin="15 15"
        d={`
        M 5 15
        H 25
        `}
      /> 

      <path strokeWidth={1.5} stroke={`rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`} strokeLinecap="round" fill="none"
        d={`
          M 15 6
          L 25 15
          L 15 24
        `}
      />

    </svg>
  );
}

export default ArrowIcon;
