export const MOBILE_THRESHOLD = 880
export const MIDDLE_THRESHOLD = 1080
export const RESIZE_TO = 200

export const SOCIALS = [
  {
    label: 'email',
    link: 'mailto:samfairbairn@hotmail.co.uk'
  },
  {
    label: 'linkedin',
    link: 'https://www.linkedin.com/in/samfairbairn/'
  },
  {
    label: 'twitter',
    link: 'https://twitter.com/samfairb'
  },
  {
    label: 'instagram',
    link: 'https://www.instagram.com/samuel_fairb/'
  },
  {
    label: 'fxhash',
    link: 'https://www.fxhash.xyz/u/SamFairbairn/creations'
  }
]

export const WORK_EXPERIENCE = [
  {
    label: 'frog design',
    link: 'https://www.frog.co/'
  },
  {
    label: 'akqa',
    link: 'https://www.akqa.com/'
  },
  {
    label: 'stink studios',
    link: 'https://www.stinkstudios.com/'
  },
  {
    label: 'media.monks',
    link: 'https://media.monks.com/'
  }
]

// export const SKILLS = ['Vue.js', 'React', 'CSS', 'Web3', 'SASS', 'Vuex', 'MiniProgram', 'Redux', 'Three.js', 'WeChat SDK', 'Node.js', 'Canvas', 'NUXT', 'Solidity', 'Webpack', 'Material UI', 'Ethers.js', 'Contentful', 'Infura', 'Stripe', 'ANT Design', 'Figma', 'Photoshop', 'Illustrator', 'Design Tokens', 'StyleGAN', 'P5.js', 'Design Thinking']
export const SKILLS = ['Vue.js', 'React', 'NEXT/NUXT', 'SASS', 'Redux/Vuex', 'Web3', 'Three.js', 'WeChat SDK', 'Node.js', 'Canvas', 'Solidity', 'Webpack', 'Material UI', 'Contentful', 'Stripe', 'ANT Design', 'Figma', 'Photoshop', 'Illustrator', 'Design Tokens']
// export const SKILLS = ['Vue.js', 'React', 'CSS', 'Web3', 'SASS', 'Vuex', 'MiniProgram', 'Redux', 'Three.js']
export const CLIENTS = ['Nike', 'Apple', 'Google', 'LVMH', 'NBA', 'Strava']

export const POS_STORAGE = 'saaaam_progress'
export const COLOUR_STORAGE = 'saaaam_colours'
export const RADIUS_STORAGE = 'saaaam_radius'
export const SLIDER_ONBOARDING_STORAGE = 'saaaam_onboarding_1'
export const SHUFFLE_ONBOARDING_STORAGE = 'saaaam_onboarding_2'

export const SVG_WIDTH = 1920 * .8;
export const SVG_HEIGHT = 1200 * .8;

// export const SVG_WIDTH = 1200 * 2;
// export const SVG_HEIGHT = 630 * 2;
// export const SVG_WIDTH = 600 * 3;
// export const SVG_HEIGHT = 400 * 3;
// export const SVG_WIDTH = 1400 * 3;
// export const SVG_HEIGHT = 350 * 3;

export const ABOUT_CONTENT = [
  {
    title: 'work experience',
    list: WORK_EXPERIENCE
  },
  {
    title: 'favourite tools',
    list: SKILLS
  },
  {
    title: 'socials',
    list: SOCIALS
  },
  {
    title: 'clients',
    list: CLIENTS
  }
]

export const COPY = {
  'minterDsc': 'for no reason in particular mint a sam for free on polygon. choose your style with the slider and shuffle button, connect your wallet and mint! <br/><br/>great if your name is sam'
}