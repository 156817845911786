import { useContext, useMemo, useRef } from "react";
import ExpandButton from '../expandButton'
import BendyText from '../bendyText';
import ArrowIcon from '../arrowIcon';
import { AppContext } from "../../context/appContext";
import { ABOUT_CONTENT } from "../../utils/constants";
import ScrollContent from "../scrollContent";
import styles from  './aboutMeContent.module.scss';

function AboutMeContent({ color, width = 2, mobile }) {

  const { gutter, screenSize, onInfoHover, onInfoClick, infoExpanded, bottomScale, btmSlideVal, sizes, showMobile, colors } = useContext(AppContext);

  const onHover = () => {
    if (!btmSlideVal && !showMobile) onInfoHover();
  }

  const onClick = () => {
    if (infoExpanded > 0) {
      onInfoClick(true)
    } else {
      onInfoClick(false)
    }
  }

  const experienceList = useRef([{label: ABOUT_CONTENT[0].title, title: true}, ...ABOUT_CONTENT[0].list])
  const toolsList = useRef([{label: ABOUT_CONTENT[1].title, title: true}, ...ABOUT_CONTENT[1].list.map(l => ({label: l}))])
  const linksList = useRef([{label: ABOUT_CONTENT[2].title, title: true}, ...ABOUT_CONTENT[2].list])
  const clientsList = useRef([{label: ABOUT_CONTENT[3].title, title: true}, ...ABOUT_CONTENT[3].list.map(l => ({label: l}))])

  const colorIndexes = useRef([
    [2, 3], 
    [4, 6],
    [7, 8],
    [9, 10]
  ])

  const lineWidth = useMemo(() => {
    return ((sizes.widths[width] - (color.borderWeight * 2)) - 40) - 30
  }, [color.borderWeight, sizes.widths, width])

  const innerContentSize = useMemo(() => {
    return {
      width: sizes.widths[mobile ? 4: 2] - (color.borderWeight * 2),
      height: sizes.heights[2] - (color.borderWeight * 2)
    }
  }, [color.borderWeight, sizes.widths, sizes.heights, mobile])

  const closedSize = useMemo(() => {
    return {
    width: sizes.widths[0] - (color.borderWeight * 2),
    height: sizes.heights[0] - (color.borderWeight * 2)
    }
  }, [sizes.widths, sizes.heights, color.borderWeight])

  return (
    <div className={styles.wrapper} onMouseOver={onHover}>

      {!infoExpanded && 
        <div className={styles.closedContent} style={{
          width: `${closedSize.width}px`, 
          height: `${closedSize.height}px`,
          color: `rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`,
          opacity: bottomScale
        }}>
          <ArrowIcon color={color} direction='right' />
        </div>
      }

      <div className={styles.wrapper} style={{
        width: `${innerContentSize.width}px`,
        height: `${innerContentSize.height}px`,
      }}>
        <ScrollContent color={color} width={mobile ? 4: 2} height={2} active={mobile ? 1 : infoExpanded}>
          <div className={styles.content}>
            { mobile ? 
              <span className={styles.title}>what is sam</span>
            :
              <div style={{ alignSelf: 'flex-start' }}>
                <ExpandButton title="what is sam" disabled={infoExpanded === 1} width={mobile ? sizes.widths[4] : (sizes.widths[1] + ((sizes.widths[0] + gutter) * infoExpanded))} height={sizes.heights[0]} opacity={infoExpanded ? 1 : 1 - bottomScale} expanded={infoExpanded} color={color} direction={'up'} hideArrow={'true'} onClickCB={onClick} />
              </div>
            }

            { (infoExpanded > 0 || showMobile) && (<>

              <p>A creative technologist and frontend engineer from the UK, currently based in Shanghai. </p>
              <p>I have over 10 years experience working in marketing and consultancy and am passionate about exploring and experimenting with unconventional user experiences, whether that's while prototyping with emerging technology, creative campaign work, building fun stuff with friends, or working on products designed to stand the test of time. </p>
              <p>If you'd like to know more about my experience, portfolio or availability, please don't hesitate to get in touch, you can find my links below ☟.</p>

              <div className={styles.linksWrapper}>

                <BendyText 
                  width={lineWidth} 
                  links={experienceList.current}
                  colorsIndexes={colorIndexes.current[0]}
                  colors={colors}
                  screenSize={screenSize}
                />

                <BendyText 
                  width={lineWidth} 
                  links={clientsList.current}
                  colorsIndexes={colorIndexes.current[3]}
                  colors={colors}
                  screenSize={screenSize}
                />

                <BendyText 
                  width={lineWidth} 
                  links={toolsList.current}
                  colorsIndexes={colorIndexes.current[1]}
                  colors={colors}
                  screenSize={screenSize}
                />

                <BendyText 
                  width={lineWidth} 
                  links={linksList.current}
                  colorsIndexes={colorIndexes.current[2]}
                  colors={colors}
                  screenSize={screenSize}
                />

              </div>
              </>)}
          </div>
        </ScrollContent>
        
        {!mobile && 
          <div onClick={onClick} 
            style={{ top: `${(sizes.heights[0] - (color.borderWeight * 2) - 40) / 2}px` }}
            className={styles.closeButton}>
          </div>
        }
      </div>

    </div>
  );
}

export default AboutMeContent;
