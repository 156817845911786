import { useEffect, useContext, useRef, useState, useCallback, useMemo } from "react";
import SVGNFT from "../SVGNFT";
import Minter from '../minter';
import { AppContext } from "../../context/appContext";
import ReactGA from "react-ga4";
import styles from  './minterContent.module.scss';
import classNames from "classnames";
import ShuffleIcon from '../arrowIcon/shuffle';
import SmallLoader from "../smallLoader";

function Thumb({ color, size, fluid, visibility }) {
  const { gutter, borderRadius, colors, sizes } = useContext(AppContext);

  const [showSVG, setShowSVG] = useState(false)
  const thumb = useRef()
  const resetTimer = useRef()

  const refreshImg = useCallback(() => {
    clearTimeout(resetTimer.current)
    resetTimer.current = null
    
    setShowSVG(false)
    resetTimer.current = setTimeout(() => {
      clearTimeout(resetTimer.current)
      resetTimer.current = null
      setShowSVG(true)

      ReactGA.event({
        category: "minter",
        action: "click",
        label: 'refresh style'
      });
    }, 500)
  }, [])

  useEffect(() => {
    if (visibility) refreshImg()
    return () => clearTimeout(resetTimer.current)
  }, [borderRadius, gutter, colors, refreshImg, visibility])

  const [svgString, setSVGString] = useState()

  useEffect(() => {
    if (visibility === 1 && showSVG) setSVGString(thumb.current.innerHTML)
  }, [visibility, showSVG])

  const width = useMemo(() => {
    if (fluid) {
      // return `${(sizes.widths[4] - (color.borderWeight * 2) - 40) * 0.6}px`
      return '100%'
    } else {
      return `${sizes.widths[1] - (color.borderWeight * 2)}px`
    }
  }, [sizes.widths, color.borderWeight, fluid])

  const height = useMemo(() => {
    if (fluid) {
      // return (1200/1920) * width
      return 'auto'
      // return '100%'
    } else {
      return `${sizes.heights[1] + gutter}px`
    }
  }, [sizes.heights, gutter, fluid])

  return (

    <div className={styles.bottom}
      style={{ 
        width: width,
        height: height,
        top: fluid ? 'auto' : `${sizes.heights[1] - (color.borderWeight * 2)}px`,
        opacity: fluid ? 1 : visibility
      }}>

      <div 
        className={styles.thumb} style={{
          height: fluid ? 'auto' : 'calc(100% - 190px)', 
          maxWidth: fluid ? '90%' : '90%',
          // width: width,
          // height: height,
          display: 'block'
        }}>
        {showSVG && visibility === 1 ?
          <div className={styles.wrap} style={{maxWidth: width}}>
            <span className={styles.inlineSVG} ref={thumb}>
              <SVGNFT />
            </span>

            <div className={styles.shuffleWrapper}>
              <span onClick={refreshImg} className={classNames([styles.button, visibility === 1 && styles.active])} style={{
                backgroundColor: `rgba(${colors[2].bgColor[0]}, ${colors[2].bgColor[1]}, ${colors[2].bgColor[2]}, ${colors[2].bgOpacity})`,
                border: `4px solid rgb(${colors[2].borderColor[0]}, ${colors[2].borderColor[1]}, ${colors[2].borderColor[2]})`
              }}>
                <ShuffleIcon color={colors[2]} />
              </span>
            </div>
          </div>
          :
          <div className={styles.loading}>
            {/* <div className={styles.wrap}>
              <SVGBorder />
            </div> */}
            <SmallLoader color={`rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`} visible={true} />
          </div>
        }
      </div>

      {/* <Button copy="download" href={`data:image/svg+xml;utf8,${svgString}`.replace(/"/g, '\'')} color={`rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`} /> */}
      <Minter svgString={showSVG && svgString ? svgString : null} color={`rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`} colors={colors} />

    </div>
  );
}

export default Thumb;
