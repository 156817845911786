import { useContext, useMemo } from "react";
import { AppContext } from "../../context/appContext";
import CentralSlider from '../centralSlider';
import Container from '../container'
import MiddleContent from '../middleContent'
import FanContainer from '../fanContainer'
import BottomSlider from '../bottomSlider'
import MShape from '../mShape'
import SShape from '../sShape'
import ShuffleButton from '../shuffleButton'
import ExpandableWrapperMid from '../expandableWrapperMid'
import Carousel from '../carousel'
import AboutMeContent from '../aboutMeContent'
import MinterContent from '../minterContent'
import ExtraContent from '../extraContent'
import VerticalSlider1 from '../verticalSlider1'
import Matter from '../matter'
import FillerShape from '../fillerShape'
import Grabber from '../grabber'
// import RandomPoly from '../randomPoly'
import styles from  './grid.module.scss';
import RtlSlider from "../rtlSlider";
import classNames from "classnames";
import RefreshIcon from '../arrowIcon/refresh'


function GridDesktop() {

  const { gutter, colors, screenSize, shuffleStyle, resetStyle, shuffled, extraBitScale, bottomScale, infoExpanded, rightScale, rightExpanded, btmSlideVal, rtlSlideVal, verticalSlide1Val, physicsActive, setPhysicsActive, pullDownVal, explosion } = useContext(AppContext);  

  const canReset = useMemo(() => {
    return shuffled || infoExpanded === 1 || rightExpanded === 1 || btmSlideVal === 1 || rtlSlideVal === 0 || verticalSlide1Val === 1
  }, [shuffled, infoExpanded, rightExpanded, btmSlideVal, rtlSlideVal, verticalSlide1Val])

  let realRTLVal = useMemo(() => 1 - rtlSlideVal, [rtlSlideVal])
  let realVertVal = useMemo(() => verticalSlide1Val * 2, [verticalSlide1Val])

  return (
    <div className={styles.grid} style={{ padding: `${gutter}px`, backgroundColor: `rgb(${colors[0].bgColor[0]}, ${colors[0].bgColor[1]}, ${colors[0].bgColor[2]})` }}>

      <div className={styles.gridLeft}>

        {/* <Container width={1} height={1 + (rightScale * 2)} left={3} top={0} color={4} styles={{ backgroundColor: 'transparent' }}> */}
        <Container width={1} height={1 + (rightScale * 2)} left={5 - extraBitScale} top={0} color={4} styles={{ backgroundColor: 'transparent' }}>
          {extraBitScale > 0 && <FillerShape active={extraBitScale === 1}/>}
        </Container>

        <Container width={1} height={4} left={-btmSlideVal - rightScale - extraBitScale} top={-4 + Math.max(realVertVal - 1, 0) + (pullDownVal * 2)} color={9} background border container>
          <Grabber active={verticalSlide1Val === 1} />
        </Container>

        <Container width={1 + infoExpanded} height={3 - (infoExpanded * 2) + Math.min(realVertVal, 1) - Math.max(realVertVal - 1, 0) - (pullDownVal * 2)} left={-btmSlideVal - rightScale - extraBitScale} top={Math.max(realVertVal - 1, 0) + (pullDownVal * 2)} color={2} container>
          <SShape />
        </Container>

        <Container width={2} height={2} left={1 - btmSlideVal - rightScale + infoExpanded - extraBitScale} top={1 - (infoExpanded * 2) + realVertVal} color={3} container styles={{ backgroundColor: 'transparent' }}>
          <CentralSlider />
        </Container>

        <FanContainer width={2} height={1} left={1 - btmSlideVal - rightScale + infoExpanded - extraBitScale} top={-(infoExpanded * 2) + realVertVal} color={4} background border container onTop={true} />

        {/** bottom left */}
        <Container 
          width={2 - (infoExpanded ? 0 : bottomScale) + infoExpanded}
          height={1 + (infoExpanded * 2)} 
          left={0 - btmSlideVal - rightScale - (infoExpanded ? bottomScale : 0) - extraBitScale} 
          top={3 - (infoExpanded * 2) + realVertVal} 
          color={5} background border container hidden>
          <AboutMeContent />
        </Container>

        <Container 
          width={1 + bottomScale} 
          height={1} 
          left={2 - bottomScale - btmSlideVal - rightScale + infoExpanded - extraBitScale} 
          top={3 - (infoExpanded * 2) + realVertVal} color={9} 
          background border container hidden>
          <ExpandableWrapperMid>
            <BottomSlider />
          </ExpandableWrapperMid>
        </Container>

        <Container 
          width={1 + bottomScale} 
          height={2} 
          left={2 - rightScale - bottomScale - btmSlideVal + infoExpanded - extraBitScale} 
          top={4 - (infoExpanded * 2) + realVertVal} 
          color={2} background border container hidden
          style={{backgroundColor: `rgb(${colors[2].borderColor[0]}, ${colors[2].borderColor[1]}, ${colors[2].borderColor[2]})`}}>
          {infoExpanded > 0 && <Carousel />}
        </Container>

      </div>

      <div className={styles.middle}>
        { btmSlideVal > 0 && 
          <MiddleContent width={btmSlideVal * 2} height={4} left={3 - btmSlideVal - rightScale + infoExpanded} top={0} color={10} container />
        }
      </div>

      <div className={styles.right}>

        <Container width={2 + rightScale + extraBitScale - Math.min(btmSlideVal + realRTLVal, 1)} height={2} left={3 + btmSlideVal - rightScale + infoExpanded - extraBitScale} top={2 + (rightExpanded * 2)} color={6} container>
          <MShape />
        </Container>

        <Container 
          width={1 + rightScale} 
          height={2 + (rightExpanded * 2)} 
          left={3 + btmSlideVal - rightScale + infoExpanded - extraBitScale} 
          top={0} color={7} background border container hidden>
          <MinterContent />
        </Container>

        <Container width={1} height={1} left={4 + btmSlideVal + infoExpanded - extraBitScale} top={-1 + rightExpanded} color={6} background border container hidden>
        </Container>

        {/* <RandomPoly color={8}/> */}
        <Container width={1} height={1 + rightExpanded} left={4 + btmSlideVal + infoExpanded - extraBitScale} top={rightExpanded} color={8} background border container hidden>
          <ShuffleButton label="shuffle" onClickCB={shuffleStyle} />
        </Container>
        
        <Container width={1 + extraBitScale} height={1} left={4 + btmSlideVal + infoExpanded - extraBitScale} top={1 + (rightExpanded * 2)} color={10} background border container hidden>
          {/* <ShuffleButton label="reset" onClickCB={_resetStyle} disabled={!shuffled && rightExpanded === 0 && btmSlideVal === 0 && infoExpanded === 0} /> */}
          <ExtraContent>
            <RtlSlider />
          </ExtraContent>
        </Container>

        <Container width={1} height={2} left={5 - realRTLVal + btmSlideVal + infoExpanded} top={2 + (rightExpanded * 2)} color={5} background border container hidden>
          {/* <ShuffleButton label="reset" onClickCB={_resetStyle} disabled={!shuffled && rightExpanded === 0 && btmSlideVal === 0 && infoExpanded === 0} /> */}
          <VerticalSlider1 expanded={1} />
        </Container>

        {/* <Container width={2} height={4} left={1 - rightScale - extraBitScale} top={-4 + realVertVal} container> */}
          {/* <ShuffleButton label="reset" onClickCB={_resetStyle} disabled={!shuffled && rightExpanded === 0 && btmSlideVal === 0 && infoExpanded === 0} /> */}
          {/* <VerticalSlider1 expanded={1} /> */}
          {/* {realVertVal > 0 && <Matter />}
        </Container> */}

        <Container width={2} height={1} left={1 - (rightScale + extraBitScale)} top={-1 + (verticalSlide1Val * 2)} color={8} container hidden noEvents></Container>
        <Container width={1} height={1} left={1 - (rightScale + extraBitScale)} top={-2 + (verticalSlide1Val * 2)} color={7} container hidden noEvents></Container>
        <Container width={1} height={2} left={2 - (rightScale + extraBitScale)} top={-3 + (verticalSlide1Val * 2)} color={6} container hidden noEvents></Container>

        <Container width={2} height={2} left={1 - (rightScale + extraBitScale)} top={-2 + (verticalSlide1Val * 2)} container hidden>
          <div style={{position: 'absolute', top: 0, let: 0, width: '100%', height: '100%'}} onMouseOver={() => { setPhysicsActive(true) }}></div>
        </Container>

        {(physicsActive || verticalSlide1Val > 0 || explosion) && (
          <div style={{
            position: 'absolute',
            left: `${(-screenSize.width / 5) * (rightScale + extraBitScale)}px`,
            top: `0px`,
            width: `${screenSize.width}px`,
            height: `${screenSize.height}px`,
            zIndex: 99999,
            pointerEvents: 'none'
            }}>
            <Matter />
          </div>
        )}

        <span onClick={resetStyle} className={classNames([styles.resetButton, canReset && styles.active])} style={{
          backgroundColor: `rgba(${colors[2].bgColor[0]}, ${colors[2].bgColor[1]}, ${colors[2].bgColor[2]}, ${colors[2].bgOpacity})`,
          border: `6px solid rgb(${colors[2].borderColor[0]}, ${colors[2].borderColor[1]}, ${colors[2].borderColor[2]})`,
          top: `-${gutter/4}px`,
          right: `-${gutter/4}px`
        }}>
          <RefreshIcon color={colors[2]} />
        </span>

      </div>
    </div>
  );
}

export default GridDesktop;
