// import { useContext } from "react";
// import { AppContext } from "../../context/appContext";
import { gsap } from "gsap/all";
import { SVG_WIDTH, SVG_HEIGHT } from '../../utils/constants'

function Stripes({ width, height, top=0, left=0, id, rotation = 0, color, factor = 1, dash, showBG }) {
  // const { colors } = useContext(AppContext);
  
  let colWidth = SVG_WIDTH / 5
  let colHeight = SVG_HEIGHT / 4

  width = colWidth * width
  height = colWidth * height

  let size = height > width ? height : width
  size =  Math.round((Math.sqrt(Math.pow(size, 2) + Math.pow(size, 2))) * 100) / 100

  let expandedLeft = Math.round(((colWidth * left) - ((size - width)/2)) * 100) / 100
  let expandedTop = Math.round(((colHeight * top) - ((size - height)/2)) * 100) / 100

  const strokeWidth = Math.round((gsap.utils.clamp(2, 12, color.borderWeight)) * 100) / 100
  const lineHeight = Math.round((strokeWidth * factor) * 100) / 100
  const rows = Math.ceil((size / lineHeight) / 2) + 1
  const colorChoice = showBG ? 'borderColor' : 'bgColor'

  return (
    <g clipPath={`url(#${id})`}>
      { [...Array(rows)].map((a, i) =>   
        <path d={`M ${expandedLeft} ${expandedTop + (i * lineHeight * 2)} H ${expandedLeft + size}`} 
          key={`stripe-${id}-${i}`}
          stroke={color[colorChoice]} 
          fill="none" strokeWidth={strokeWidth} strokeLinecap="round"
          transform={`rotate(${rotation} ${expandedLeft + (size/2)} ${expandedTop + (size/2)})`} 
          style={{
            strokeDasharray: dash ? `${dash}px ${strokeWidth*2 * factor}px` : 'none',
            strokeDashoffset: dash ? `${i%2 === 0 ? 0 : -dash/2}px` : 'none'
          }}
        />
      )}
    </g>
  );
}

export default Stripes ;
