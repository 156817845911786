import classNames from "classnames";
import { useState } from "react";
import styles from  './button.module.scss';
import SmallLoader from '../smallLoader'

function Button({ copy = '', onClickCB, small = false, color, href, disabled }) {

  const [loading, setLoading] = useState(false)

  const onClick = async () => {
    if (onClickCB && !loading) {
      setLoading(true)
      await onClickCB()
      setLoading(false)
    }
  }

  if (href) {
    return (
      <a href={href} download="saaa.am sam.svg" className={classNames([styles.button, small && styles.small, disabled && styles.disabled])} onClick={onClick} style={{
        borderColor: color ? color : `blue`,
        color: color ? color : `blue`,
      }}>
        {copy}
      </a>
    )
  } else {
    return (
      <div className={classNames([styles.button, small && styles.small, loading && styles.loading, disabled && styles.disabled])} onClick={onClick} style={{
        borderColor: color ? color : `blue`,
        color: color ? color : `blue`,
      }}>
        <div className={styles.copy}>{copy}</div>
        <SmallLoader color={color} visible={loading} />
      </div>
    );
  }
}

export default Button;
