import { useContext, cloneElement } from "react";
import { AppContext } from "../../context/appContext";
import ArrowIcon from '../arrowIcon'
import styles from  './extraContent.module.scss';

function ExtraContent({ children, color, size }) {

  const { extraBitScale, onExtraHover, sizes } = useContext(AppContext);

  const onHover = () => {
    onExtraHover(true)
  }

  const onOut = () => {
    onExtraHover(false)
  }

  return (
    <div className={styles.wrapper} onMouseOver={onHover} onMouseLeave={onOut}>
    {/* // <div className={styles.wrapper} onClick={onClick}> */}
      <div className={styles.closedContent} style={{
          width: `${size.width - (color.borderWeight * 2)}px`, 
          height: `${size.height - (color.borderWeight * 2)}px`,
          color: `rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`,
          opacity: 1 - extraBitScale,
          pointerEvents: extraBitScale === 1 ? 'none' : 'auto'
      }}>
        <ArrowIcon color={color} direction={'left'} />
      </div>
      <div className={styles.innerContent} style={{
        width: `${size.width - (color.borderWeight * 2)}px`, 
        height: `${size.height - (color.borderWeight * 2)}px`,
        opacity: extraBitScale,
        pointerEvents: extraBitScale === 1 ? 'auto' : 'none'
      }}>
        {children && cloneElement(children, {color, size, sizes, height: size.height})}
      </div>
    </div>
  );
}

export default ExtraContent;
