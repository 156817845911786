import { useContext, cloneElement } from "react";
import { AppContext } from "../../context/appContext";
// import BottomSlider from '../bottomSlider'
import ArrowIcon from '../arrowIcon'
import styles from  './expandableWrapperMid.module.scss';

function ExpandableWrapperMid({ children, color, size }) {

  const { onInfoHover, bottomScale, sizes, showMobile } = useContext(AppContext);

  const onHover = () => {
    if (!showMobile) onInfoHover(true)
  }

  return (
    <div className={styles.wrapper} onMouseOver={onHover}>
      <div className={styles.closedContent} style={{
          width: `${size.width - (color.borderWeight * 2)}px`, 
          height: `${size.height - (color.borderWeight * 2)}px`,
          color: `rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`,
          opacity: 1 - bottomScale,
          pointerEvents: bottomScale === 1 ? 'none' : 'auto'
      }}>
        <ArrowIcon color={color} direction={'left'} />
      </div>
      <div className={styles.innerContent} style={{
        opacity: bottomScale,
        pointerEvents: bottomScale === 1 ? 'auto' : 'none'
      }}>
        {children && cloneElement(children, {color, size, sizes})}
      </div>
    </div>
  );
}

export default ExpandableWrapperMid;
