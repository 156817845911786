import { React, useEffect, useRef, memo, forwardRef, useImperativeHandle } from "react";
import styles from  './centralSlider.module.scss';

const SliderCanvas = memo(forwardRef(({ box, lineThickness, borderRadius }, ref) => {

  const pathCanvas = useRef()

  useImperativeHandle(ref, () => ({
    getClosestPoint (color, parentBox, slideVal, x, y) {
      if (!pathCanvas.current) return 

      const _startPos = parentBox.width - lineThickness * 1.5 - (color.borderWeight * 2)

      const lineParts = [
        _startPos - (lineThickness/2),
        parentBox.height - lineThickness,
        parentBox.width - lineThickness,
        parentBox.height - lineThickness
      ]

      const totalLength = lineParts[0] + lineParts[1] + lineParts[2] + lineParts[3]
      const linePartsRelative = lineParts.map(p => p/totalLength)
      const posRelative = {x: x / parentBox.width, y: y / parentBox.height}

      var ctx = pathCanvas.current.getContext("2d");
      let pixelColor = ctx.getImageData(posRelative.x * box.width, posRelative.y * box.height, 1, 1).data;

      let progress = slideVal

      if (pixelColor[0] > 200) {
        progress = clamp((1 - ((x - (lineThickness/2)) / lineParts[0])) * linePartsRelative[0], 0, linePartsRelative[0])
      } else if (pixelColor[1] > 200 && pixelColor[2] > 200) {
        progress = clamp(((y - (lineThickness/2)) / lineParts[3]) * linePartsRelative[3], 0, linePartsRelative[3]) + linePartsRelative[0] + linePartsRelative[1] + linePartsRelative[2]
      } else if (pixelColor[1] > 200) {
        progress = clamp((1 - ((y - (lineThickness/2)) / lineParts[1])) * linePartsRelative[1], 0, linePartsRelative[1]) + linePartsRelative[0]
      } else if (pixelColor[2] > 200) {
        progress = clamp(((x - (lineThickness/2)) / lineParts[2]) * linePartsRelative[2], 0, linePartsRelative[2]) + linePartsRelative[0] + linePartsRelative[1]
      }

      return progress
    }

  }));

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  useEffect(() => {
    if (!box || !pathCanvas.current) return

    const canvas = pathCanvas.current
    const ctx = canvas.getContext("2d");

    let width = canvas.width
    let height = canvas.height

    ctx.clearRect(0, 0, width, height);

    ctx.fillStyle = "#FF0000";
    ctx.fillRect(0, height - lineThickness - (borderRadius/2), width, height);

    ctx.fillStyle = "#0000FF";
    ctx.fillRect(0, 0, width, lineThickness + (borderRadius/2));
    
    ctx.fillStyle = "#00FF00";
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(lineThickness + (borderRadius/2), lineThickness + (borderRadius/2));
    ctx.lineTo(lineThickness + (borderRadius/2), height - lineThickness - (borderRadius/2));
    ctx.lineTo(0, height);
    ctx.fill();

    ctx.fillStyle = "#00FFFF";
    ctx.beginPath();

    ctx.moveTo(width - lineThickness - (borderRadius/2), lineThickness + (borderRadius/2));
    ctx.lineTo(width - lineThickness - (borderRadius/2), height/2);
    ctx.lineTo(width - lineThickness, height/2);
    ctx.lineTo(width - lineThickness, height);
    ctx.lineTo(width, height);
    ctx.lineTo(width, 0);
    ctx.fill();

  }, [box, lineThickness, borderRadius])

  if (!box) return null

  return (
    <canvas ref={pathCanvas} className={styles.canvas} width={box.width} height={box.height} />
  )
}))

export default SliderCanvas;
