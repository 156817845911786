import { useContext, useEffect, useState, cloneElement, useMemo } from "react";
import classNames from "classnames";
import { AppContext } from "../../context/appContext";
import styles from  './container.module.scss';

function Container({ children, width, height, top, left, color, background, border, container, hidden, onTop, fillBorder, style = {}, noEvents }) {

  const { screenSize, gutter, borderRadius, colors, sizes } = useContext(AppContext);

  const [computedHeight, setComputedHeight] = useState((screenSize.height / 4) * height)
  const [computedWidth, setComputedWidth] = useState((screenSize.width / 5) * width)

  const updateSize = () => {
    let colWidth = screenSize.width / 5
    let colHeight = screenSize.height / 4

    setComputedHeight(colHeight * height)
    setComputedWidth(colWidth * width)
  }

  useEffect(updateSize, [height, width, screenSize])

  const [leftPos, setLeftPos] = useState((screenSize.width / 5) * left)
  const [topPos, setTopPos] = useState((screenSize.height / 4) * top)

  const updatePos = () => {
    let colWidth = screenSize.width / 5
    let colHeight = screenSize.height / 4
    setLeftPos(colWidth * left)
    setTopPos(colHeight * top)
  }

  useEffect(updatePos, [top, left, screenSize])
  
  const size = useMemo(() => {
    return {
      width: computedWidth - gutter, 
      height: computedHeight - gutter, 
      left: leftPos + (gutter / 2), 
      top: topPos + (gutter / 2)
    }
  }, [computedWidth, computedHeight, leftPos, topPos, gutter])

  const _color = useMemo(() => {
    return colors[color]
  }, [colors, color])

  const bgColor = useMemo(() => {
    if (fillBorder) {
      return `rgba(${_color.borderColor[0]}, ${_color.borderColor[1]}, ${_color.borderColor[2]}, ${_color.borderOpacity})`
    } else if (_color && background) {
      return `rgba(${_color.bgColor[0]}, ${_color.bgColor[1]}, ${_color.bgColor[2]}, ${_color.bgOpacity})`
    } else {
      return 'transparent'
    }
  }, [_color, background, fillBorder])

  return (
    <div 
      className={styles.container}
      data-color-id={color}
      style={{ 
        width: `${computedWidth}px`, 
        height: `${computedHeight}px`,
        left: `${leftPos}px`,
        top: `${topPos}px`,
        backgroundColor: _color && container ? `rgba(${_color.containerColor[0]}, ${_color.containerColor[1]}, ${_color.containerColor[2]}, ${_color.containerOpacity})` : 'transparent',
        zIndex: onTop ? 9999 : 'inherit',
        pointerEvents: noEvents ? 'none' : 'auto'
      }}
    >
      <div 
        className={classNames([styles.content, onTop && styles.onTop])}
        style={{ 
          width: `${computedWidth - gutter}px`, 
          height: `${computedHeight - gutter}px`,
          left: `${gutter / 2}px`,
          top: `${gutter / 2}px`,
          borderRadius: `${borderRadius}px`,
          backgroundColor: bgColor,
          border: color && border ? `${_color.borderWeight}px solid rgba(${_color.borderColor[0]}, ${_color.borderColor[1]}, ${_color.borderColor[2]}, ${_color.borderOpacity})` : 'transparent',
          overflow: hidden ? 'hidden': 'visible',
          ...style
        }}
      >
        {children && cloneElement(children, { size: size, sizes, color: _color})}
      </div>
    </div>
  );
}

export default Container;
