import { useContext, cloneElement } from "react";
import { AppContext } from "../../context/appContext";
// import BottomSlider from '../bottomSlider'
import ArrowIcon from '../arrowIcon'
import styles from  './clickVerticalExpand.module.scss';

function ClickVerticalExpand({ children, color, size }) {

  const { onInfoHover, bottomScale, sizes, verticalSlide1Val } = useContext(AppContext);

  const onClick = () => {
    onInfoHover(true)
  }

  return (
    <div className={styles.wrapper} onClick={onClick}>
      <div className={styles.closedContent} style={{
          width: `${size.width - (color.borderWeight * 2)}px`, 
          height: `${size.height - (color.borderWeight * 2)}px`,
          color: `rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`,
          opacity: 1 - bottomScale
      }}>
        <ArrowIcon color={color} direction={verticalSlide1Val < 0.5 ? 'down' : 'up'} />
      </div>
      {children && cloneElement(children, {color, size, sizes})}
    </div>
  );
}

export default ClickVerticalExpand;
