import { useEffect, useRef, useState, useCallback, memo } from "react";
import classNames from "classnames";
import styles from  './carouselOverlay.module.scss';

const CarouselOverlay = memo(({ show, size, color }) => {

  const path = useRef()
  const title = useRef()
  
  const textLength = useRef(0)
  const totalLength = useRef(0)
  const offset = useRef(0)

  const [off, setOff] = useState(0)
  const [opacity, setOpacity] = useState(1 - show)

  const height = useRef(size.height * 2)

  const curves = useRef(8)
  const gap = useRef(height.current / curves.current)

  const init = useCallback(() => {
    textLength.current = title.current.getBoundingClientRect().width
    totalLength.current = path.current.getTotalLength();
    offset.current = (totalLength.current - textLength.current) * 0.5

    setOff(offset.current)
    setOpacity(1 - show)
  }, [show])

  useEffect(() => {
    init();
  }, [size, init])

  useEffect(() => {
    setOpacity(1 - show)
  }, [show])

  const drawPath = (stroke = 0) => {
    let _path = `M ${(size.width * 0.8) + gap.current/2} ${-(height.current/4)}`
    for (let i = 0; i < curves.current; i++) {
      if (i%2 === 0) {
        _path += ` H ${(size.width * 0.2) + gap.current/2}`
        _path += ` A ${gap.current/2} ${gap.current/2} 1 0 0 ${(size.width * 0.2) + gap.current/2} ${(gap.current * (i + 1)) - (height.current/4)}`
      } else {
        _path += ` H ${(size.width * 0.8) - gap.current/2}`
        _path += ` A ${gap.current/2} ${gap.current/2} 0 0 1 ${(size.width * 0.8) - gap.current/2} ${(gap.current * (i + 1)) - (height.current/4)}`
      }
    }
    _path += ` H ${(size.width * 0.2) - gap.current/2}`

    return (
      <path id={`path-carousel-expand`} className={styles.path} ref={path} d={_path} 
      fill="none" strokeWidth={stroke} opacity={opacity}
      stroke={`rgb(${color.bgColor[0]}, ${color.bgColor[1]}, ${color.bgColor[2]})`}
      strokeLinecap="round"
      style={{
        // strokeDasharray: `${textLength.current + 300}px ${totalLength.current - 300}px`,
        // strokeDashoffset: `${(textLength.current - off - textLength.current) + 150}px`,
        transform: `translateX(-${color.borderWeight}px)`
      }}
      />
    )
  }

  return (
    <div className={styles.pathWrapper} >

      <div ref={title} className={styles.text}>
        some&nbsp;&nbsp;&nbsp;recent&nbsp;&nbsp;&nbsp;stuff
      </div>
      
      <svg preserveAspectRatio="none" width={size.width} height={height.current} 
        viewBox={`0 0 ${size.width} ${height.current}`} 
        className={classNames([styles.svg, styles.hover])}>

          { drawPath(10) }
          { drawPath(60) }

          <text textLength={textLength.current} className={styles.label} opacity={opacity}>
            <textPath 
              alignmentBaseline={'middle'} href={`#path-carousel-expand`} id="masterTextPath" 
              fill={`rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`}
              style={{ transform: `translateX(-${color.borderWeight}px)` }}
              textLength={textLength.current} startOffset={off}>some&nbsp;&nbsp;&nbsp;recent&nbsp;&nbsp;&nbsp;stuff</textPath>
          </text>
              
      </svg>        
      
    </div>
  );
})

export default CarouselOverlay;
