import { useContext, cloneElement, useMemo } from "react";
import classNames from "classnames";
import { AppContext } from "../../context/appContext";
import styles from  './middleBlock.module.scss';

function MiddleBlock({ children, width, color, height, top, rotationAngle, style = {} }) {

  const { btmSlideVal, screenSize, gutter, borderRadius, colors, sizes } = useContext(AppContext);
  
  const borderWeight = useMemo(() => {
    return Math.round(colors[color].borderWeight)
  }, [colors, color])
  
  const expandedWidth = useMemo(() => {
    let _totalWidth = sizes.widths[1] + (borderWeight * 2)
    let _containerHeight = ((screenSize.height/4) * height) - gutter
    let _rotation = Math.abs(rotationAngle)
    let _angle1 = 180 - (90 + _rotation)
    let _leftSpace = Math.sin(_rotation * (Math.PI/180)) * _containerHeight
    let _angle2 = 180 - (_angle1 + 90)
    let _hyp = _totalWidth - _leftSpace

    return _hyp / Math.cos(_angle2 * (Math.PI/180))
  }, [borderWeight, sizes, gutter, height, rotationAngle, screenSize.height])

  const positioning = useMemo(() => {
    let _totalWidth = width + (borderWeight * 2)
    let _containerHeight = ((screenSize.height/4) * height) - gutter
    let _rotation = btmSlideVal * Math.abs(rotationAngle)
    let _angle1 = 180 - (90 + _rotation)
    let _leftSpace = Math.sin(_rotation * (Math.PI/180)) * _containerHeight
    let _angle2 = 180 - (_angle1 + 90)
    let _hyp = _totalWidth - _leftSpace

    return {
      totalWidth: _totalWidth,
      containerHeight: _containerHeight,
      rotation: _rotation,
      angle1: _angle1,
      leftSpace: _leftSpace,
      hyp: _hyp,
      angle2: _angle2,
      width: _hyp / Math.cos(_angle2 * (Math.PI/180))
    }
  }, [borderWeight, btmSlideVal, gutter, height, rotationAngle, width, screenSize])

  const _size = useMemo(() => {
    return { width: expandedWidth, height: positioning.containerHeight }
  }, [positioning.containerHeight, expandedWidth])

  const _color = useMemo(() => {
    return colors[color]
  }, [color, colors])

  return (
    <div className={styles.container} style={{
      top: `${(top * (screenSize.height/4)) - ((rotationAngle/60) * (screenSize.height/4))}px`,
      width: `${positioning.width}px`,
      height: `${positioning.containerHeight}px`,
      left: `-${_color.borderWeight}px`,
      transformOrigin: rotationAngle < 0 ? 'top left' : 'bottom left',
      transform: rotationAngle < 0 ? 
      `rotate(-${positioning.rotation}deg)` : 
      `rotate(${positioning.rotation}deg)`,
      borderRadius: `${borderRadius}px`,
      backgroundColor: `rgba(${_color.bgColor[0]}, ${_color.bgColor[1]}, ${_color.bgColor[2]}, ${_color.bgOpacity})`,
      border: `${_color.borderWeight}px solid rgba(${_color.borderColor[0]}, ${_color.borderColor[1]}, ${_color.borderColor[2]}, ${_color.borderOpacity})`,
      opacity: btmSlideVal < 0.1 ? 0 : btmSlideVal < 0.35 ? (btmSlideVal - 0.1) * 4 : 1,
      ...style
    }}>
      <div className={classNames([styles.content, btmSlideVal > 0.9 && styles.visible])} style={{
          width: `${expandedWidth - (_color.borderWeight * 2)}px`, 
          // height: `${sizes.heights[0] - (_color.borderWeight * 2)}px`,
          color: `rgb(${_color.textColor[0]}, ${_color.textColor[1]}, ${_color.textColor[2]})`,
          opacity: btmSlideVal < 0.8 ? 0 : (btmSlideVal - 0.8) * 5
        }}>
        {/* {children} */}
        {children && cloneElement(children, {size: _size})}
      </div>
    </div>
  );
}

export default MiddleBlock;
