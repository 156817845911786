import { useEffect, useContext, useRef, useState, useMemo } from "react";
import { AppContext } from "../../context/appContext";
import CarouselSlide from "../carouselSlide";
import CarouselOverlay from "../carouselOverlay";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styles from "./carousel.module.scss";
import classNames from "classnames";

function Carousel({ color, closedWidth = 0, openWidth = 1, height = 1 }) {
  const {
    onInfoHover,
    bottomScale,
    infoExpanded,
    sizes,
    showMobile,
    rtlSlideVal,
    btmSlideVal,
  } = useContext(AppContext);

  const [active, setActive] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const carousel = useRef();

  const onHover = () => {
    if (!showMobile) onInfoHover(true);
  };

  useEffect(() => {
    if (showMobile) return;
    if (infoExpanded === 1 && !active) {
      setActive(true);
    } else if (infoExpanded !== 1 && active) {
      setActive(false);
    }
  }, [infoExpanded, showMobile, active]);

  useEffect(() => {
    if (!showMobile) return;
    if (btmSlideVal > 0 && rtlSlideVal < 1 && !active) {
      setActive(true);
    } else if ((btmSlideVal === 0 || rtlSlideVal === 1) && active) {
      setActive(false);
    }
  }, [rtlSlideVal, btmSlideVal, showMobile, active]);

  useEffect(() => {
    if (active) {
      carousel.current.slickPlay();
    } else {
      carousel.current.slickPause();
    }
  }, [active]);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "ease-in-out",
    afterChange: (index) => {
      setActiveSlide(index);
    },
  };

  const _slides = [
    {
      label: "sesameorder.com →",
      image: require("../../assets/carousel/sesame.jpg"),
      link: "https://www.frog.co/work/sesame-delivery-designed-by-restaurants",
      curves: 4,
    },
    {
      label: "dinomoves.com →",
      image: require("../../assets/carousel/dinomoves.jpg"),
      link: "https://www.dinomoves.com/",
      curves: 4,
    },
    {
      label: "thesupply.com →",
      image: require("../../assets/carousel/thesupply.jpg"),
      link: "http://thesupply.com/",
      curves: 3,
    },
    {
      label: "smartvoy.ai →",
      image: require("../../assets/carousel/smartvoy.jpg"),
      link: "https://smartvoy.ai/",
      curves: 4,
    },
    {
      label: "noumena ai nft →",
      image: require("../../assets/carousel/noumena.jpg"),
      link: "https://noumena-ai.app/",
      curves: 4,
    },
    {
      label: "new on fxhash →",
      image: require("../../assets/carousel/infinite-loops.jpg"),
      link: "https://www.fxhash.xyz/u/SamFairbairn/creations",
      curves: 3,
    },
  ];

  const goTo = (index) => {
    carousel.current.slickGoTo(index);
  };

  const [grabbing, setGrabbing] = useState(false);

  const onMouseDown = () => {
    setGrabbing(true);
  };

  const onMouseUp = () => {
    setGrabbing(false);
  };

  useEffect(() => {
    document.addEventListener("mouseup", onMouseUp);
    return () => document.removeEventListener("mouseup", onMouseUp);
  }, []);

  const singlewidth = sizes.widths[closedWidth] - color.borderWeight * 2;
  const fullwidth = sizes.widths[openWidth] - color.borderWeight * 2 + 10;
  const fullheight =
    Math.ceil(sizes.heights[height] - color.borderWeight * 2) + 10;
  const expandOverlaySize = useMemo(() => {
    return { width: sizes.widths[closedWidth], height: sizes.heights[height] };
  }, [closedWidth, sizes.heights, sizes.widths, height]);

  return (
    <div
      className={styles.wrapper}
      onMouseOver={onHover}
      style={{ height: `${fullheight}px` }}
    >
      {!showMobile && (
        <div
          className={styles.expandOverlay}
          style={{ width: `${expandOverlaySize.width}px` }}
        >
          <CarouselOverlay
            size={expandOverlaySize}
            show={bottomScale}
            color={color}
          />
        </div>
      )}
      <div
        className={classNames([styles.carousel, grabbing && styles.grabbing])}
        onMouseDown={onMouseDown}
        style={{
          left: showMobile
            ? "0px"
            : `${
                (bottomScale - 1) * ((fullwidth / 2 - singlewidth) / 2) - 5
              }px`,
          width: `${fullwidth}px`,
          height: `${fullheight}px`,
          color: `rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`,
        }}
      >
        <Slider {...settings} ref={carousel}>
          {_slides.map((slide, index) => (
            <div className={styles.slide} key={`slide-${index}`}>
              <div
                className={styles.slideContents}
                style={{
                  // width: `${(sizes.widths[1] - (color.borderWeight * 2)) / 2}px`,
                  height: `${fullheight}px`,
                }}
              >
                <CarouselSlide
                  color={color}
                  copy={slide.label}
                  rtl={index % 2 === 0}
                  image={slide.image}
                  link={slide.link}
                  curves={slide.curves}
                  size={{ width: fullwidth / 2, height: fullheight }}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className={styles.discs}>
        <div className={styles.background}></div>
        {_slides.map((slide, index) => (
          <div
            key={`disc-${index}`}
            className={classNames([
              styles.disc,
              activeSlide === index && styles.active,
            ])}
            onClick={() => {
              goTo(index);
            }}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default Carousel;
