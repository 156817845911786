import { useContext, memo, useMemo } from "react";
import classNames from "classnames";
import { AppContext } from "../../context/appContext";
import MiddleBlock from '../middleBlock'
import NoumenaCanvas from '../noumenaCanvas'
import ReactGA from "react-ga4";
import styles from  './middleContent.module.scss';

const MiddleContent = memo(({ width, height, top, left, color, background, border, container, hidden, onTop }) => {

  const { screenSize, gutter, btmSlideVal, sizes, colors } = useContext(AppContext);

  const positions = useMemo(() => {
    let colWidth = screenSize.width / 5
    let colHeight = screenSize.height / 4
    return {
      height: colHeight * height,
      width: colWidth * width,
      left: colWidth * left,
      top: colHeight * top,
      insideWidth: (colWidth * width) - gutter
    }
  }, [height, width, left, top, screenSize, gutter])

  const triggerEvent = (label) => {
    ReactGA.event({
      category: "noumena-link",
      action: "click",
      label: label
    });
  }
  
  const _color = useMemo(() => {
    return colors[color]
  }, [color, colors])

  return (
    <div 
      className={styles.container}
      style={{ 
        width: `${positions.width}px`, 
        height: `${positions.height}px`,
        left: `${positions.left}px`,
        top: `${positions.top}px`,
        backgroundColor: color && container ? `rgba(${_color.containerColor[0]}, ${_color.containerColor[1]}, ${_color.containerColor[2]}, ${_color.containerOpacity})` : 'transparent',
        zIndex: onTop ? 9999 : 'inherit',        
      }}
    >
      <div 
        className={classNames([styles.content, onTop && styles.onTop])}
        style={{ 
          width: `${positions.insideWidth}px`, 
          height: `${positions.height - gutter}px`,
          left: `${gutter / 2}px`,
          top: `${gutter / 2}px`,
        }}
      >

        <MiddleBlock width={positions.insideWidth} height={1} top={0} color={2} rotationAngle={10} sizes={sizes}>
          <>
            <span className={styles.label}>featured</span>
            <span className={styles.title}>noumena</span>
          </>
        </MiddleBlock>

        <MiddleBlock width={positions.insideWidth} height={2} top={2} color={8} rotationAngle={10} sizes={sizes}>
          <NoumenaCanvas sizes={sizes} />
        </MiddleBlock>

        <MiddleBlock width={positions.insideWidth} height={1.5} top={0.75} color={5} rotationAngle={-10} sizes={sizes} >
          <>
            <div className={styles.copy}>
              noumena is an ongoing generative ai art nft project. collaborate with a StyleGAN model to generate and mint your own piece of generative art
            </div>
          </>
        </MiddleBlock>

        <div className={classNames([styles.buttons, btmSlideVal > 0.9 && styles.active])} style={{
          top: `${(sizes.heights[0] * 2.5) - gutter}px`,
          opacity: btmSlideVal < 0.1 ? 0 : btmSlideVal < 0.65 ? (btmSlideVal - 0.4) * 4 : 1,
          pointerEvents: btmSlideVal < 0.9 ? 'none' : 'auto'
        }}>
          <a href="https://twitter.com/noumena_nft" target="_blank" rel="noreferrer" className={classNames([styles.button, styles.twitter])} 
            style={{
              backgroundColor: `rgba(${colors[3].bgColor[0]}, ${colors[3].bgColor[1]}, ${colors[3].bgColor[2]}, ${colors[3].bgOpacity})`,
              border: `4px solid rgb(${colors[3].borderColor[0]}, ${colors[3].borderColor[1]}, ${colors[3].borderColor[2]})`
            }}
            onClick={() => {triggerEvent('twitter')}}
          >
            {/* <TwitterIcon /> */}
            <div className={styles.iconWrapper}>
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 310 310" style={{ enableBackground: 'new 0 0 310 310'}}>
                <path style={{
                  fill: `rgb(${colors[3].textColor[0]}, ${colors[3].textColor[1]}, ${colors[3].textColor[2]})`
                }} d="M302.973,57.388c-4.87,2.16-9.877,3.983-14.993,5.463c6.057-6.85,10.675-14.91,13.494-23.73
                  c0.632-1.977-0.023-4.141-1.648-5.434c-1.623-1.294-3.878-1.449-5.665-0.39c-10.865,6.444-22.587,11.075-34.878,13.783
                  c-12.381-12.098-29.197-18.983-46.581-18.983c-36.695,0-66.549,29.853-66.549,66.547c0,2.89,0.183,5.764,0.545,8.598
                  C101.163,99.244,58.83,76.863,29.76,41.204c-1.036-1.271-2.632-1.956-4.266-1.825c-1.635,0.128-3.104,1.05-3.93,2.467
                  c-5.896,10.117-9.013,21.688-9.013,33.461c0,16.035,5.725,31.249,15.838,43.137c-3.075-1.065-6.059-2.396-8.907-3.977
                  c-1.529-0.851-3.395-0.838-4.914,0.033c-1.52,0.871-2.473,2.473-2.513,4.224c-0.007,0.295-0.007,0.59-0.007,0.889
                  c0,23.935,12.882,45.484,32.577,57.229c-1.692-0.169-3.383-0.414-5.063-0.735c-1.732-0.331-3.513,0.276-4.681,1.597
                  c-1.17,1.32-1.557,3.16-1.018,4.84c7.29,22.76,26.059,39.501,48.749,44.605c-18.819,11.787-40.34,17.961-62.932,17.961
                  c-4.714,0-9.455-0.277-14.095-0.826c-2.305-0.274-4.509,1.087-5.294,3.279c-0.785,2.193,0.047,4.638,2.008,5.895
                  c29.023,18.609,62.582,28.445,97.047,28.445c67.754,0,110.139-31.95,133.764-58.753c29.46-33.421,46.356-77.658,46.356-121.367
                  c0-1.826-0.028-3.67-0.084-5.508c11.623-8.757,21.63-19.355,29.773-31.536c1.237-1.85,1.103-4.295-0.33-5.998
                  C307.394,57.037,305.009,56.486,302.973,57.388z"/>
              </svg>
            </div>
          </a>
          <a href="https://noumena-ai.app/" target="_blank" rel="noreferrer" className={classNames([styles.button, styles.website])} 
            style={{
              backgroundColor: `rgba(${colors[10].bgColor[0]}, ${colors[10].bgColor[1]}, ${colors[10].bgColor[2]}, ${colors[10].bgOpacity})`,
              border: `4px solid rgb(${colors[10].borderColor[0]}, ${colors[10].borderColor[1]}, ${colors[10].borderColor[2]})`
            }}
            onClick={() => {triggerEvent('website')}}
            >
            {/* <WebsiteIcon style={iconStyles} /> */}
            <div className={styles.iconWrapper}>
              <svg width="512px" height="512px" viewBox="0 0 512 512" id="_x30_1" style={{ enableBackground: 'new 0 0 512 512' }} version="1.1">
                <path style={{
                  fill: `rgb(${colors[10].textColor[0]}, ${colors[10].textColor[1]}, ${colors[10].textColor[2]})`
                }} d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M418.275,146h-46.667  c-5.365-22.513-12.324-43.213-20.587-61.514c15.786,8.776,30.449,19.797,43.572,32.921C403.463,126.277,411.367,135.854,418.275,146  z M452,256c0,17.108-2.191,33.877-6.414,50h-64.034c1.601-16.172,2.448-32.887,2.448-50s-0.847-33.828-2.448-50h64.034  C449.809,222.123,452,238.892,452,256z M256,452c-5.2,0-21.048-10.221-36.844-41.813c-6.543-13.087-12.158-27.994-16.752-44.187  h107.191c-4.594,16.192-10.208,31.1-16.752,44.187C277.048,441.779,261.2,452,256,452z M190.813,306  c-1.847-16.247-2.813-33.029-2.813-50s0.966-33.753,2.813-50h130.374c1.847,16.247,2.813,33.029,2.813,50s-0.966,33.753-2.813,50  H190.813z M60,256c0-17.108,2.191-33.877,6.414-50h64.034c-1.601,16.172-2.448,32.887-2.448,50s0.847,33.828,2.448,50H66.414  C62.191,289.877,60,273.108,60,256z M256,60c5.2,0,21.048,10.221,36.844,41.813c6.543,13.087,12.158,27.994,16.752,44.187H202.404  c4.594-16.192,10.208-31.1,16.752-44.187C234.952,70.221,250.8,60,256,60z M160.979,84.486c-8.264,18.301-15.222,39-20.587,61.514  H93.725c6.909-10.146,14.812-19.723,23.682-28.593C130.531,104.283,145.193,93.262,160.979,84.486z M93.725,366h46.667  c5.365,22.513,12.324,43.213,20.587,61.514c-15.786-8.776-30.449-19.797-43.572-32.921C108.537,385.723,100.633,376.146,93.725,366z   M351.021,427.514c8.264-18.301,15.222-39,20.587-61.514h46.667c-6.909,10.146-14.812,19.723-23.682,28.593  C381.469,407.717,366.807,418.738,351.021,427.514z"/>
              </svg>
            </div>
          </a>
        </div>

      </div>
    </div>
  );
})

export default MiddleContent;
