import { useEffect, useRef, useState, useContext, useMemo } from "react";
import classNames from 'classnames'
import { gsap } from "gsap/all";
import { AppContext } from "../../context/appContext";
import styles from  './bottomSlider.module.scss';

function BottomSlider({ forceOpen, color, size, width, height }) {

  const { bottomScale, colors, sliderThickness, btmSlideVal, updateBtmSlider, sizes } = useContext(AppContext);

  const [handlePoint, setHandlePoint] = useState();
  const [grabbing, setGrabbing] = useState(false);

  const adjustTimer = useRef(undefined);

  const targetVal = useRef(0);
  const svgLine = useRef();

  const box = useMemo(() => {
    return {
      width: (width || sizes.widths[1]) - (color.borderWeight * 2),
      height: (height || sizes.heights[0]) - (color.borderWeight * 2)
    }
  }, [width, height, sizes, color.borderWeight])

  const positions = useMemo(() => {
    return {
      start: (sliderThickness/2) + (color.borderWeight/2),
      max: box.width - (sliderThickness/2) - (color.borderWeight/2)
    }
  }, [color.borderWeight, box.width, sliderThickness])

  const radius = useMemo(() => {
    return (sliderThickness/2) - color.borderWeight - 10
  }, [sliderThickness, color.borderWeight])

  useEffect(() => {
    if (svgLine.current) setHandlePoint(svgLine.current.getPointAtLength(btmSlideVal * svgLine.current.getTotalLength()))
  }, [color.borderWeight, btmSlideVal, positions, sizes])

  const handleDown = (e) => {
    if (!e || (bottomScale < 0.9 && !forceOpen)) return
    setGrabbing(true)

    let x = e.touches && e.touches.length ? e.touches[0].clientX : e.clientX
    x = x - size.left - color.borderWeight - positions.start
    x = x / (positions.max - positions.start)
    x = gsap.utils.clamp(0, 1, x)
    
    targetVal.current = x
    updateBtmSlider(targetVal.current)

    if (adjustTimer.current) {
      clearTimeout(adjustTimer.current)
      adjustTimer.current = undefined
    }
  }

  const onContainerMove = (e) => {
    if (!e) return
    let x = e.touches && e.touches.length ? e.touches[0].clientX : e.clientX
    x = x - size.left - color.borderWeight - positions.start
    x = x / (positions.max - positions.start)
    x = gsap.utils.clamp(0, 1, x)
    
    if (grabbing) {
      targetVal.current = x
      updateBtmSlider(targetVal.current)
    }
  }
  const handleUp = (e) => {
    setGrabbing(false)

    if (adjustTimer.current) {
      clearTimeout(adjustTimer.current)
      adjustTimer.current = undefined
    }
    
    adjustPosition()
  }

  const adjustPosition = () => {
    if (targetVal.current >= 0.5) {
      targetVal.current = 1
      updateBtmSlider(targetVal.current)
    } else {
      targetVal.current = 0
      updateBtmSlider(targetVal.current)
    }
  }

  useEffect(() => {
    if (!grabbing) targetVal.current = btmSlideVal
  }, [btmSlideVal, grabbing])

  return (
    <div className={classNames([styles.wrapper, grabbing && styles.grabbing])} 
      onTouchStart={handleDown} onMouseDown={handleDown} 
      onTouchMove={onContainerMove} onMouseMove={onContainerMove} 
      onTouchEnd={handleUp} onMouseUp={handleUp} onMouseOut={handleUp} >
      { box.width > 0 && box.height > 0 && ( <>
        <svg preserveAspectRatio="none" width={box.width} height={box.height} viewBox={`0 0 ${box.width} ${box.height}`} className={styles.svg}>

          {/* // H ${(positions.max/2) + ((positions.max/2) * (forceOpen ? 1 : bottomScale))} */}
          <path id="sliderPath" ref={svgLine} style={{pointerEvents: 'none'}} d={`
            M ${positions.start} ${box.height / 2}
            H ${(positions.max/2) + (positions.max/2)}
          `} fill="none" 
          strokeWidth={Math.max(color.borderWeight, 2)}
          stroke={`rgb(${color.borderColor[0]}, ${color.borderColor[1]}, ${color.borderColor[2]})`}
          strokeLinecap="round" />

        {handlePoint && (<>
          <circle cx={handlePoint.x} cy={handlePoint.y} r={radius} 
          fill={`rgb(${colors[0].bgColor[0]}, ${colors[0].bgColor[1]}, ${colors[0].bgColor[2]})`} 
          strokeWidth={color.borderWeight * 2}
          stroke={`rgb(${color.borderColor[0]}, ${color.borderColor[1]}, ${color.borderColor[2]})`}
          strokeOpacity={color.borderOpacity}
          style={{paintOrder: 'stroke'}} />

          <path strokeWidth={1.5} stroke={`rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`} strokeLinecap="round" fill="none"
            transform={`rotate(${btmSlideVal * 180}, ${handlePoint.x} ${handlePoint.y})`}
            className={styles.arrow}
            d={`
            M ${handlePoint.x - 10} ${handlePoint.y}
            H ${handlePoint.x + 10}
            `}
          /> 

          <path strokeWidth={1.5} stroke={`rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`} strokeLinecap="round" fill="none"
            transform={`rotate(${btmSlideVal * 180}, ${handlePoint.x} ${handlePoint.y})`}
            className={styles.arrow}
            d={`
              M ${handlePoint.x} ${handlePoint.y - 9}
              L ${handlePoint.x + 10} ${handlePoint.y}
              L ${handlePoint.x} ${handlePoint.y + 9}
            `}
          />

        </>)}

        </svg>
      </>)}
    </div>
  );
}

export default BottomSlider;
