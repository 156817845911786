import { useContext } from "react";
import { AppContext } from "../../context/appContext";

function MShapeGroup({ color, size, left = 0, top = 0, mousePos, useHex, nft = false }) {

  const { borderRadius } = useContext(AppContext);

  /*
  ** gap
  */

  const gap = (color.borderWeight / 4) + 1

  /*
  ** positions
  */
  
  let boxWidth = size.width - (nft ? 0 : color.borderWeight)
  let boxHeight = size.height - (nft ? 0 : color.borderWeight)

  let leftX = Math.cos(mousePos.x * (Math.PI * 0.75))
  let rightX = Math.cos(mousePos.x * (Math.PI * 0.75) + (Math.PI * 0.25))

  const topX = Math.sin((mousePos.x - 0.5) * Math.PI) * (boxWidth * 0.2)
  const topY = 1 - Math.cos(mousePos.y * Math.PI) * (boxHeight * 0.25)

  const topYBase = boxHeight/3
  const bottomYBase = boxHeight/2

  const bottomY = 1 - Math.cos(mousePos.y * Math.PI) * (boxHeight * 0.15) + (leftX * boxHeight * 0.05)
  const bottomY2 = 1 - Math.cos(mousePos.y * Math.PI) * (boxHeight * 0.15) + (rightX * boxHeight * -0.05)

  const bottomX2 = leftX * (boxWidth * -0.15)
  const bottomX = rightX * (boxWidth * -0.15)

  const cornerScale = gap * (2 + (Math.cos(mousePos.y * Math.PI / 2) * 2))
  const cornerScaleX = gap * (3 + (leftX * 2))
  const cornerScaleX2 = gap * (3 + (rightX * -2))

  
  let leftPos = boxWidth/1.5 + (gap * 2) + bottomX
  let rightPos = boxWidth/3 - (gap * 2) + bottomX2
  
  let middle = boxWidth/2 - gap
  const topLBorderRadius = borderRadius > middle + topX ? middle + topX : borderRadius
  const topRBorderRadius = borderRadius > middle - topX ? middle - topX : borderRadius

  const bottomRBorderRadius = borderRadius < (boxWidth - leftPos) ? borderRadius : boxWidth - leftPos
  const bottomLBorderRadius = borderRadius < rightPos ? borderRadius : rightPos

  let middleGap =  (boxWidth/1.5 + bottomX - (gap * 2)) - (boxWidth/3 + bottomX2)
  const bottomMBorderRadius = middleGap < borderRadius * 2 ? middleGap/2 : borderRadius

  const getPath = () => {
    let path = ''

    if (borderRadius > 1) {
      path += `M ${left} ${topLBorderRadius + top}`
      path += ` A ${topLBorderRadius} ${topLBorderRadius} 0 0 1 ${left + topLBorderRadius} ${top}`
    } else {
      path += `M ${left} ${top}`
    }

    if (borderRadius > 1) {
      path += ` H ${boxWidth/2 - (gap * 2) + topX + left}`
      path += ` A ${gap} ${gap} 0 0 1 ${boxWidth/2 - gap + topX + left} ${gap + top}`
    } else {
      path += ` H ${boxWidth/2 - gap + topX + left}`
    }

    path += ` V ${topYBase + topY + top}`
    path += ` A ${gap} ${gap} 1 0 0 ${boxWidth/2 + gap + topX + left} ${topYBase + topY + top}`
    
    if (borderRadius > 1) {
      path += ` V ${gap + top}`
      path += ` A ${gap} ${gap} 0 0 1 ${boxWidth/2 + (gap * 2) + topX + left} ${top}`
    } else {
      path += ` V ${top}`
    }

    if (borderRadius > 1) {
      path += ` H ${boxWidth - topRBorderRadius + left}`
      path += ` A ${topRBorderRadius} ${topRBorderRadius} 0 0 1 ${boxWidth + left} ${topRBorderRadius + top}`
    } else {
      path += ` H ${boxWidth + left}`
    }

    if (borderRadius > 1) {
      path += ` V ${boxHeight - bottomRBorderRadius + top}`
      path += ` A ${bottomRBorderRadius} ${bottomRBorderRadius} 0 0 1 ${boxWidth - bottomRBorderRadius + left} ${boxHeight + top}`
    } else {
      path += ` V ${boxHeight + top}`
    }

    path += ` H ${boxWidth/1.5 + (gap * 2) + bottomX + left}`
    path += ` A ${gap} ${gap} 0 0 1 ${boxWidth/1.5 + gap + bottomX + left} ${boxHeight - gap + top}`
    path += ` V ${bottomYBase + bottomY2 + top}`
    path += ` A ${gap} ${gap} 1 0 0 ${boxWidth/1.5 - gap + bottomX + left} ${bottomYBase + bottomY2 + top}`
    
    if (borderRadius > 1) {
      path += ` V ${boxHeight - bottomMBorderRadius + top}`
      path += ` A ${bottomMBorderRadius} ${bottomMBorderRadius} 0 0 1 ${boxWidth/1.5 - gap - bottomMBorderRadius + bottomX + left} ${boxHeight + top}`
    } else {
      path += ` V ${boxHeight + top}`
    }
    
    if (borderRadius > 1) {
      path += ` H ${boxWidth/3 + gap + bottomMBorderRadius + bottomX2 + left}`
      path += ` A ${bottomMBorderRadius} ${bottomMBorderRadius} 0 0 1 ${boxWidth/3 + gap + bottomX2 + left} ${boxHeight - bottomMBorderRadius + top}`
    } else {
      path += ` H ${boxWidth/3 + gap + bottomX2 + left}`
    }

    path += ` V ${bottomYBase + bottomY + top}`

    path += ` A ${gap} ${gap} 1 0 0 ${boxWidth/3 - gap + bottomX2 + left} ${bottomYBase + bottomY + top}`

    path += ` V ${boxHeight - gap + top}`
    path += ` A ${gap} ${gap} 0 0 1 ${boxWidth/3 - (gap * 2) + bottomX2 + left} ${boxHeight + top}`
    
    if (borderRadius > 1) {
      path += ` H ${bottomLBorderRadius + left}`
      path += ` A ${bottomLBorderRadius} ${bottomLBorderRadius} 0 0 1 ${left} ${boxHeight - bottomLBorderRadius + top}`
    } else {
      path += ` H ${left}`
    }

    path += ` Z`

    return path
  }

  return (
    <g>
      <path style={{pointerEvents: 'none'}} 
        d={getPath()} 
        fill={useHex ? color.bgColor : `rgb(${color.bgColor[0]}, ${color.bgColor[1]}, ${color.bgColor[2]})`} 
        fillOpacity={color.bgOpacity}
        strokeWidth={color.borderWeight} 
        stroke={useHex ? color.borderColor : `rgb(${color.borderColor[0]}, ${color.borderColor[1]}, ${color.borderColor[2]})`} 
        strokeOpacity={color.borderOpacity}
        strokeLinecap="round"
      />

      <path
        style={{pointerEvents: 'none'}} fill="none" strokeWidth={color.borderWeight} 
        stroke={useHex ? color.borderColor : `rgb(${color.borderColor[0]}, ${color.borderColor[1]}, ${color.borderColor[2]})`} 
        strokeOpacity={color.borderOpacity}
        strokeLinecap="round" d={
          `M ${boxWidth/2 - gap + topX - cornerScale + left} ${topYBase + topY + (gap * 2) + (cornerScale/2) + top}`
          + ` A ${cornerScale * 2} ${cornerScale * 2} 0 0 1 ${boxWidth/2 + topX + left} ${topYBase + topY + (gap * 2) + top}`
          + ` A ${cornerScale * 2} ${cornerScale * 2} 0 0 1 ${boxWidth/2 + gap + topX + cornerScale + left} ${topYBase + topY + (gap * 2) + (cornerScale/2) + top}`
          }
      />

      <path
        style={{pointerEvents: 'none'}} fill="none" strokeWidth={color.borderWeight} 
        stroke={useHex ? color.borderColor : `rgb(${color.borderColor[0]}, ${color.borderColor[1]}, ${color.borderColor[2]})`} 
        strokeOpacity={color.borderOpacity}
        strokeLinecap="round" d={
          `M ${boxWidth/3 + gap + bottomX2 + cornerScaleX2 + left} ${bottomYBase + bottomY - (gap * 2) - (cornerScaleX2/2) + top}`
          + ` A ${cornerScaleX2 * 2} ${cornerScaleX2 * 2} 0 0 1 ${boxWidth/3 + bottomX2 + left} ${bottomYBase + bottomY - (gap * 2) + top}`
          + ` A ${cornerScaleX2 * 2} ${cornerScaleX2 * 2} 0 0 1 ${boxWidth/3 + bottomX2 - gap - cornerScaleX2 + left} ${bottomYBase + bottomY - (gap * 2) - (cornerScaleX2/2) + top}`
        }
      />  

      <path
        style={{pointerEvents: 'none'}} fill="none" strokeWidth={color.borderWeight} 
        stroke={useHex ? color.borderColor : `rgb(${color.borderColor[0]}, ${color.borderColor[1]}, ${color.borderColor[2]})`} 
        strokeOpacity={color.borderOpacity}
        strokeLinecap="round" d={
          `M ${boxWidth/1.5 + gap + bottomX + cornerScaleX + left} ${bottomYBase + bottomY2 - (gap * 2) - (cornerScaleX/2) + top}`
          + ` A ${cornerScaleX * 2} ${cornerScaleX * 2} 0 0 1 ${boxWidth/1.5 + bottomX + left} ${bottomYBase + bottomY2 - (gap * 2) + top}`
          + ` A ${cornerScaleX * 2} ${cornerScaleX * 2} 0 0 1 ${boxWidth/1.5 + bottomX - gap - cornerScaleX + left} ${bottomYBase + bottomY2 - (gap * 2) - (cornerScaleX/2) + top}`
        }
      /> 
    </g>
  );
}

export default MShapeGroup;
