export const vert = `
  attribute vec4 a_position;
  
  void main() {
    gl_Position = a_position;
  }
`

export const frag = `

#ifdef GL_ES
precision mediump float;
#endif

uniform vec2 u_resolution;
uniform vec2 u_mouse;
uniform float u_time;
uniform float u_hover;
uniform float u_progress;

uniform sampler2D u_texture;
uniform vec2 u_textureResolution;

uniform sampler2D u_video;
uniform vec2 u_videoResolution;

void main() {
   	// vec2 uv = (gl_FragCoord.xy / u_resolution.xy) * (u_resolution.xy/u_mouse);
   	vec2 uv = gl_FragCoord.xy / u_resolution.xy;

    // vec2 mousePos = u_mouse.xy / u_resolution.xy;
    // float pct = 0.0;
    // pct = distance(mousePos.xy, uv);
    
    // vec2 pct = ((gl_FragCoord.xy / u_resolution.xy) - (u_mouse.xy / u_resolution.xy)) * 5.;
    // float size = (100.0 * pct.x) * u_hover / u_resolution.y;
    float size = 200.0 * u_hover / u_resolution.y;

    float dx = size / u_resolution.x;
    float dy = size / u_resolution.y;
    
    uv = vec2(dx * (floor(uv.x / dx) + 0.5), dy * (floor(uv.y / dy) + 0.5));
    vec3 video = texture2D(u_video, uv).rgb;

    // vec2 mousePos = u_mouse.xy / u_resolution.xy;
    // float pct = 0.0;
    // pct = distance(mousePos.xy, uv) * 2.;
     
   	// vec2 texUV = (gl_FragCoord.xy / u_resolution.xy);
    // vec3 texCol = texture2D(u_texture, texUV).rgb;
    vec3 texCol = texture2D(u_texture, vec2(video.r, u_progress)).rgb;
    
    gl_FragColor = vec4(texCol, 1.0);
    // gl_FragColor = mix(vec4(texCol, 1.0), vec4(video, 1.0), pct);
}
`