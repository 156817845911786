function ShuffleIcon({ color }) {
  return (
    <svg enableBackground="new 0 0 40 40" version="1.1" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path fill={`rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`} strokeWidth={1} d="M9.1,26c-0.6,0-1-0.4-1-1s0.4-1,1-1c2,0,4-0.8,5.4-2.2c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4C14.1,25,11.6,26,9.1,26z"/>
      </g>
      <g>
        <path fill={`rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`} strokeWidth={1} d="M31.8,25.8L29,28.6c-0.2,0.2-0.5,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l1.1-1.1h-2.3   c-2.6,0-5-1-6.8-2.8l-5-5c-1.4-1.4-3.4-2.2-5.4-2.2c-0.6,0-1-0.4-1-1s0.4-1,1-1c2.5,0,5,1,6.8,2.8l5,5c1.5,1.4,3.4,2.2,5.4,2.2h2.3   l-1.1-1.1c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l2.8,2.8C32.2,24.7,32.2,25.4,31.8,25.8z"/>
        <path fill={`rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`} strokeWidth={1} d="M31.8,15.7L29,18.5c-0.2,0.2-0.5,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l1.1-1.1h-2.3   c-2,0-4,0.8-5.4,2.2c-0.2,0.2-0.5,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4c1.8-1.8,4.2-2.8,6.8-2.8h2.3l-1.1-1.1   c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l2.8,2.8C32.2,14.6,32.2,15.3,31.8,15.7z"/>
      </g>
    </svg>
  );
}

export default ShuffleIcon;