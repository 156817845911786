import { useContext } from "react";
import ExpandButton from '../expandButton'
import { AppContext } from "../../context/appContext";
import styles from  './minterContent.module.scss';
import Thumb from './thumb'
import { COPY } from '../../utils/constants'

function MinterContent({ color, size }) {

  const { gutter, onRightHover, rightScale, onRightClick, rightExpanded, sizes } = useContext(AppContext);
  
  const onClick = () => {
    if (rightExpanded) {
      onRightHover(false)
      onRightClick(true)
    } else {
      onRightHover(true)
      setTimeout(() => {
        onRightClick(false)
      }, 250)
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content} style={{
          width: `${sizes.widths[1] - (color.borderWeight * 2)}px`, 
          height: `${sizes.heights[1] - (color.borderWeight * 2)}px`,
          color: `rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`,
        }}>

          <div className={styles.left}
            style={{ 
              width: `${sizes.widths[0] - (color.borderWeight * 2)}px`, 
              height: `${sizes.heights[1] - (color.borderWeight * 2)}px`
            }}>
            <ExpandButton title="mint a sam" onClickCB={onClick} directionUp={true} width={sizes.widths[0]} height={sizes.heights[1]} expanded={rightScale} vertical={true} direction={rightScale ? 'up':'down'} sizes={sizes} color={color} />
          </div>

          { rightScale > 0 && (<>

            <div className={styles.right}
              style={{ 
                width: `${sizes.widths[0] + gutter}px`, 
                height: `${sizes.heights[1] - (color.borderWeight * 2)}px`,
                opacity: rightScale
              }}
            >
              <div className={styles.body} dangerouslySetInnerHTML={{__html: COPY.minterDsc}} />
            </div>

            <Thumb color={color} visibility={rightExpanded === 1 ? 1 : 0} />

          </>)}
        
      </div>
    </div>
  );
}

export default MinterContent;
