import { useContext, useState, useRef, useMemo } from "react";
import ArrowIcon from '../arrowIcon';
import { AppContext } from "../../context/appContext";
import { SOCIALS } from '../../utils/constants';
import ReactGA from "react-ga4";
import styles from  './fanContainer.module.scss';

function FanContainer({ width, height, top, left, color, background, border, container, hidden, onTop }) {

  const { screenSize, gutter, borderRadius, colors } = useContext(AppContext);
  const [hovering, setHovering] = useState()

  let colWidth = screenSize.width / 5
  let colHeight = screenSize.height / 4

  const _height = colHeight * height
  const _width = colWidth * width
  const _left = colWidth * left
  const _top = colHeight * top

  const links = useRef(SOCIALS)

  const onHover = (index) => {
    setHovering(index)
  }
  
  const onOut = () => {
    setHovering(undefined)
  }

  const triggerEvent = (label) => {
    ReactGA.event({
      category: "links",
      action: "click",
      label: label
    });
  }

  const _color = useMemo(() => {
    return colors[color]
  }, [color, colors])

  return (
    <div 
      className={styles.container}
      style={{ 
        width: `${_width}px`, 
        height: `${_height}px`,
        left: `${_left}px`,
        top: `${_top}px`,
        backgroundColor: color && container ? `rgba(${_color.containerColor[0]}, ${_color.containerColor[1]}, ${_color.containerColor[2]}, ${_color.containerOpacity})` : 'transparent',
        // zIndex: onTop ? 9999 : 'inherit'
      }}
    >

      <div 
        className={styles.content}
        onMouseOver={() => { onHover(0) }}
        onMouseOut={onOut}
        style={{
          width: `${_width - gutter}px`, 
          height: `${_height - gutter}px`,
          left: `${gutter / 2}px`,
          top: `${gutter / 2}px`,
          borderRadius: `${borderRadius}px`,
          backgroundColor: color && background ? `rgba(${_color.bgColor[0]}, ${_color.bgColor[1]}, ${_color.bgColor[2]}, ${_color.bgOpacity})` : 'transparent',
          border: color && border ? `${_color.borderWeight}px solid rgba(${_color.borderColor[0]}, ${_color.borderColor[1]}, ${_color.borderColor[2]}, ${_color.borderOpacity})` : 'transparent',
          overflow: hidden ? 'hidden': 'visible',
          zIndex: hovering !== undefined ? links.current.length - Math.abs(0 - hovering) : links.current.length - 0,
          color: `rgb(${_color.textColor[0]}, ${_color.textColor[1]}, ${_color.textColor[2]})`
        }}
      >
        <span style={{ marginRight: '10px' }}>links</span>
        <ArrowIcon color={_color} direction="down" />
      </div>

      { links.current.map((link, index) => 
        <a 
          href={link.link} target="_blank" rel="noreferrer"
          key={`fan-${index}`}
          className={styles.content}
          onMouseOver={() => { onHover(index + 1) }}
          onMouseOut={onOut}
          onClick={() => { triggerEvent(link.label) }}
          style={{
            width: `${_width - gutter}px`, 
            height: `${_height - gutter}px`,
            left: `${gutter / 2}px`,
            top: `${gutter / 2}px`,
            borderRadius: `${borderRadius}px`,
            backgroundColor: color && background ? `rgba(${_color.bgColor[0]}, ${_color.bgColor[1]}, ${_color.bgColor[2]}, ${_color.bgOpacity})` : 'transparent',
            border: color && border ? `${_color.borderWeight}px solid rgba(${_color.borderColor[0]}, ${_color.borderColor[1]}, ${_color.borderColor[2]}, ${_color.borderOpacity})` : 'transparent',
            overflow: hidden ? 'hidden': 'visible',
            zIndex: hovering !== undefined ? links.current.length - Math.abs((index + 1) - hovering) : links.current.length - (index + 1),
            color: `rgb(${_color.textColor[0]}, ${_color.textColor[1]}, ${_color.textColor[2]})`
          }}
        >
            {link.label}
        </a>
      )}
    </div>
  );
}

export default FanContainer;
