import { useEffect, useRef, useState, useContext, useCallback, useMemo } from "react";
import { AppContext } from "../../context/appContext";
import GlslCanvas from 'glslCanvas';
import styles from  './noumenaCanvas.module.scss';
import vid from '../../assets/noumena-5s-s.mp4'
import { frag } from './shaders'
import gradientImg from '../../assets/gradient-combined.jpg'

function NoumenaCanvas({ color, size }) {

  const { colors, btmSlideVal, slideVal, shuffled, sizes } = useContext(AppContext);
  
  const wrapper = useRef();
  const glslCanvas = useRef();
  const colorCanvas = useRef();
  const sandbox = useRef();
  
  const playing = useRef(false);
  const [colorRange, setColorRange] = useState()

  const vidSize = useMemo(() => {
    return size.width > size.height ? size.width : size.height
  }, [size])

  const updateColorRange = useCallback(() => {
    if (colors) {
      let _colors = [...colors].splice(2, colors.length).map(c => c.bgColor)
      setColorRange(_colors)
    }
  }, [colors])

  const updateColorCanvas = useCallback(() => {
    if (colorRange) {
      const canvas = colorCanvas.current
      canvas.width = (colorRange.length-1) * 50

      const ctx = canvas.getContext("2d");

      for (let i = 0; i < colorRange.length - 1; i++) {
        
        // ctx.fillStyle = `rgb(${colorRange[i].r}, ${colorRange[i].g}, ${colorRange[i].b})`

        var my_gradient = ctx.createLinearGradient(50 * i, 0, 50 * (i + 1), 0);
        my_gradient.addColorStop(0, `rgb(${colorRange[i][0]}, ${colorRange[i][1]}, ${colorRange[i][2]})`);
        my_gradient.addColorStop(1, `rgb(${colorRange[i+1][0]}, ${colorRange[i+1][1]}, ${colorRange[i+1][2]})`);
        ctx.fillStyle = my_gradient;

        ctx.fillRect(50 * i, 0, 50, 50);
      }

      let img = colorCanvas.current.toDataURL()
      if (sandbox.current) {
        sandbox.current.setUniform("u_texture", img);
        // sandbox.current.textures.u_video.source.play()
      }
    }
  }, [colorRange])

  const initGLSL = useRef(() => {
    if (!sandbox.current && glslCanvas.current) {
      sandbox.current = new GlslCanvas(glslCanvas.current);
      sandbox.current.load(frag)

      sandbox.current.setUniform("u_texture", gradientImg);
      sandbox.current.setUniform("u_video", vid);
      sandbox.current.setUniform("u_hover", 1);
      sandbox.current.setUniform("u_progress", slideVal);
      // sandbox.current.setUniform("u_mouse", mousePos.x, mousePos.y);

      sandbox.current.textures.u_video.source.loop = true
      sandbox.current.textures.u_video.source.muted = true
      // sandbox.current.textures.u_video.source.play()

      sandbox.current.pause()
    }
  }, [slideVal])

  useEffect(() => {
    initGLSL.current()
    // setTimeout(initGLSL, 2000)
    // updateColorRange()
  }, [])

  const updateTO = useRef()

  useEffect(() => {
    clearTimeout(updateTO.current)
    updateTO.current = null

    if (playing.current) {
      updateColorRange()
    } else {
      updateTO.current = setTimeout(() => {
        clearTimeout(updateTO.current)
        updateTO.current = null
        updateColorRange()
      }, 500)
    }

    return () => clearTimeout(updateTO.current)
  }, [colors, updateColorRange])

  // useEffect(() => {
  //   if (sandbox.current) sandbox.current.setUniform("u_mouse", mousePos.x, mousePos.y);
  // }, [mousePos])

  useEffect(() => {
    if (sandbox.current) sandbox.current.setUniform("u_progress", slideVal)
  }, [slideVal])

  useEffect(() => {
    if (btmSlideVal >= 0.99 && !playing.current) {
      playing.current = true
      if (sandbox.current) {
        sandbox.current.play()
        sandbox.current.textures.u_video.source.play()
      }
    } else if (btmSlideVal < 0.99 && playing.current) {
      playing.current = false
      if (sandbox.current) {
        sandbox.current.pause()
        sandbox.current.textures.u_video.source.pause()
      }
    }
  }, [
    btmSlideVal
  ])

  const onOver = () => {
    if (sandbox.current) sandbox.current.setUniform("u_hover", sizes.widths[1]);
  }
  
  const onOut = () => {
    if (sandbox.current) sandbox.current.setUniform("u_hover", 1);
  }

  useEffect(() => {
    if (colorRange && shuffled) {
      updateColorCanvas()
    }
  }, [colorRange, shuffled, updateColorCanvas])

  useEffect(() => {
    if (shuffled) {
      updateColorCanvas()
    } else {
      if (sandbox.current) sandbox.current.setUniform("u_texture", gradientImg);
    }
  }, [shuffled, updateColorCanvas])

  // if (!colorRange) return null

  return (
    <div className={styles.wrapper} ref={wrapper} onMouseOver={onOver} onMouseOut={onOut} style={{ width: `${vidSize}px`, height: `${vidSize}px` }}>
      <canvas className={styles.colorCanvas} ref={colorCanvas} height={50}></canvas>
        <canvas className={styles.glslCanvas} width={512} height={512} ref={glslCanvas}></canvas>
    </div>
  );
}

export default NoumenaCanvas;
