import { useState, useEffect, useRef, useCallback, useMemo, memo } from "react";
import classNames from "classnames";
import styles from  './scrollContent.module.scss';

const ScrollProgress = memo(({ color, size, progress, updateProgress }) => {

  const wrapper = useRef();
  const _grabbing = useRef(false);
  const [grabbing, setGrabbing] = useState(false);
  const curves = 5;
  const curveHeight = useRef(size.height / curves);
  const path = useRef();
  const thumb = useRef();
  const pathLength = useRef();
  
  useEffect(() => {
    pathLength.current = path.current.getTotalLength();
    curveHeight.current = size.height / curves;
  }, [size])

  const updatePos = useCallback((e) => {
    const wrapperPos = wrapper.current.getBoundingClientRect()
    const pos = Math.max(Math.min(e.clientY - wrapperPos.top, wrapperPos.height), 0);
    updateProgress({}, pos/wrapperPos.height)
  }, [updateProgress])

  const startTouch = (e) => {
    _grabbing.current = true;
    setGrabbing(_grabbing.current);
    updatePos(e)
  }
  
  const touchMove = useCallback((e) => {
    if (!_grabbing.current) return
    updatePos(e)
  }, [updatePos])
  
  const touchEnd = useCallback((e) => {
    _grabbing.current = false;
    setGrabbing(_grabbing.current);
  }, [setGrabbing])

  useEffect(() => {
    pathLength.current = path.current.getTotalLength();
    document.body.addEventListener('mousemove', touchMove)
    document.body.addEventListener('mouseup', touchEnd)

    return () => {
      document.body.removeEventListener('mousemove', touchMove)
      document.body.removeEventListener('mouseup', touchEnd)
    }
  }, [touchMove, touchEnd])

  const getPath = useMemo(() => {
    let path = `M ${size.width/2} ${0}`
    for (let i = 0; i < curves; i++) {
      if (i%2 === 0) {
        path += ` A ${curveHeight.current} ${curveHeight.current} 0 0 1 ${size.width/2} ${curveHeight.current * (i + 1)}`
      } else {
        path += ` A ${curveHeight.current} ${curveHeight.current} 1 0 0 ${size.width/2} ${curveHeight.current * (i + 1)}`
      }
    }
    return path
  }, [size.width])

  if (!size || !color) return null

  return (
    <div className={classNames([styles.scrollProgress, grabbing && styles.grabbing])} ref={wrapper} onMouseDown={startTouch}>
      <svg preserveAspectRatio="none" width={size.width} height={size.height} viewBox={`0 0 ${size.width} ${size.height}`} className={styles.svg}>
        <path 
          id={`path-curve`} className={styles.path} ref={path} d={getPath}
          stroke={`rgb(${color.borderColor[0]}, ${color.borderColor[1]}, ${color.borderColor[2]})`} 
          strokeWidth={14} strokeLinecap="round" fill="none" 
        />
        <path 
          id={`path-thumb`} className={styles.thumb} ref={thumb} d={getPath} fill="none" 
          stroke={`rgb(${color.bgColor[0]}, ${color.bgColor[1]}, ${color.bgColor[2]})`} strokeWidth={10} strokeLinecap="round"
          style={{
            strokeDasharray: `50px ${pathLength.current}px`,
            strokeDashoffset: `${(pathLength.current - 50) * -progress}px`,
          }}
        />
      </svg>
    </div>
  );
})

export default ScrollProgress;
