import React, { useState, useRef, useEffect } from 'react';
import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all";
import classNames from 'classnames/bind'
import styles from './circleButton.module.scss';


gsap.registerPlugin(ScrollTrigger, Draggable, MotionPathPlugin); 

let cx = classNames.bind(styles);
// const BUTTON_RADIUS = 50      

const STROKE_WIDTH = 30

function CircleButton({copy, center, r = 50}) {

  const [letters, setLetters] = useState([])
  // const [hovering, setHovering] = useState(false)
  // const [radius, setRadius] = useState(r)
  const radius = useRef(r)

  const wrapper = useRef(null)
  // const resetHover = useRef(null)

  // const handleMouseOver = () => {
  //   if (!hovering) {
  //     if (resetHover.current) {
  //       clearTimeout(resetHover.current)
  //       resetHover.current = undefined
  //     }
  //     setHovering(true)
  //     setRadius(r + 20)
  //   }
  // }

  // const handleMouseOut = () => {
  //   if (hovering) {
  //     setHovering(false)
  //     setRadius({value: r})

  //     resetHover.current = setTimeout(() => {
  //       setRadius({value: r})
  //       clearTimeout(resetHover.current)
  //       resetHover.current = undefined
  //     }, 500)

  //   }
  // }

  const calculatePosition = () => {
    let _letters = copy.split('')
    _letters = _letters.map((letter, index) => {
      // const _radius = hovering ? radius : r
      const _radius = radius.current
      const angle = ((360 / _letters.length) * index) * (Math.PI / 180)
      const x = Math.round(_radius * (Math.cos(angle))) + 'px';
      const y = Math.round(_radius * (Math.sin(angle))) + 'px';
      return {
        letter,
        style: {
          transform: `translate3d(${x}, ${y}, 0) rotate(${(angle * (180/Math.PI) + 90)}deg)`,
          // 'font-size': `${_radius/2}px`
        }
      }
    })
    setLetters(_letters)
  }

  useEffect(() => {
    
    calculatePosition()
    gsap.fromTo(wrapper.current, {rotate: 0}, {rotate: 360, duration: 30, repeat: -1, ease: "none"});

    // eslint-disable-next-line
  }, [])
  
  // useEffect(() => {
  //   calculatePosition()
  // }, [radius])

  return (
    <div className={cx({CircleButton: true})} 
      // onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} 
      style={{top: `${center.y}px`, left: `${center.x}px`}}>
      <div className={cx({ButtonWrapper: true})} style={{ width: `${radius.current * 2}px`, height: `${radius.current * 2}px`}}>
      <svg preserveAspectRatio="none" style={{top: -(STROKE_WIDTH/2), left: -(STROKE_WIDTH/2)}}
        width={(radius.current * 2) + STROKE_WIDTH} height={(radius.current * 2) + STROKE_WIDTH} 
        viewBox={`0 0 ${(radius.current * 2) + STROKE_WIDTH} ${(radius.current * 2) + STROKE_WIDTH}`}>
          <circle cx={radius.current + (STROKE_WIDTH/2)} cy={radius.current + (STROKE_WIDTH/2)} r={radius.current}
            fill={`none`} 
            strokeWidth={STROKE_WIDTH}
            stroke={`white`}
            strokeOpacity={0.6}
            style={{pointerEvents: 'none', paintOrder: 'stroke'}}
          />
        </svg>
        <div className={cx({LetterWrapper: true})} ref={wrapper}>
        {letters.map((letter, index) => {
          return (
            <div key={`letter-${index}`} className={cx({Letter: true})} style={letter.style} >
              <div className={cx({LetterInset: true})}>
                { letter.letter }
              </div>
            </div>
          )
        })}
        </div>
      </div>
    </div>
  );
}

export default CircleButton;
