import { useEffect, useState, useCallback, useRef } from "react";
import { ethers, BigNumber } from 'ethers'
import detectEthereumProvider from '@metamask/detect-provider';
import { serializeError } from "eth-rpc-errors";
import Button from '../button'
import ReactGA from "react-ga4";
import classNames from 'classnames'
import samNFTABI from '../../assets/SaaaamNFT.json'
import styles from  './minter.module.scss';

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID
const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

function Minter({svgString, colors, color}) {

  const { ethereum } = window;
  const [hasMetamask, setHasMetamask] = useState()
  
  const [address, setAddress] = useState()
  const [chainID, setChainID] = useState()
  
  const [minting, setMinting] = useState(false)
  const [message, setMessage] = useState({
    type: 0, // 0: normal, 1: success, 2: error
    copy: undefined
  })

  useEffect(() => {
    setMessage({
      type: 0,
      copy: undefined
    })
  }, [colors])

  const updateSettings = useCallback(async () => {
    setMessage({
      type: 0,
      copy: undefined
    })

    const { ethereum } = window;
    const chainId = await ethereum.request({ method: 'eth_chainId' });
    const addresses = await ethereum.request({ method: 'eth_accounts' });

    setAddress(addresses[0])
    setChainID(`${BigNumber.from( chainId ).toNumber()}`)
  }, [])

  const accountChanged = useCallback((accounts) => {
    updateSettings()
  }, [updateSettings])

  const chainChanged = useCallback(() => {
    updateSettings()
  }, [updateSettings])


  const [provider, setProvider] = useState()

  useEffect(() => {
    if (provider) {
      setHasMetamask(provider.isMetaMask)
      updateSettings()

      provider.on("accountsChanged", accountChanged);
      provider.on("chainChanged", chainChanged);
  
      return function () {
        provider.removeListener("accountsChanged", accountChanged);
        provider.removeListener("chainChanged", chainChanged);
      }

    }
  }, [provider, accountChanged, chainChanged, updateSettings])

  useEffect(() => {
    const setup = async () => {
      const browserProvider = await detectEthereumProvider();
      setProvider(browserProvider)
    }

    setup()
  }, []);

  const connect = async () => {
    if (hasMetamask) {
      try {
        await ethereum.request({
          method: "eth_requestAccounts",
        });
        updateSettings()
      } catch (err) {
        console.log('some err', err);
      }
    }
  }

  const contract = useRef();

  const connectContract = useCallback(async () => {
    if (chainID !== CHAIN_ID) return

    let _provider = new ethers.providers.Web3Provider(provider)

    contract.current = new ethers.Contract(
      CONTRACT_ADDRESS, // PolygonMumbai
      samNFTABI.abi,
      _provider.getSigner(),
    )

    // let supply = (await contract.current.currentSupply()).toNumber()
    // console.log('supply', supply)
    // let maxSupply = (await contract.current.maxSupply()).toNumber()
    // console.log('maxSupply', maxSupply)

  }, [chainID, provider])

  useEffect(() => {
    if (address && chainID && !contract.current) {
      connectContract()
    }
  }, [chainID, address, connectContract])

  const mint = async () => {

    if (minting || !svgString) return

    setMessage({
      type: 0,
      copy: 'saving to ipfs...'
    })

    setMinting(true)

    const blob = new Blob([svgString], {type: 'image/svg+xml'});
    var form = new FormData();
    form.append("file", blob, 'saaaaam.svg');

    try {
      let _res = await fetch(API_ENDPOINT,
      {
        method: 'POST',
        body: form
      })
      .then(res=>res.json())

      ReactGA.event({
        category: "minter",
        action: "click",
        label: 'ipfs'
      });

      setMessage({
        type: 0,
        copy: 'minting...'
      })

      // let txResponse = await contract.current.mint(_res.uri, _res.v, _res.r, _res.s, { gasLimit: 10000000 })
      let txResponse = await contract.current.mint(_res.uri, _res.v, _res.r, _res.s)
      const txReceipt = await txResponse.wait();
      const [transferEvent] = txReceipt.events;
      const { tokenId } = transferEvent.args;

      setMessage({
        type: 1,
        copy: `Woop! your sam #${tokenId} has minted <br/><a style="text-decoration: underline; font-size: 10px;" href="${process.env.REACT_APP_OPENSEA_URL}${tokenId}" rel="noreferrer" target="_blank">visit on opensea</a>`
      })

      ReactGA.event({
        category: "minter",
        action: "click",
        label: 'mint success'
      });

    } catch (err) {
      const _e = serializeError(err);
      console.log(_e);

      let message = getErrorMsg(_e)

      setMessage({
        type: 2,
        copy: message
      })

      ReactGA.event({
        category: "minter",
        action: "click",
        label: 'mint fail'
      });
    }

    setMinting(false) 
  }

  const getErrorMsg = (err) => {
    let msg = err?.data?.originalError?.reason || err?.message
    let message = 'Uh oh, there was a server error, please try again later'
    if (!msg) return message

    const errors = [
      // [blockchain error, custom msg]
      ['Signature already used', 'That signature has already been used, change the settings and try again'],
      ['Max wallet allocation reached', 'This wallet has already minted its max allocation'],
      ['Max supply reached', 'The maximum number of saaa.am\'s has been minted']
    ]

    for (let errmsg of errors) {
      if (msg.indexOf(errmsg[0]) > -1) {
        return errmsg[1]
      }
    }
  }

  return (
    <div className={styles.minter}>

      {address ? 
        <Button copy="mint" onClickCB={mint} disabled={!hasMetamask || chainID !== CHAIN_ID || !svgString} color={color} />
        :
        <Button copy="connect with MetaMask" onClickCB={connect} disabled={!hasMetamask || chainID !== CHAIN_ID || !svgString} color={color} />
      }

      {!hasMetamask ? (
        <span className={classNames([styles.label, styles.error])}>
          Metamask is not detected
        </span>
      ) : chainID !== CHAIN_ID ? (
        <span className={classNames([styles.label, styles.error])}>
          Change to the polygon network
        </span>
      ) : message.copy ? (
        <span
          className={classNames([styles.label, message.type === 1 && styles.success, message.type === 2 && styles.error])} 
          style={{ color: color }}
          dangerouslySetInnerHTML={{__html: message.copy}}></span>
      ) : address ? (
        <span className={styles.label} style={{ color: color }}>
          connected to {address.substring(0, 8)}...
        </span>
      ) : null}

    </div>
  );
}

export default Minter;
