import { useCallback, useEffect, useRef, memo, useState } from "react";
import { gsap, Elastic } from "gsap/all";
import { createNoise2D } from 'simplex-noise';

const Star = memo(({size, color, active}) => {

  const [_size, updateSize] = useState(size)
  const canvas = useRef()
  const ctx = useRef()
  const resizeTO = useRef()
  const noise = useRef()
  const visible = useRef(false)
  const scale = useRef(0.5)
  const opacity = useRef(0)
  const rotation = useRef(1)
  const revealTO = useRef()
  const spikes = useRef(0)
  const spikeOptions = useRef([10, 16, 24, 36])
  const radius = useRef(0)
  const radiusOptions = useRef([0.05, 0.1, 0.2, 0.3])

  let inTweens = useRef([])

  useEffect(() => {
    ctx.current = canvas.current.getContext("2d");
    noise.current = createNoise2D();
  }, [])

  const reveal = useCallback(() => {
    let val = { scale: scale.current, opacity: opacity.current, rotation: rotation.current }
    spikes.current = Math.floor(Math.random() * spikeOptions.current.length)
    radius.current = Math.floor(Math.random() * radiusOptions.current.length)

    inTweens.current = [
      gsap.to(val, {duration: 0.5, scale: 1, opacity: 1, ease: 'Power2.easeOut',
        onUpdate: () => {
          scale.current = val.scale
          opacity.current = val.opacity
        }
      }),
      gsap.to(val, {duration: 3, rotation: 0, ease: Elastic.easeOut.config(1, 0.5),
        onUpdate: () => {
          rotation.current = val.rotation
        }
      })
    ]
  }, [])

  const hide = useCallback(() => {
    let val = { scale: scale.current, opacity: opacity.current, rotation: rotation.current }
    
    for (let tween of inTweens.current) {
      tween.kill();
    }
      
    gsap.to(val, {duration: 0.25, scale: 0.5, opacity: 0, ease: 'Power2.easeOut',
      onUpdate: () => {
        scale.current = val.scale
        opacity.current = val.opacity
      },
      onComplete: () => {
        visible.current = false
      }
    });

    gsap.to(val, {duration: 0.25, rotation: 1, ease: 'Power2.easeOut',
      onUpdate: () => {
        rotation.current = val.rotation
      }
    });
  }, [])

  useEffect(() => {
    if (active && !visible.current) {
      // show
      clearTimeout(revealTO.current)
      revealTO.current = null
      visible.current = true
      revealTO.current = setTimeout(reveal, 100)
    } else if (!active && visible.current) {
      // hide
      clearTimeout(revealTO.current)
      revealTO.current = null
      hide()
    }
  }, [active, hide, reveal])

  useEffect(() => {
    clearTimeout(resizeTO.current)
    resizeTO.current = null

    resizeTO.current = setTimeout(() => {
      clearTimeout(resizeTO.current)
      resizeTO.current = null
      if (canvas.current) {
        canvas.current.width = size.width
        canvas.current.height = size.height
      }
      updateSize({width: size.width, height: size.height})
    }, 100)
  }, [size.width, size.height])

  const ctg = (x) => { return 1 / Math.tan(x); }

  const getPoint = useCallback((phi, width, height) => {
    let c = Math.cos(phi)
    let s = Math.sin(phi)
    let x, y

    if (width * Math.abs(s) < height * Math.abs(c)) {
      x = Math.sign(c) * width / 2
      y = Math.tan(phi) * x
    } else {
      y = Math.sign(s) * height / 2
      x = ctg(phi) * y
    }

    return {x, y}
  }, [])

  const drawShape = useCallback(() => {
    ctx.current.globalAlpha = opacity.current
    ctx.current.fillStyle = `rgb(${color.bgColor[0]}, ${color.bgColor[1]}, ${color.bgColor[2]})`;

    let noOfSpikes = spikeOptions.current[spikes.current]
    let centerSize = (_size.width * radiusOptions.current[radius.current]) * scale.current
    let debug = false
    let points = []
    let padding = 10
    let width = _size.width - padding
    let height = _size.height - padding
    let rotationOffset = rotation.current * 20
    let rotationOffset2 = rotation.current * 80
    let centerPoint = {x: _size.width/2, y: _size.height/2}

    for (let i = 0; i < noOfSpikes; i++) {
      let degreeAngle = (360/noOfSpikes) * (noOfSpikes-i) - tick.current - rotationOffset
      let radianAngle = (Math.PI/180) * degreeAngle

      let maxOffset = 360/(noOfSpikes*4)
      let offset = noise.current(13213 + i + tick.current * 0.01, 9234923 + i + tick.current * 0.01)

      let outerDegreeAngle = ((360/noOfSpikes) * i) + 95 + tick.current + rotationOffset2 + (maxOffset * offset)
      let outerRadianAngle = (Math.PI/180) * outerDegreeAngle

      let centerX = Math.sin(radianAngle);
      let centerY = Math.cos(radianAngle);
      

      let scaleOffset = tick.current * 0.01
      let noiseScale = 1/noOfSpikes * 2

      let xOffset = centerSize * noise.current(centerX + scaleOffset, centerY + scaleOffset) * noiseScale
      let yOffset = centerSize * noise.current(centerX + scaleOffset, centerY + scaleOffset) * noiseScale

      centerX = centerX * (centerSize + xOffset)
      centerY = centerY * (centerSize + yOffset)

      let outerPoint = getPoint(outerRadianAngle, width * scale.current - padding, height * scale.current - padding);
      let {x, y} = getPoint(outerRadianAngle, width * scale.current - padding, height * scale.current - padding);
      points.push({x: centerX, y: centerY})
      points.push(outerPoint)

      if (debug) {
        ctx.current.beginPath();
        ctx.current.arc(centerX + centerPoint.x, centerY + centerPoint.y, 10, 0, 2 * Math.PI);
        ctx.current.fill();

        ctx.current.fillStyle = "#ffffff";
        ctx.current.font = "10px Arial";
        ctx.current.fillText(i, centerX + centerPoint.x, centerY + (centerPoint.y));
        ctx.current.fillStyle = `rgb(${color.bgColor.r}, ${color.bgColor.g}, ${color.bgColor.b})`;
      
        ctx.current.beginPath();
        ctx.current.arc(x + (centerPoint.x), y + (centerPoint.y), 10, 0, 2 * Math.PI);
        ctx.current.fill();

        ctx.current.fillStyle = "#ffffff";
        ctx.current.font = "10px Arial";
        ctx.current.fillText(i, x + (centerPoint.x), y + (centerPoint.y));
        ctx.current.fillStyle = `rgb(${color.bgColor.r}, ${color.bgColor.g}, ${color.bgColor.b})`;
      }

    }

    ctx.current.beginPath();
    ctx.current.moveTo(points[0].x + (centerPoint.x), points[0].y + (centerPoint.y));

    for (let i = 1; i < points.length; i++) {
      ctx.current.lineTo(points[i].x + (centerPoint.x), points[i].y + (centerPoint.y));
    }
    ctx.current.lineTo(points[0].x + (centerPoint.x), points[0].y + (centerPoint.y));
    if (debug) {
      ctx.current.stroke();
    } else {
      ctx.current.fill();
    }
  }, [_size, getPoint, color])

  const requestRef = useRef()
  const tick = useRef(0)
  const lastTick = useRef(0)

  const animate = useCallback(() => {
    let now = Date.now()
    if (now - lastTick.current > 1000/60) {
      tick.current += 0.05;
      lastTick.current = now
      if (ctx.current && canvas.current && visible.current) {
        ctx.current.clearRect(0, 0, canvas.current.width, canvas.current.height);
        drawShape()
      }
    }
    requestRef.current = requestAnimationFrame(animate);
  }, [drawShape])

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [animate])

  return (
    <canvas ref={canvas} style={{ width: '100%', height: '100%' }} />
  );
})

export default Star;
