import { useContext } from "react";
import { AppContext } from "../../context/appContext";

function SliderGroup({ color, size, left = 0, top = 0, useHex, nft = false }) {
  const { borderRadius } = useContext(AppContext);
  const lineThickness = 200
  const lineRadius = borderRadius < lineThickness/2 ? borderRadius : lineThickness/2
  const boxWidth = size.width - (nft ? 0 : color.borderWeight)
  const boxHeight = size.height - (nft ? 0 : color.borderWeight)

  const getPath = () => {
    let path = `M ${left} ${boxHeight / 2 + top}`

    if (borderRadius > 1) { 
      path += ` V ${borderRadius + top}`
      path += ` A ${borderRadius} ${borderRadius} 0 0 1 ${borderRadius + left} ${top}`
    } else {
      path += ` V ${top}`
    }
              
    if (borderRadius > 1) {
      path += ` H ${boxWidth - borderRadius + left}`
      path += ` A ${borderRadius} ${borderRadius} 0 0 1 ${boxWidth + left} ${borderRadius + top}`
    } else {
      path += ` H ${boxWidth + left}`
    }
              
    if (borderRadius * 2 > lineThickness) {
      path += ` V ${boxHeight - (lineThickness/2) + top}`
      path += ` A ${lineThickness/2} ${lineThickness/2} 0 0 1 ${boxWidth - lineThickness + left} ${boxHeight - (lineThickness/2) + top}`
    } else {

      if (borderRadius > 1) {
        path += ` V ${boxHeight - borderRadius + top}`
        path += ` A ${borderRadius} ${borderRadius} 0 0 1 ${boxWidth - borderRadius + left} ${boxHeight + top}`
      } else {
        path += ` V ${boxHeight + top}`
      }
           
      if (borderRadius > 1) {
        path += ` H ${boxWidth - lineThickness + borderRadius + left}`
        path += ` A ${borderRadius} ${borderRadius} 0 0 1 ${boxWidth - lineThickness + left} ${boxHeight - borderRadius + top}`
      } else {
        path += ` H ${boxWidth - lineThickness + left}`
      }
    }
           
              
    if (borderRadius > 1) {
      path += ` V ${lineThickness + borderRadius + top}`
      path += ` A ${borderRadius} ${borderRadius} 1 0 0 ${boxWidth - lineThickness - borderRadius + left} ${lineThickness + top}`
    } else {
      path += ` V ${lineThickness + top}`
    }
              
    if (boxHeight - (lineThickness * 2) < (borderRadius * 2)) {
      path += ` H ${lineThickness + ((boxHeight - (lineThickness * 2)) / 2) + left}`
      path += ` A ${((boxHeight - (lineThickness * 2)) / 2)} ${((boxHeight - (lineThickness * 2)) / 2)} 1 0 0 ${lineThickness + ((boxHeight - (lineThickness * 2)) / 2) + left} ${boxHeight - lineThickness + top}`
    } else {

      if (borderRadius > 1) {
        path += ` H ${lineThickness + borderRadius + left}`
        path += ` A ${borderRadius} ${borderRadius} 1 0 0 ${lineThickness + left} ${lineThickness + borderRadius + top}`
      } else {
        path += ` H ${lineThickness + left}`
      }
    
      if (borderRadius > 1) {
        path += ` V ${boxHeight - lineThickness - borderRadius + top}`
        path += ` A ${borderRadius} ${borderRadius} 1 0 0 ${lineThickness + borderRadius + left} ${boxHeight - lineThickness + top}`
      } else {
        path += ` V ${boxHeight - lineThickness + top}`
      }
    }
                         
    if (borderRadius * 2 > lineThickness) {
      path += ` H ${boxWidth - (lineThickness * 1.5) - (color.borderWeight * 3) + left}`
      path += ` A ${lineThickness/2} ${lineThickness/2} 0 0 1 ${boxWidth - (lineThickness * 1.5) - (color.borderWeight * 3) + left} ${boxHeight + top}`
    } else {

      if (borderRadius > 1) {
        path += ` H ${boxWidth - lineThickness - (color.borderWeight * 3) - borderRadius + left}`
        path += ` A ${borderRadius} ${borderRadius} 0 0 1 ${boxWidth - lineThickness - (color.borderWeight * 3) + left} ${boxHeight - lineThickness + borderRadius + top}`
      } else {
        path += ` H ${boxWidth - lineThickness - (color.borderWeight * 3) + left}`
      }

      if (borderRadius > 1) {
        path += ` V ${boxHeight - borderRadius + top}`
        path += ` A ${borderRadius} ${borderRadius} 0 0 1 ${boxWidth - lineThickness - (color.borderWeight * 3) - borderRadius + left} ${boxHeight + top}`
      } else {
        path += ` V ${boxHeight + top}`
      }
    }

    if (borderRadius > 1) {
      path += ` H ${borderRadius + left}`
      path += ` A ${borderRadius} ${borderRadius} 0 0 1 ${left} ${boxHeight - borderRadius + top}`
    } else {
      path += ` H ${left}`
    }
              
    path += ` Z`

    return path
  }

  const getLine = () => {
    let path = `M ${boxWidth - (lineThickness * 1.5) - (color.borderWeight * 3) + left} ${boxHeight - (lineThickness/2) + top}`

    if (borderRadius > 1) {
      path += ` H ${(lineThickness/2) + lineRadius + left}`
      path += ` A ${lineRadius} ${lineRadius} 0 0 1 ${lineThickness/2 + left} ${boxHeight - (lineThickness/2) - lineRadius + top}`
    } else {
      path += ` H ${lineThickness/2 + left}`
    }

    if (borderRadius > 1) {
      path += ` V ${(lineThickness/2) + lineRadius + top}`
      path += ` A ${lineRadius} ${lineRadius} 0 0 1 ${lineThickness/2 + lineRadius + left} ${lineThickness/2 + top}`
    } else {
      path += ` V ${lineThickness/2 + top}`
    }
    
    if (borderRadius > 1) { 
      path += ` H ${boxWidth - (lineThickness/2) - lineRadius + left}`
      path += ` A ${lineRadius} ${lineRadius} 0 0 1 ${boxWidth - (lineThickness/2) + left} ${lineThickness/2 + lineRadius + top}`
    } else {
      path += ` H ${boxWidth - (lineThickness/2) + left}`
    }

    path += ` V ${boxHeight - (lineThickness/2) + top}`

    return path
  }

  return (
    <g>
      <path 
        fill={useHex ? color.bgColor : `rgb(${color.bgColor[0]}, ${color.bgColor[1]}, ${color.bgColor[2]})`} 
        fillOpacity={color.bgOpacity}
        strokeWidth={color.borderWeight} 
        stroke={useHex ? color.borderColor : `rgb(${color.borderColor[0]}, ${color.borderColor[1]}, ${color.borderColor[2]})`}
        strokeOpacity={color.borderOpacity}
        strokeLinecap="round"
        d={getPath()} 
      />
      <path 
        id="sliderPath" d={getLine()} fill="none" 
        strokeWidth={Math.max(color.borderWeight, 2)}
        stroke={useHex ? color.borderColor : `rgb(${color.borderColor[0]}, ${color.borderColor[1]}, ${color.borderColor[2]})`}
        strokeLinecap="round" 
      />
    </g>
  )

}

export default SliderGroup;
