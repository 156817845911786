import { useContext } from "react";
import { AppContext } from "../../context/appContext";
import GridMobile from './gridMobile';
import GridDesktop from './gridDesktop';
// import { MOBILE_THRESHOLD } from '../../utils/constants';
// import RandomPoly from '../randomPoly';
import styles from  './grid.module.scss';


function App() {

  const { showMobile, colors, gutter, screenSize, borderRadius } = useContext(AppContext);  

  if (!colors || !screenSize || !borderRadius || !gutter) return null
  // let showMobile = screenSize.width < MOBILE_THRESHOLD && orientation === 'portrait'

  return (
    <div className={styles.grid} style={{ padding: `${gutter}px`, backgroundColor: `rgb(${colors[0].bgColor[0]}, ${colors[0].bgColor[1]}, ${colors[0].bgColor[2]})` }}>
      {showMobile ? <GridMobile /> : <GridDesktop />}
    </div>
  );
}

export default App;
