import { useContext } from "react";
import { AppContext } from "../../context/appContext";
import Group from './group'
import styles from  './sShape.module.scss';

function SShape({ color, size }) {

  const { mousePos } = useContext(AppContext);

  if (!size || !color) return null

  return (
    <div className={styles.wrapper}>
      <svg preserveAspectRatio="none" width={size.width - color.borderWeight} height={size.height - color.borderWeight} viewBox={`0 0 ${size.width - color.borderWeight} ${size.height - color.borderWeight}`} className={styles.svg} style={{ top: `${color.borderWeight / 2}px`, left: `${color.borderWeight / 2}px` }}>
        <Group color={color} size={size} mousePos={mousePos} />
      </svg>
    </div>
  );
}

export default SShape;
