import { useEffect, useRef, useState, useContext, useMemo } from "react";
import classNames from 'classnames'
import { gsap } from "gsap/all";
import { AppContext } from "../../context/appContext";
import styles from  './verticalSlider1.module.scss';

function VerticalSlider1({ color, size, expanded, width, height }) {

  const { colors, sizes, sliderThickness, updateVerticalSlider1, verticalSlide1Val, showMobile } = useContext(AppContext);

  const [handlePoint, setHandlePoint] = useState();
  const [grabbing, setGrabbing] = useState(false);

  const adjustTimer = useRef(undefined);

  const targetVal = useRef(showMobile ? 1 : 0);
  const svgLine = useRef();

  const box = useMemo(() => {
    return {
      width: (width || sizes.widths[0]) - (color.borderWeight * 2),
      height: (height || sizes.heights[1]) - (color.borderWeight * 2)
    }
  }, [width, height, sizes, color.borderWeight])

  const positions = useMemo(() => {
    return {
      start: (sliderThickness/2) + (color.borderWeight/2),
      max: box.height - (sliderThickness/2) - (color.borderWeight/2)
    }
  }, [color.borderWeight, box.height, sliderThickness])

  const radius = useMemo(() => {
    return (sliderThickness/2) - color.borderWeight - 10
  }, [sliderThickness, color.borderWeight])

  useEffect(() => {
    if (svgLine.current) setHandlePoint(svgLine.current.getPointAtLength(verticalSlide1Val * svgLine.current.getTotalLength()))
  }, [color, verticalSlide1Val, positions, sizes])

  useEffect(() => {
    if (expanded) {
      if (svgLine.current) setHandlePoint(svgLine.current.getPointAtLength(verticalSlide1Val * svgLine.current.getTotalLength()))
    }
  }, [expanded, verticalSlide1Val])

  const handleDown = (e) => {
    if (!e || expanded < 0.9) return
    setGrabbing(true)

    let y = e.touches && e.touches.length ? e.touches[0].clientY : e.clientY
    y = y - size.top - color.borderWeight - positions.start
    y = y / (positions.max - positions.start)
    y = gsap.utils.clamp(0, 1, y)
    
    targetVal.current = y
    updateVerticalSlider1(targetVal.current)

    if (adjustTimer.current) {
      clearTimeout(adjustTimer.current)
      adjustTimer.current = undefined
    }
  }

  const onContainerMove = (e) => {
    if (!e) return
    let y = e.touches && e.touches.length ? e.touches[0].clientY : e.clientY
    y = y - size.top - color.borderWeight - positions.start
    y = y / (positions.max - positions.start)
    y = gsap.utils.clamp(0, 1, y)
    
    if (grabbing) {
      targetVal.current = y
      updateVerticalSlider1(targetVal.current)
    }
  }

  // const onMouseOver = () => {
  //   setPointing(true)
  // }

  // const onMouseOut = () => {
  //   setPointing(false)
  //   handleUp()
  // }

  const handleUp = (e) => {
    setGrabbing(false)
    
    if (adjustTimer.current) {
      clearTimeout(adjustTimer.current)
      adjustTimer.current = undefined
    }
    
    adjustPosition()
  }

  const adjustPosition = () => {
    if (targetVal.current >= 0.5) {
      targetVal.current = 1
      updateVerticalSlider1(targetVal.current)
    } else {
      targetVal.current = 0
      updateVerticalSlider1(targetVal.current)
    }
  }

  useEffect(() => {
    if (!grabbing) targetVal.current = verticalSlide1Val
  }, [verticalSlide1Val, grabbing])

  return (
    <div className={classNames([styles.wrapper, grabbing && styles.grabbing])} 
      style={{
        opacity: expanded, 
        pointerEvents: expanded === 1 ? 'auto' : 'none'
      }}
      onTouchStart={handleDown} onMouseDown={handleDown} 
      onTouchMove={onContainerMove} onMouseMove={onContainerMove} 
      onTouchEnd={handleUp} onMouseUp={handleUp} 
      onMouseOut={handleUp}
      // onMouseOut={onMouseOut}
      // onMouseOver={onMouseOver}
      >
      { box.width > 0 && box.height > 0 && ( <>
        <svg preserveAspectRatio="none" width={box.width} height={box.height} viewBox={`0 0 ${box.width} ${box.height}`} className={styles.svg}>

          <path id="sliderPath" ref={svgLine} style={{pointerEvents: 'none'}} 
            d={`
              M ${box.width / 2} ${positions.start}
              V ${(positions.max/2) + ((positions.max/2) * expanded)}
            `} fill="transparent" 
            strokeWidth={Math.max(color.borderWeight, 2)}
            stroke={`rgb(${color.borderColor[0]}, ${color.borderColor[1]}, ${color.borderColor[2]})`}
            strokeLinecap="round"
          />

        {handlePoint && (<>
          <circle cx={handlePoint.x} cy={handlePoint.y} r={radius} 
          fill={`rgb(${colors[0].bgColor[0]}, ${colors[0].bgColor[1]}, ${colors[0].bgColor[2]})`} 
          strokeWidth={color.borderWeight * 2}
          stroke={`rgb(${color.borderColor[0]}, ${color.borderColor[1]}, ${color.borderColor[2]})`}
          strokeOpacity={color.borderOpacity}
          style={{paintOrder: 'stroke'}} />

          <path strokeWidth={1.5} stroke={`rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`} strokeLinecap="round" fill="none"
            transform={`rotate(${(verticalSlide1Val * 180) + 90}, ${handlePoint.x} ${handlePoint.y})`}
            className={styles.arrow}
            d={`
            M ${handlePoint.x - 10} ${handlePoint.y}
            H ${handlePoint.x + 10}
            `}
          /> 

          <path strokeWidth={1.5} stroke={`rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`} strokeLinecap="round" fill="none"
            transform={`rotate(${(verticalSlide1Val * 180) + 90}, ${handlePoint.x} ${handlePoint.y})`}
            className={styles.arrow}
            d={`
              M ${handlePoint.x} ${handlePoint.y - 9}
              L ${handlePoint.x + 10} ${handlePoint.y}
              L ${handlePoint.x} ${handlePoint.y + 9}
            `}
          />

        </>)}

        </svg>
      </>)}
    </div>
  );
}

export default VerticalSlider1;
