import { useEffect, useRef, useState, useContext, useCallback } from "react";
import { AppContext } from "../../context/appContext";
import { gsap } from "gsap/all";
import classNames from "classnames";
import ReactGA from "react-ga4";
import styles from  './carouselSlideLink.module.scss';

function CarouselSlideLink({ copy, hover, size, curves, rtl, link, color }) {
  const { screenSize, showMobile } = useContext(AppContext);

  const path = useRef()
  const title = useRef()
  
  const textLength = useRef(0)
  const totalLength = useRef(0)
  const offset = useRef(0)

  const _off = useRef(0)
  const [off, setOff] = useState(0)
  const [opacity, setOpacity] = useState(0)

  const gap = size.height / curves

  const id = useRef(Math.floor(Math.random() * 999999))

  const init = useCallback(() => {
    textLength.current = title.current.getBoundingClientRect().width
    totalLength.current = path.current.getTotalLength();
    offset.current = (totalLength.current - textLength.current) / 2

    setOff(showMobile ? offset.current : -textLength.current)
    setOpacity(showMobile ? 1 : 0)
  }, [showMobile])

  useEffect(() => {
    _off.current = off
  }, [off])

  useEffect(() => {
    if (showMobile) return
    if (hover) {
      let val = { pos: -textLength.current, opacity: -0.5 }

      gsap.to(val, {
        duration: 0.5,
        ease: 'Power2.easeOut',
        pos: offset.current,
        opacity: 1,
        onUpdate: () => {
          setOff(val.pos)
          setOpacity(val.opacity)
        }
      });
    } else {
      let val = { pos: _off.current, opacity: 1 }

      gsap.to(val, {
        duration: 0.5,
        ease: 'Power2.easeIn',
        pos: totalLength.current, 
        opacity: -0.5,
        onUpdate: () => {
          setOff(val.pos)
          setOpacity(val.opacity)
        }
      });
    }
    
  }, [hover, showMobile])

  useEffect(() => {
    init();
  }, [screenSize, init])

  // useEffect(() => {
  //   init();
  //   setTimeout(init, 3000);
  // }, []) 

  const goTo = () => {
    window.open(link, '_blank').focus();
    ReactGA.event({
      category: "carousel",
      action: "click",
      label: copy
    });
  }

  const drawPath = (stroke = 0) => {
    let _path = ``

    const _gap = showMobile ? gap/3 : gap/2
    const _left = showMobile ? size.width * 0.25 : size.width * 0.2
    const _right = showMobile ? size.width * 0.75 : size.width * 0.8

    if (rtl) {
      _path += `M ${(_right) + _gap} ${0}`
      for (let i = 0; i < curves; i++) {
        if (i%2 === 0) {
          _path += ` H ${(_left) + _gap}`
          _path += ` A ${_gap} ${_gap} 1 0 0 ${(_left) + _gap} ${gap * (i + 1)}`
        } else {
          _path += ` H ${(_right) - _gap}`
          _path += ` A ${_gap} ${_gap} 0 0 1 ${(_right) - _gap} ${gap * (i + 1)}`
        }
      }
      _path += ` H ${(_left) - _gap}`
    } else {
      _path += `M ${(_left) + _gap} ${0}`
      for (let i = 0; i < curves; i++) {
        if (i%2 === 0) {
          _path += ` H ${(_right) - _gap}`
          _path += ` A ${_gap} ${_gap} 0 0 1 ${(_right) - _gap} ${gap * (i + 1)}`
        } else {
          _path += ` H ${(_left) + _gap}`
          _path += ` A ${_gap} ${_gap} 1 0 0 ${(_left) + _gap} ${gap * (i + 1)}`
        }
      }
      _path += ` H ${(_right) - _gap}`
    }

    return (
      <path id={`path-${id.current}`} className={styles.path} ref={path} d={_path} 
      fill="none" strokeWidth={stroke} 
      stroke={`rgb(${color.bgColor[0]}, ${color.bgColor[1]}, ${color.bgColor[2]})`}
      strokeLinecap="round" opacity={opacity}
      style={{
        strokeDasharray: `${textLength.current + 20}px ${totalLength.current - 20}px`,
        strokeDashoffset: `${(textLength.current - off - textLength.current) + 10}px`,
        // transform: `translateX(${stroke/2}px)`
        // strokeDashoffset: `${offset.current}px`
      }}
      />
    )
  }

  const [hoverLink, setHoverLink] = useState(false)

  const hoverOn = () => {
    setHoverLink(true)
  }
  
  const hoverOut = () => {
    setHoverLink(false)
  }

  return (
    <div className={styles.pathWrapper} >

      <div ref={title} className={styles.text}>
        {copy}
      </div>
      
      <svg preserveAspectRatio="none" width={size.width} height={size.height} 
        viewBox={`0 0 ${size.width} ${size.height}`} 
        className={classNames([styles.svg, hoverLink && !showMobile && styles.hover])}>

          { drawPath(10) }
          { drawPath(40) }

          <text textLength={textLength.current} className={styles.label} opacity={opacity} onMouseOver={hoverOn} onMouseOut={hoverOut} >
            <textPath 
              alignmentBaseline={'middle'} href={`#path-${id.current}`} id="masterTextPath" textLength={textLength.current} 
              fill={hoverLink ? 'white' : `rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`}
              startOffset={off} onClick={goTo}>{copy}</textPath>
          </text>
              
      </svg>        
      
    </div>
  );
}

export default CarouselSlideLink;
