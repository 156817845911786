import { useState, useContext } from "react";
import { AppContext } from "../../context/appContext";
import CarouselSlideLink from "../carouselSlideLink";
import styles from  './carouselSlide.module.scss';

function CarouselSlide({ copy, image, size, link, curves, rtl, color }) {
  const { bottomScale, infoExpanded, showMobile } = useContext(AppContext);
  const [isHover, setIsHover] = useState(false)

  const onOver = () => {
    setIsHover(true)
  }

  const onOut = () => {
    setIsHover(false)
  }
  
  return (
    <div
      className={styles.slide} onMouseOver={onOver} onMouseOut={onOut}
      style={{backgroundImage: image ? `url(${image})` : 'none'}}
    >
      { ((bottomScale > 0 && infoExpanded > 0) || showMobile) && (<>
        <div className={styles.overlay}></div>
        <CarouselSlideLink color={color} copy={copy} size={size} hover={isHover} link={link} curves={curves} rtl={rtl} />
      </>)}
    </div>
  );
}

export default CarouselSlide;
