import { useCallback, useEffect, useRef, memo, useState } from "react";
import { gsap, Elastic } from "gsap/all";
import { createNoise2D } from 'simplex-noise';

const Splat = memo(({size, color, active}) => {

  const [_size, updateSize] = useState(size)
  const canvas = useRef()
  const ctx = useRef()
  const resizeTO = useRef()
  const noise = useRef()
  const visible = useRef(false)
  const scale = useRef(0.5)
  const opacity = useRef(0)
  const rotation = useRef(1)
  const revealTO = useRef()
  const spikes = useRef(0)
  const spikeOptions = useRef([6, 10, 16])
  const radius = useRef(0)
  const radiusOptions = useRef([0.5, 0.25, 0])

  let inTweens = useRef([])

  useEffect(() => {
    // updateSize()
    ctx.current = canvas.current.getContext("2d");
    noise.current = createNoise2D();
  }, [])

  const reveal = useCallback(() => {
    let val = { scale: scale.current, opacity: opacity.current, rotation: rotation.current }
    spikes.current = Math.floor(Math.random() * spikeOptions.current.length)
    radius.current = Math.floor(Math.random() * radiusOptions.current.length)

    inTweens.current = [
      // gsap.to(val, {duration: 0.5, scale: 1, opacity: 1, ease: 'Power2.easeOut',
      gsap.to(val, {duration: 1, scale: 1, opacity: 1, ease: Elastic.easeOut.config(1, 0.5),
        onUpdate: () => {
          scale.current = val.scale
          opacity.current = val.opacity
        }
      }),
      gsap.to(val, {duration: 3, rotation: 0, ease: Elastic.easeOut.config(1, 0.5),
        onUpdate: () => {
          rotation.current = val.rotation
        }
      })
    ]
  }, [])

  const hide = useCallback(() => {
    let val = { scale: scale.current, opacity: opacity.current, rotation: rotation.current }
    
    for (let tween of inTweens.current) {
      tween.kill();
    }
      
    gsap.to(val, {duration: 0.25, scale: 0.5, opacity: 0, ease: 'Power2.easeOut',
      onUpdate: () => {
        scale.current = val.scale
        opacity.current = val.opacity
      },
      onComplete: () => {
        visible.current = false
      }
    });

    gsap.to(val, {duration: 0.25, rotation: 1, ease: 'Power2.easeOut',
      onUpdate: () => {
        rotation.current = val.rotation
      }
    });
  }, [])

  useEffect(() => {
    if (active && !visible.current) {
      // show
      clearTimeout(revealTO.current)
      revealTO.current = null
      visible.current = true
      revealTO.current = setTimeout(reveal, 100)
    } else if (!active && visible.current) {
      // hide
      clearTimeout(revealTO.current)
      revealTO.current = null
      hide()
    }
  }, [active, hide, reveal])

  useEffect(() => {
    clearTimeout(resizeTO.current)
    resizeTO.current = null

    resizeTO.current = setTimeout(() => {
      clearTimeout(resizeTO.current)
      resizeTO.current = null
      canvas.current.width = size.width
      canvas.current.height = size.height
      updateSize({width: size.width, height: size.height})
    }, 100)
  }, [size.width, size.height])

  const ctg = (x) => { return 1 / Math.tan(x); }

  const getPoint = useCallback((phi, width, height) => {
    let c = Math.cos(phi)
    let s = Math.sin(phi)
    let x, y

    if (width * Math.abs(s) < height * Math.abs(c)) {
      x = Math.sign(c) * width / 2
      y = Math.tan(phi) * x
    } else {
      y = Math.sign(s) * height / 2
      x = ctg(phi) * y
    }

    return {x, y}
  }, [])

  const getPointBetween = (p1, p2, dist) => {
    var xDist = p2.x - p1.x;
    var yDist = p2.y - p1.y;

    return {
      x: p1.x + xDist * dist,
      y: p1.y + yDist * dist
   }
  }

  const drawShape = useCallback(() => {
    ctx.current.globalAlpha = opacity.current
    ctx.current.fillStyle = `rgb(${color.bgColor[0]}, ${color.bgColor[1]}, ${color.bgColor[2]})`;
    let noOfSpikes = spikeOptions.current[spikes.current]
    let debug = false
    let points = []
    let padding = 10
    let width = _size.width - padding
    let height = _size.height - padding
    let rotationOffset2 = rotation.current * 20
    let centerPoint = {x: _size.width/2, y: _size.height/2}

    for (let i = 0; i < noOfSpikes; i++) {
      let maxOffset = 360/(noOfSpikes*4)
      let offset = noise.current(13213 + i + tick.current * 0.01, 9234923 + i + tick.current * 0.01)
      let outerDegreeAngle = ((360/noOfSpikes) * i) + 95 + tick.current + rotationOffset2 + (maxOffset * offset)
      let outerRadianAngle = (Math.PI/180) * outerDegreeAngle
      let outerPoint = getPoint(outerRadianAngle, width * scale.current - padding, height * scale.current - padding);
      points.push(outerPoint)
    }

    // draw center points
    if (debug) {
      for (let i = 0; i < points.length; i++) {
        ctx.current.fillStyle = "#ff0000";
        ctx.current.beginPath();
        ctx.current.arc(points[i].x + (centerPoint.x), points[i].y + (centerPoint.y), 3, 0, 2 * Math.PI);
        ctx.current.fill();

        let prev = i === 0 ? points.length - 1 : i -1
        let point = {x: points[i].x + (centerPoint.x), y: points[i].y + (centerPoint.y)}
        let center = {x: (point.x + ((points[prev].x - points[i].x)/2)), y: (point.y + ((points[prev].y - points[i].y)/2))}
        // let curveAxis = midpoint({x: centerPoint.x, y: centerPoint.y}, center)
        let curveAxis = getPointBetween({x: centerPoint.x, y: centerPoint.y}, center, radiusOptions.current[radius.current])

        ctx.current.fillStyle = "#0000ff";
        ctx.current.beginPath();
        ctx.current.arc(center.x, center.y, 3, 0, 2 * Math.PI);
        ctx.current.fill();

        ctx.current.fillStyle = "#00ff00";
        ctx.current.beginPath();
        ctx.current.arc(curveAxis.x, curveAxis.y, 3, 0, 2 * Math.PI);
        ctx.current.fill();
      }
    }

    ctx.current.beginPath();
    ctx.current.moveTo(points[points.length-1].x + (centerPoint.x), points[points.length-1].y + (centerPoint.y));

    for (let i = 0; i < points.length; i++) {
      let point = {x: points[i].x + (centerPoint.x), y: points[i].y + (centerPoint.y)}
      let prev = i === 0 ? points.length - 1 : i -1
      let center = {x: (point.x + ((points[prev].x - points[i].x)/2)), y: (point.y + ((points[prev].y - points[i].y)/2))}
      let anchorPoint = getPointBetween({x: centerPoint.x, y: centerPoint.y}, center, radiusOptions.current[radius.current])
      ctx.current.quadraticCurveTo(anchorPoint.x, anchorPoint.y, point.x, point.y);
    }
    
    if (debug) {
      ctx.current.stroke();
    } else {
      ctx.current.fill();
    }
    
  }, [_size, getPoint, color])

  const requestRef = useRef()
  const tick = useRef(0)
  const lastTick = useRef(0)

  const animate = useCallback(() => {
    let now = Date.now()
    if (now - lastTick.current > 1000/60) {
      tick.current += 0.05;
      lastTick.current = now
      if (ctx.current && canvas.current && visible.current) {
        ctx.current.clearRect(0, 0, canvas.current.width, canvas.current.height);
        drawShape()
      }
    }
    requestRef.current = requestAnimationFrame(animate);
  }, [drawShape])

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [animate])

  return (
    <canvas ref={canvas} style={{ width: '100%', height: '100%' }} />
  );
})

export default Splat;
