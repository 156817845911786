import { useCallback, useEffect, useRef, useState } from "react";
import { gsap } from "gsap/all";
import styles from  './shuffleButton.module.scss';
import classNames from "classnames";

function ShuffleButton({ label, onClickCB, color, disabled }) {
  const layout = useRef();
  const isHover = useRef();
  const [weight, setWeight] = useState(200);

  const updateLayout = useCallback((hover = false) => {
    let _layout = []

    for (let i = 0; i < label.length; i++) {

      let offset = hover ? (i / ((label.length - 1) / 2)) - 1 : 0
      let minX = hover ? offset * 30 : 0
      let xFactor = hover ? 0.4 : 0
      let rFactor = hover ? 60 : 0

      let settings = {
        scale: hover ? ((Math.random() * 0.6) - 0.3) + 1.2 : 0,
        rotation: (Math.random() * rFactor) - (rFactor/2),
        posX: Math.floor((((Math.random() * xFactor) * offset) * 40) + minX),
        posY: Math.floor(((Math.random() * xFactor) - (xFactor/2)) * 100),
      }

      _layout.push({
        letter: label[i],
        styles: {
          transform: `translate3d(${settings.posX}px, ${settings.posY}px, 0) scale(${1 + settings.scale}) rotate(${settings.rotation}deg)`,
          transitionDelay: `${Math.floor(Math.random() * 200)}ms`,
        }
      })
    }

    layout.current = _layout
  }, [label])

  useEffect(() => {
    updateLayout()
  }, [label, disabled, updateLayout])

  const onClick = () => {
    updateLayout(true)
    if (onClickCB) onClickCB()
  }

  const onOver = () => {
    updateLayout(true)

    let val = { pos: weight }
    gsap.to(val, {
      duration: 0.25,
      pos: 600, 
      onUpdate: () => {
        setWeight(val.pos)
      },
      onComplete: () => {
        isHover.current = true
      }
    });
  }

  const onOut = () => {
    updateLayout()

    let val = { pos: weight }
    gsap.to(val, {
      duration: 0.25,
      pos: 200, 
      onUpdate: () => {
        setWeight(val.pos)
      },
      onComplete: () => {
        isHover.current = false
      }
    });
  }
  
  if (!label || !layout.current) return null

  return (
    <div className={classNames([styles.wrapper, disabled && styles.disabled ])} onClick={onClick} onMouseOver={onOver} onMouseOut={onOut}>
      {layout.current.map((letter, index) =>
        <span className={styles.letter} key={`letter-${index}`} style={{ ...letter.styles, ...{ color: `rgb(${color.textColor[0]}, ${color.textColor[1]}, ${color.textColor[2]})`, fontVariationSettings: `'wght' ${weight}` }}}>{letter.letter}</span>
      )}
    </div>
  );
}

export default ShuffleButton;
