import { useCallback, useContext, useEffect, useRef, memo, useState } from "react";
import { AppContext } from "../../context/appContext";
import { brightPalette } from '../../utils/colors'
import { Engine, Render, Composite, Bodies, Runner, Common, Body } from 'matter-js'
import Container from './container'
import ReactGA from "react-ga4";
import styles from  './matter.module.scss';

const sidesCat = 0x0001
const topCat = 0x0002
const bottomCat = 0x0004
const explosionCat = 0x0008

const Matter = memo(() => {

  const { colors, screenSize, borderRadius, gutter, verticalSlide1Val, physicsActive, setPhysicsActive, explosion, setExplosion } = useContext(AppContext);

  const startGutter = useRef(gutter)

  const renderer = useRef()
  const scene = useRef()
  const engine = useRef(Engine.create())
  const resetWallsTO = useRef() 

  const [_colors, setColors] = useState(colors)

  // const mouseObj = useRef()
  const shapes = useRef([])
  const walls = useRef()

  // const active = useRef(false)
  const paused = useRef(true)

  const onUnPause = useCallback(() => {
    ReactGA.event({
      category: "physics",
      action: "start"
    });

    for (let i = 0; i < shapes.current.length; i++) {      
      setTimeout(() => {
        // if (shapes.current[i]) shapes.current[i].release()
        if (shapes.current[i]) shapes.current[i].wobble()
      }, (shapes.current.length - i) * 300)
    }
  }, [])

  useEffect(() => {

    // if (paused.current && verticalSlide1Val === 1) {
    //   // active.current = true
    //   setPhysicsActive(true)
    //   paused.current = false
    //   onUnPause()
    // }

    if (paused.current && verticalSlide1Val === 1) {
      paused.current = false
    }

    if (!paused.current && verticalSlide1Val < 1) {
      paused.current = true
      for (let s of shapes.current) {
        if (s.canCollide()) s.collideOff()
      }
    }

  }, [verticalSlide1Val, onUnPause])

  useEffect(() => {
    if (physicsActive) onUnPause()
  }, [physicsActive, onUnPause])

  const getShape = useCallback((width, height, left, top, color, collides, staticCat, randomShape) => {
    return new Container({
      world: engine.current.world,
      width, height, left, top,
      screenSize, collides,
      radius: borderRadius, 
      gutter: startGutter.current, 
      color: _colors[color], 
      bgColor: _colors[0],
      staticCat,
      randomShape
    })
  }, [screenSize, _colors, borderRadius])

  const addShapes = useCallback(() => {
    shapes.current = [
      getShape(1, 1, 1, -2, 3, true),
      getShape(1, 1, 1, -1, 4),
      getShape(1, 1, 2, -2, 5, true),
      getShape(1, 2, 2, -1, 6),
      getShape(1, 1, 1, 0, 7),
      getShape(2, 1, 1, 1, 8),
    ]
  }, [getShape])
  
  let randomExplosions = useRef([])

  const addShape = useCallback(() => {
    let color = Math.floor((_colors.length - 3) * Math.random()) + 2
    let shape
    let isVert = Math.random() < 0.1
    let isHoriz = Math.random() < 0.1
    let isRandom = Math.random() < 0.5
    let collides = Math.random() < 0.9
    
    if (isVert) {
      shape = getShape(1, 2, Math.random() < 0.5 ? 1 : 2, -3, color, collides)
    } else if (isHoriz) {
      shape = getShape(2, 1, 1, -2, color, collides)
    } else if (isRandom) {
      shape = getShape(1, 1, Math.random() < 0.5 ? 1 : 2, -2, color, collides, undefined, true)
    } else {
      shape = getShape(1, 1, Math.random() < 0.5 ? 1 : 2, -2, color, collides)
    }

    shapes.current.push(shape)
    shape.release()

  }, [_colors, getShape])

  const explode = useCallback(() => {
    ReactGA.event({
      category: "physics",
      action: "explode"
    });

    if (shapes.current && shapes.current.length) {
      // let offset = -(screenSize.height/2) + ((screenSize.height/2) * verticalSlide1Val)

      for (let i = shapes.current.length - 1; i >= 0; i--) {
        let s = shapes.current[i]
        
        let _pos = s.shape.position
        let _w = s.size.width
        let _h = s.size.height

        Composite.remove(engine.current.world, s.shape)
        shapes.current.splice(i, 1)
        
        let noOfshapes = Math.round(Math.sqrt(Math.sqrt(_w * _h)))

        for (let i = 0; i < noOfshapes; i++) {
          let sides = Math.round(Common.random(1, 8));
          let chamfer = {
            radius: 10
          }
          let color = Common.choose(brightPalette);
          let shape = Bodies.polygon(
            _pos.x + (Math.random() * _w/2) - _w/4,
            _pos.y + (Math.random() * _h/2) - _h/4,
            // ((screenSize.width * 0.6) * Math.random()) + (screenSize.width * 0.2), 
            // (screenSize.height + 100) + Common.random(-50, 50), 
            sides,
            Common.random(15, 30), 
            { 
              isStatic: true, 
              chamfer: chamfer, 
              collisionFilter: {
                category: explosionCat,
                mask: explosionCat
              },
              render: {
                fillStyle: color,
                strokeStyle: '#222222',
                lineWidth: 24
              }
            }
          )

          randomExplosions.current.push(shape)
          Composite.add(engine.current.world, shape)
        }
      }
    }

    for (let shape of randomExplosions.current) {
      Body.setStatic(shape, false)
      var forceMagnitude = 0.02 * shape.mass;
      Body.applyForce(shape, shape.position, { 
          x: (forceMagnitude + Common.random() * forceMagnitude) * Common.choose([1, -1]), 
          y: (forceMagnitude + Common.random() * forceMagnitude) * Common.choose([1, -1]), 
          // y: -forceMagnitude + Common.random() * -forceMagnitude
      });
    }
  }, [])

  useEffect(() => {
    if (explosion) {
      explode()
    }
  }, [explosion, explode, setExplosion])

  useEffect(() => {
    if (shapes.current && shapes.current.length) {
      // let offset = -(screenSize.height/2) + ((screenSize.height/2) * verticalSlide1Val)
      for (let s of shapes.current) {
        // if (s.shape.isStatic && !s.shape.isSleeping) {
        //   Body.setPosition(s.shape, {x: s.pos.x, y: s.pos.y + offset})
        // }
        s.updatePosition(verticalSlide1Val)
      }
    }
  }, [verticalSlide1Val, screenSize])

  const addWalls = useCallback(() => {
    if (walls.current && walls.current.length) {
      for (let i = walls.current.length - 1; i >= 0; i--) {
        Composite.remove(engine.current.world, walls.current[i].shape)
      }
    }

    walls.current = [
      getShape(1, 3, 0, 1, 2, false, sidesCat),
      getShape(2, 1, 1, 2, 4, false, topCat),
      getShape(2, 2, 1, 3, 3, false, bottomCat)
    ]
  }, [getShape])

  const reset = useCallback(() => {
    Composite.clear(engine.current.world)
    addWalls()
    addShapes()
    // active.current = false
    setPhysicsActive(false)
    // Render.stop(renderer.current)
    
  }, [addWalls, addShapes, setPhysicsActive])

  useEffect(() => {
    clearTimeout(resetWallsTO.current)
    
    resetWallsTO.current = setTimeout(() => {
      clearTimeout(resetWallsTO.current)
      resetWallsTO.current = null
      setColors(colors)
      addWalls()
    }, 100)
  }, [borderRadius, addWalls, colors])

  const resetTO = useRef()

  useEffect(() => {
    clearTimeout(resetTO.current)
    
    resetTO.current = setTimeout(() => {
      clearTimeout(resetTO.current)
      resetTO.current = null
      
      if (!physicsActive && verticalSlide1Val === 1) {

        for (let i = shapes.current.length - 1; i >= 0; i--) {
          Composite.remove(engine.current.world, shapes.current[i].shape)
          shapes.current.splice(i, 1)
        }

        reset() 
        for (let s of shapes.current) {
          s.updatePosition(1)
        }
      }
    }, 100)
  }, [colors, verticalSlide1Val, physicsActive, reset])

  const checkShapes = useCallback(() => {
    for (let i = shapes.current.length - 1; i >= 0; i--) {
      let shape = shapes.current[i].shape
      let shapeY = shape.position.y 
      if (shapeY > screenSize.height) {
        Composite.remove(engine.current.world, shape)
        shapes.current.splice(i, 1)
      }
    }
    
    if (randomExplosions.current) {
      for (let i = randomExplosions.current.length - 1; i >= 0; i--) {
        let shape = randomExplosions.current[i]
        let shapeY = shape.position.y 
        if (shapeY > (screenSize.height + 150)) {
          Composite.remove(engine.current.world, shape)
          randomExplosions.current.splice(i, 1)
        }
      }
    }

    if (shapes.current.length < 4 && !paused.current) {
      addShape()
    }

    if (explosion && randomExplosions.current && randomExplosions.current.length === 0) {
      setExplosion(false)
    } else if (paused.current && shapes.current.length === 0 && verticalSlide1Val === 0 && physicsActive && !explosion) {
      reset()
    }
    
  }, [addShape, screenSize, verticalSlide1Val, reset, physicsActive, explosion, setExplosion])

  const startScene = useCallback(() => {
    addWalls()
    addShapes()
    Render.run(renderer.current)
  }, [addShapes, addWalls])

  const createScene = useRef(() => {
    renderer.current = Render.create({
      element: scene.current,
      engine: engine.current,
      options: {
        width: screenSize.width,
        height: screenSize.height,
        wireframes: false,
        background: 'transparent'
      }
    })
      
    // mouseObj.current = Bodies.circle(0, 0, 40, { isStatic: true, render: { opacity: 0 }})
    // Composite.add(engine.current.world, [mouseObj.current])
      
    // run the engine
    Runner.run(engine.current)
    startScene()
    // Render.run(renderer.current)
  })

  const removeScene = useRef(() => {
    Render.stop(renderer.current)
    Composite.clear(engine.current.world)
    Engine.clear(engine.current)
    renderer.current.canvas.remove()
    renderer.current.canvas = null
    renderer.current.context = null
    renderer.current.textures = {}
    renderer.current = null
  })

  useEffect(() => {
    createScene.current()
    return () => removeScene.current() // eslint-disable-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let shapeInterval = setInterval(() => {
      checkShapes()
    }, 500)

    return () => clearInterval(shapeInterval)
  }, [_colors, borderRadius, verticalSlide1Val, checkShapes])

  useEffect(() => {
    if (!physicsActive) {
      reset()
    }
  }, [screenSize, reset, physicsActive])

  return (
    <div className={styles.container} style={{width: `${screenSize.width}px`, height: `${screenSize.height}px`}} >
      <div ref={scene} style={{ width: '100%', height: '100%' }} />
    </div>
  );
})

export default Matter;
