import { useContext } from "react";
import { AppContext } from "../../context/appContext";

function SShapeGroup({ color, size, left = 0, top = 0, mousePos, useHex, nft = false }) {

  const { borderRadius } = useContext(AppContext);

  /*
  ** gap
  */

  const gap = (color.borderWeight / 4) + 1

  /*
  ** set positions
  */

  let boxWidth = size.width - (nft ? 0 : color.borderWeight)
  let boxHeight = size.height - (nft ? 0 : color.borderWeight)

  let maxYRadius =  size.height / 4 < borderRadius ? size.height / 4 : borderRadius
  let maxRadius = size.width / 2 < maxYRadius ? size.width / 2 : maxYRadius

  let maxDistance = (size.height / 2) - maxRadius - color.borderWeight - gap
  let yDistance = maxDistance * 0.6
  let xDistance = maxDistance * 0.4
  
  let mouseX = (1 - Math.cos(mousePos.x * Math.PI)) / 2
  let _cornerScaleTop = (gap * 2) + ((gap * 1) * mouseX)
  let _cornerScaleBottom = (gap * 2) + ((gap * 1) * (1 - mouseX))

  let mouseY = (1 - Math.cos(mousePos.y * Math.PI)) / 2
  let mouseY2 = (1 - Math.sin((mousePos.y * Math.PI) + (Math.PI/2))) / 2

  let _topY = (mouseY * yDistance) + (mouseX * xDistance) + maxRadius + gap
  let _bottomY = size.height - maxRadius - (mouseY2 * yDistance) - ((1 - mouseX) * xDistance) - gap

  let maxXDistance = size.width / 4

  let topXOffset = maxXDistance * mousePos.x
  let bottomXOffset = maxXDistance * (1 - mousePos.x)

  return (
    <g>

      <path 
        style={{ 
          pointerEvents: 'none', 
          // filter: `url(#displacementFilter)` 
        }} strokeWidth={color.borderWeight}
        fill={useHex ? color.bgColor : `rgb(${color.bgColor[0]}, ${color.bgColor[1]}, ${color.bgColor[2]})`}
        fillOpacity={color.bgOpacity}
        d={
          borderRadius === 1 ?
            `M ${left} ${top}`
            + ` H ${boxWidth + left}`
            + ` V ${_topY + top}`
            + ` H ${(boxWidth / 2) - bottomXOffset + left}`
            + ` A ${gap/2} ${gap/2} 1 0 0 ${(boxWidth / 2) - bottomXOffset + left} ${_topY + (gap * 2) + top}`
            + ` H ${boxWidth + left}`
            + ` V ${boxHeight + top}`
            + ` H ${left}`
            + ` V ${_bottomY + top}`
            + ` H ${(boxWidth / 2) + topXOffset + left}`
            + ` A ${gap/2} ${gap/2} 1 0 0 ${(boxWidth / 2) + topXOffset + left} ${_bottomY - (gap * 2) + top}`
            + ` H ${left}`
            + ` Z`
          :
            `M ${left} ${maxRadius + top}`
            + ` A ${maxRadius} ${maxRadius} 0 0 1 ${maxRadius + left} ${0 + top}`
            + ` H ${boxWidth - maxRadius + left}`
            + ` A ${maxRadius} ${maxRadius} 0 0 1 ${boxWidth + left} ${maxRadius + top}`
            + ` V ${_topY - gap + top}`
            + ` A ${gap} ${gap} 0 0 1 ${boxWidth - gap + left} ${_topY + top}`
            + ` H ${(boxWidth / 2) - bottomXOffset + left}`
            + ` A ${gap/2} ${gap/2} 1 0 0 ${(boxWidth / 2) - bottomXOffset + left} ${_topY + (gap * 2) + top}`
            + ` H ${boxWidth - maxRadius + left}`
            + ` A ${maxRadius} ${maxRadius} 0 0 1 ${boxWidth + left} ${_topY + gap + maxRadius + top}`
            + ` V ${boxHeight - maxRadius + top}`
            + ` A ${maxRadius} ${maxRadius} 0 0 1 ${boxWidth - maxRadius + left} ${boxHeight + top}`
            + ` H ${maxRadius + left}`
            + ` A ${maxRadius} ${maxRadius} 0 0 1 ${0 + left} ${boxHeight - maxRadius + top}`
            + ` V ${_bottomY + gap + top}`
            + ` A ${gap} ${gap} 0 0 1 ${gap + left} ${_bottomY + top}`
            + ` H ${(boxWidth / 2) + topXOffset + left}`
            + ` A ${gap/2} ${gap/2} 1 0 0 ${(boxWidth / 2) + topXOffset + left} ${_bottomY - (gap * 2) + top}`
            + ` H ${maxRadius + left}`
            + ` A ${maxRadius} ${maxRadius} 0 0 1 ${0 + left} ${_bottomY - gap - maxRadius + top}`
            + ` Z`
        } stroke={useHex ? color.borderColor : `rgb(${color.borderColor[0]}, ${color.borderColor[1]}, ${color.borderColor[2]})`} strokeLinecap="round"
      />

      <path style={{pointerEvents: 'none'}} fill="none" strokeWidth={color.borderWeight} strokeLinecap="round"
        stroke={useHex ? color.borderColor : `rgb(${color.borderColor[0]}, ${color.borderColor[1]}, ${color.borderColor[2]})`} d={
          `M ${(boxWidth / 2) - bottomXOffset - (gap * 2) - (_cornerScaleTop/2) + left} ${_topY - _cornerScaleTop + top}`
          + ` A ${_cornerScaleTop * 2} ${_cornerScaleTop * 2} 0 0 1 ${(boxWidth / 2) - bottomXOffset - (gap * 2) + left} ${_topY + gap + top}`
          + ` A ${_cornerScaleTop * 2} ${_cornerScaleTop * 2} 0 0 1 ${(boxWidth / 2) - bottomXOffset - (gap * 2) - (_cornerScaleTop/2) + left} ${_topY + (gap * 2) + _cornerScaleTop + top}`
        }
      />

      <path style={{pointerEvents: 'none'}} fill="none" strokeWidth={color.borderWeight} strokeLinecap="round"
        stroke={useHex ? color.borderColor : `rgb(${color.borderColor[0]}, ${color.borderColor[1]}, ${color.borderColor[2]})`} d={
          `M ${(boxWidth / 2) + topXOffset + (gap * 2) + (_cornerScaleBottom/2) + left} ${_bottomY - (gap * 2) - _cornerScaleBottom + top}`
          + ` A ${_cornerScaleBottom * 2} ${_cornerScaleBottom * 2} 1 0 0 ${(boxWidth / 2) + topXOffset + (gap * 2) + left} ${_bottomY - gap + top}`
          + ` A ${_cornerScaleBottom * 2} ${_cornerScaleBottom * 2} 1 0 0 ${(boxWidth / 2) + topXOffset + (gap * 2) + (_cornerScaleBottom/2) + left} ${_bottomY + _cornerScaleBottom + top}`
        }
      />

    </g>
  );
}

export default SShapeGroup;
